import { FC, useCallback, useEffect, useState } from 'react'

import { Backdrop, Box, CircularProgress, debounce } from '@mui/material'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

import { Search } from 'src/components/Search'
// import { CustomButton } from 'src/ui/Button'

import { Map } from './components/Map'
import { MapAside } from './components/MapAside'
import { IFactory } from './types'
import { CustomButton } from 'src/ui/Button'
import { toast } from 'react-toastify'
import PageLoader from 'src/components/PageLoader/PageLoader'

// import { MapFilters } from './components/MapFilters'

export const FleetMap: FC = () => {
  const [mapData, setMapData] = useState<IFactory[]>()
  const [markers, setMarkers] = useState<any[]>([])
  const [regions, setRegions] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const { plantId: activeMarker } = useParams<{ plantId: string }>()
  const user = localStorage.getItem('user')

  const role = user ? JSON.parse(user).role : ''
  const navigate = useNavigate()

  const [filtering, setFiltering] = useState<{
    searchQuery: string
    searchField: string
    region: string
  }>({
    searchQuery: '',
    searchField: 'name',
    region: '',
  })
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setIsLoading(true)
      const response = await axios.get<{
        factories: IFactory[]
        regions: any[]
      }>('/company/map-data', {
        params: {
          ...filtering,
        },
      })
      setMapData(response.data.factories)
      setRegions(response.data.regions)
      setMarkers(
        response.data.factories.map((factory) => ({
          id: factory.id,
          lng: factory.location.lng,
          lat: factory.location.lat,
          name: factory.name,
        })),
      )
    }

    fetchData()
      .then(() => {
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error.message)
      })
  }, [filtering])

  const filters = regions.map((region) => ({
    value: region,
    label: region,
  }))

  const onFilterChange = (value: string): void => {
    setFiltering((prev) => ({
      ...prev,
      region: value,
    }))
    // todo
    // setActiveMarker(undefined)
  }

  const handleSearchDebounced = debounce((value) => {
    setFiltering((prev) => ({
      ...prev,
      searchQuery: value,
    }))
    // todo
    // setActiveMarker(undefined)
  }, 500)

  const handleMarkerChange = useCallback(
    (marker: any): void => {
      const id = marker?.id ?? marker
      if (typeof id === 'number') {
        navigate(`/map/${id}`)
      } else {
        navigate(`/map`)
      }
    },
    [navigate],
  )

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: '48px',
          height: '64px',
          pb: '24px',
          border: '1p solid #ADB5BD',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
          }}
        >
          <Search
            searchStyles={{
              width: '364px',
              padding: '0.3rem 3rem 0.3rem 0',
            }}
            selectLabel={{ name: 'Region', id: 'region' }}
            styles={{ width: 'auto' }}
            onChange={(e) => handleSearchDebounced(e.target.value)}
            hasFilters={true}
            onFilterChange={(e: any) => {
              onFilterChange(e)
            }}
            options={filters}
            isMultiFilter={true}
            selectStyles={{
              backgroundColor: '#F5F7F9',
              borderRadius: '4px',
            }}
          />
          {role === 'globalAdmin' && (
            <CustomButton
              appearance="primary-soft-default"
              disabled={isLoading}
              onClick={async () => {
                try {
                  setIsLoading(true)
                  await axios.get('/admin/refresh-machines-data')
                  setIsLoading(false)
                  toast.success('Plants refreshed successfully')
                } catch (e) {
                  setIsLoading(false)
                  toast.error('Failed to refresh plants, please try again..')
                }
              }}
            >
              Refresh machines
            </CustomButton>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mt: '48px',
          overflow: 'hidden',
          borderRadius: '8px',
          height: '864px',
        }}
      >
        <MapAside
          data={mapData}
          activeMarkerId={activeMarker ? +activeMarker : undefined}
          changeMarker={handleMarkerChange}
        />
        <Map
          markers={markers}
          changeMarker={handleMarkerChange}
          currentMarker={markers.find((e) => e.id == activeMarker)}
        />
      </Box>
      {isLoading && (
        <Backdrop
        open={true}
        sx={{ zIndex: 9999, background: 'transparent' }}
      ></Backdrop>
      )}
    </>
  )
}
