import React, { FC } from 'react'

import Box from '@mui/material/Box'

import { PageHeading } from 'src/components/PageHeading'
import KnowledgeBase from 'src/modules/KnowledgeBase/KnowledgeBase'

export const KnowledgeBasePage: FC = () => {
  return (
    <Box
      component={'section'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        mt: '3rem',
        mb: '7.75rem',
      }}
    >
      <PageHeading
        title="FAQ & Tutorials"
        subtitle="In this section you can find out how
       to leverage the use of your Pride Platform."
      />
      <KnowledgeBase />
    </Box>
  )
}
