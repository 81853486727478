import { useEffect, useState } from 'react'

import { ChartData } from 'src/modules/MachineView/components/MachineChart'

export interface DataFetcherProps {
  data?: ChartData[]
  newData?: ChartData[]
  isLive?: boolean
}

export function useChartData({
  newData,
  data: dataProp,
  isLive,
}: DataFetcherProps): ChartData[] {
  const [data, setData] = useState<ChartData[]>(dataProp ?? [])

  const [liveData, setLiveData] = useState<ChartData[]>(newData!)

  useEffect(() => {
    if (!isLive) {
      setData(dataProp ?? [])
    }
  }, [dataProp, newData, isLive])

  useEffect(() => {
    let animationFrameId: number

    const updateData = (): void => {
      if (isLive && newData && newData.length > 1) {
        // console.log('newData', newData)

        setLiveData((prevData) =>
          prevData.map((item, index) => {
            const fiveMinutesAgo = new Date(Date.now() - 300000)

            // filter out old data points from existing data
            const recentData = item.data.filter((existingItem) => {
              const existingItemDate = new Date(existingItem.date)
              return existingItemDate > fiveMinutesAgo
            })

            // filter new data to include only points that are newer than the last point and within 5 minutes
            const filteredNewData = newData[index].data.filter((newItem) => {
              const newItemDate = new Date(newItem.date)
              const lastDataPointDate = recentData[recentData.length - 1]?.date

              return (
                newItemDate > new Date(lastDataPointDate) &&
                newItemDate > fiveMinutesAgo
              )
            })

            const updatedData = [...recentData, ...filteredNewData].sort(
              (a, b) => a.date.getTime() - b.date.getTime(),
            )

            return {
              ...item,
              data: updatedData,
            }
          }),
        )
      }
      animationFrameId = requestAnimationFrame(updateData)
    }

    animationFrameId = requestAnimationFrame(updateData)

    return () => cancelAnimationFrame(animationFrameId)
  }, [newData, isLive]) // Make sure to include all dependencies here

  return isLive ? liveData : data
}
