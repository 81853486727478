import { FC } from 'react'

import { Box, Stack, List, ListItem, Typography } from '@mui/material'
import SVG from 'react-inlinesvg'

import 'simplebar-react/dist/simplebar.min.css'
import '../MapAside/styles.css'

import { IconSitemap, IconTableSort } from 'src/assets/icons'
import { IconWithText } from 'src/components/IconWithText'
import PieChart from 'src/ui/PieChart/PieChart'

import { IFactoryLine } from '../../types'

interface ProductionAverageProps {
  lines: IFactoryLine[]
  status: {
    synapseNormal: number
    synapseWarning: number
    synapseFailure: number
    synapseUnknown: number
    synapseFault: number
  }
  handleSorting?: (columnName: string) => void
}

// const handleColorChip = (option: number): string =>
//   option === 0 ? '#C92A2A' : option < 2000 ? '#E9A100' : '#37B24D'

export const ProductionAverage: FC<ProductionAverageProps> = ({
  lines,
  status,
  // timestamp,
  handleSorting,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        mt: '64px',
      }}
    >
      <Typography
        sx={{
          fontSize: '22px',
          fontWeight: 700,
          color: '#272B30',
        }}
      >
        Line Performance
      </Typography>
      {/* <Typography
        variant="body2"
        sx={{
          mt: '16px',
          color: '#4F575E',
        }}
      >
        The values listed below are generated at
      </Typography> */}
      {/* <Typography
        variant="body2"
        sx={{
          fontWeight: 700,
          color: '#4F575E',
          mt: '4px',
        }}
      >
        {timestamp}
      </Typography> */}
      <Stack sx={{ mt: '24px' }}>
        <Box
          sx={{
            display: 'flex',
            // justifyContent: 'space-between',
            pb: '16px',
            borderBottom: '1px solid #CED4DA',
            mb: '22px',
          }}
        >
          <Box
            onClick={() => {
              handleSorting && handleSorting('name')
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              ml: '16px',
              flex: 0.75,
              cursor: 'pointer',
            }}
          >
            <Typography
              sx={{
                color: '#272B30',
                fontSize: '12px',
                fontWeight: 700,
              }}
            >
              Line Label
            </Typography>
            <SVG
              src={IconTableSort}
              width={16}
              height={16}
              style={{ marginLeft: '8px' }}
            />
          </Box>
          <Box
            onClick={() => {
              handleSorting && handleSorting('synapseStatus')
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <Typography
              sx={{
                color: '#272B30',
                fontSize: '12px',
                fontWeight: 700,
              }}
            >
              Synapse Status
            </Typography>
            <SVG
              src={IconTableSort}
              width={16}
              height={16}
              style={{ marginLeft: '8px' }}
            />
          </Box>
        </Box>
      </Stack>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          p: 0,
        }}
      >
        {lines.map((line, idx) => {
          return (
            <ListItem
              key={`${line.name}${idx}`}
              sx={{
                display: 'flex',
                p: '8px 16px',
                border: '1px solid #CED4DA',
                borderRadius: '4px',
                bgcolor: '#F1F3F5',
              }}
            >
              <IconWithText
                src={IconSitemap}
                text={line.name}
                styles={{
                  color: '#4F575E',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  width: '100%',
                }}
                stylesContainer={{
                  width: '203px',
                }}
              />
              {/* <Box
                sx={{
                  p: '5.5px 16px',
                  borderRadius: '100px',
                  color: '#FFF',
                  bgcolor: handleColorChip(line.avgProductionRate),
                }}
              >
                {line.avgProductionRate}
              </Box> */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <PieChart
                  props={{
                    data: [
                      {
                        label: 'normal',
                        value: line.synapseStatus.normal,
                      },
                      {
                        label: 'warning',
                        value: line.synapseStatus.warning,
                      },
                      {
                        label: 'fault',
                        value: line.synapseStatus.fault,
                      },
                      {
                        label: 'failure',
                        value: line.synapseStatus.failure,
                      },
                      {
                        label: 'unknown',
                        value: line.synapseStatus.unknown,
                      },
                    ],
                    thresholds: {
                      normal: {
                        value: status.synapseNormal,
                        color: '#37B24D',
                      },
                      warning: {
                        value: status.synapseWarning,
                        color: '#E9A100',
                      },
                      fault: {
                        value: status.synapseFault,
                        color: '#b95000',
                      },
                      failure: {
                        value: status.synapseFailure,
                        color: '#C92A2A',
                      },
                      unknown: {
                        value: status.synapseUnknown,
                        color: '#6A7178',
                      },
                    },
                    innerRadius: 10,
                    outerRadius: 20,
                  }}
                />

                <Box
                  sx={{
                    ml: '8px',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                >
                  <p style={{ marginBottom: '7px' }}>
                    <Box
                      component={'span'}
                      sx={{ color: '#37B24D', mr: '4px' }}
                    >
                      Normal
                    </Box>
                    = {line.synapseStatus.normal}
                  </p>
                  <p style={{ marginBottom: '7px' }}>
                    <Box
                      component={'span'}
                      sx={{ color: '#E9A100', mr: '4px' }}
                    >
                      Warning
                    </Box>
                    = {line.synapseStatus.warning}
                  </p>
                  <p style={{ marginBottom: '7px' }}>
                    <Box
                      component={'span'}
                      sx={{ color: '#C92A2A', mr: '4px' }}
                    >
                      Fault
                    </Box>
                    = {line.synapseStatus.fault}
                  </p>
                </Box>
              </Box>
            </ListItem>
          )
        })}
      </List>
    </Box>
  )
}
