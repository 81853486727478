import React, { FC, useState, useRef, useEffect } from 'react'
import { Close } from '@mui/icons-material'
import { IconButton, Box, Popover } from '@mui/material'
import { DateRangePicker as DatePicker, DateRange } from 'mui-daterange-picker'
import SVG from 'react-inlinesvg'
import { IconCalendar } from 'src/assets/icons'

interface DateRangePickerProps {
  onDateRangeSelected: (dateRange: DateRange) => void
  iconColor?: string
  resetRange?: boolean
}

const DateRangePicker: FC<DateRangePickerProps> = ({
  onDateRangeSelected,
  iconColor,
  resetRange,
}) => {
  const [open, setOpen] = useState(false)
  const [dateRange, setDateRange] = useState<DateRange>({})
  const anchorRef = useRef<HTMLButtonElement>(null)
  const toggle = (): void => setOpen(!open)

  useEffect(() => {
    // Update the key whenever the dateRange state changes
    resetRange && setDateRange({})
  }, [resetRange])

  const handleDateRangeSelected = (range: DateRange): void => {    
    if ((range as any).label) {
      setDateRange({
        startDate: range.startDate,
        endDate: range.endDate,
      })
      onDateRangeSelected({
        startDate: range.startDate,
        endDate: range.endDate,
      })
      setOpen(false)
      return
    }
    setDateRange(range)
    onDateRangeSelected(range)
    setOpen(false)
  }

  const datePicker = (
    <Box>
      <DatePicker
        initialDateRange={dateRange}
        wrapperClassName="date-range-picker"
        open={open}
        toggle={toggle}
        onChange={handleDateRangeSelected}
      />
    </Box>
  )

  return (
    <div>
      <IconButton
        onClick={toggle}
        disableRipple
        ref={anchorRef}
        aria-controls={open ? 'date-picker-popover' : undefined}
        aria-haspopup="true"
      >
        <Box
          component={SVG}
          sx={{
            width: '1.5rem',
            height: '1.5rem',
            '& path': {
              stroke: iconColor ?? '#303D60',
            },
          }}
          src={IconCalendar}
        ></Box>
      </IconButton>
      <Popover
        sx={{ padding: 0 }}
        id="date-picker-popover"
        open={open}
        anchorEl={anchorRef.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Box sx={{ position: 'relative', width: 'fit-content' }}>
          <IconButton
            sx={{ position: 'absolute', top: 8, right: 8, zIndex: 5 }}
            size="small"
            onClick={() => setOpen(false)}
          >
            <Close />
          </IconButton>
          {datePicker}
        </Box>
      </Popover>
    </div>
  )
}

export default DateRangePicker
