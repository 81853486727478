import { FC, useEffect, useState } from 'react'

import { Box, Stack, Typography, InputLabel } from '@mui/material'
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Link, useNavigate } from 'react-router-dom'

import { ImagePrideLogin, ImageSynapseLogo } from 'src/assets/images'
import { initSocket } from 'src/helpers/socketManager'
import { routes } from 'src/router'
import { loginUser } from 'src/store/reducers/authReducer'
import { RootState } from 'src/store/store'
import { CustomButton } from 'src/ui/Button'
import { Checkbox } from 'src/ui/Checkbox'
import { Image } from 'src/ui/Image'
import { Input } from 'src/ui/Input'
import { useForm } from 'react-hook-form'

export const LoginForm: FC = () => {
  // const [email, setEmail] = useState('')
  // const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch()
  const isLoading = useSelector((state: RootState) => state.authReducer.loading)
  const form = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  })
  useEffect(() => {
    const userData = localStorage.getItem('userData')
    if (userData) {
      const { email, password } = JSON.parse(userData)
      form.setValue('email', email)
      form.setValue('password', password)

      setRememberMe(true)
    }
  }, [])

  const login = async (e: Event): Promise<void> => {
    e.preventDefault()

    const { email, password } = form.getValues()
    if (!email || !password) {
      setError('Please fill in all fields')
      return
    }

    try {
      const res = (await dispatch(
        loginUser({ email, password }),
      ).unwrap()) as unknown as { user: any; success: boolean; message: string }
      if (rememberMe) {
        localStorage.setItem('userData', JSON.stringify({ email, password }))
      } else {
        localStorage.removeItem('userData')
      }

      if (!res.success) {
        throw new Error(res.message)
      } else {
        localStorage.setItem('user', JSON.stringify(res.user))
        initSocket(res.user.token)
      }

      navigate(routes.fleetView)
    } catch (err) {
      const error = err as Error
      if (error.message) {
        setError(error.message)
      } else if (typeof err === 'string') {
        setError(err)
      } else {
        setError('Something went wrong')
      }
    }
  }

  return (
    <Stack
      alignItems="center"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '435px',
        bgcolor: '#FFF',
        p: '0',
        borderRadius: '24px',
        boxShadow: '0px 20px 40px rgba(0,0,0,0.4)',
      }}
    >
      <Image
        styles={{
          width: '468px',
          height: '100%',
          borderRadius: '24px 0 0 24px',
        }}
        src={ImagePrideLogin}
      />
      {/* <Image src={IconLogo} styles={{ width: '100px', marginBottom: '32px' }} /> */}
      <Box
        sx={{
          width: '632px',
          p: '48px',
        }}
      >
        {/* <Typography
          sx={{
            color: '#000',
            fontWeight: 700,
            textAlign: 'center',
            mb: '16px',
            fontSize: '36px',
          }}
        >
          Welcome back
        </Typography> */}

        <Box
          sx={{
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            mb: '32px',
            alignItems: 'center',
            height: '200px',
            width: 'fit-content',
          }}
        >
          <Image
            src={ImageSynapseLogo}
            styles={{
              width: '100%',
              height: '100%',
            }}
          />
        </Box>

        <Form>
          <Stack sx={{ mb: '16px' }}>
            <InputLabel
              htmlFor="email"
              sx={{
                fontSize: '14px',
                lineHeight: '18px',
                my: '16px',
                fontWeight: 500,
                color: '#4F575E',
              }}
            >
              Email
            </InputLabel>
            <Input
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                form.setValue('email', e.target.value)
              }
              type="text"
              name="email"
              id="email"
              value={form.watch('email')}
              styles={{
                p: '8px',
                fontSize: '14px',
                '&>input': {
                  p: 0,
                },
              }}
            />
          </Stack>
          <Stack sx={{ mb: '16px' }}>
            <InputLabel
              htmlFor="password"
              sx={{
                fontSize: '14px',
                lineHeight: '18px',
                mb: '16px',
                fontWeight: 500,
                color: '#4F575E',
              }}
            >
              Password
            </InputLabel>
            <Input
              value={form.watch('password')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                form.setValue('password', e.target.value)
              }
              type="password"
              name="password"
              id="password"
              styles={{
                p: '8px',
                fontSize: '14px',
                '&>input': {
                  p: 0,
                },
              }}
            />
          </Stack>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: '16px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: '8px',
              }}
            >
              <Checkbox
                name="remember"
                id="remember"
                checked={rememberMe}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  setRememberMe(e.target.checked)
                }
                styles={{
                  cursor: 'pointer',
                  width: '14px',
                  height: '14px',
                  borderRadius: '2px',
                  border: '1px solid #CED4DA',
                  borderColor: '#CED4DA',
                  '& .MuiSvgIcon-root': {
                    fontSize: '20px',
                    color: '#1068EB !important',
                  },
                }}
                value={rememberMe}
              />
              <InputLabel
                htmlFor="remember"
                sx={{
                  fontSize: '14px',
                  lineHeight: '18px',
                  ml: '16px',
                  fontWeight: 500,
                  color: '#4F575E',
                  cursor: 'pointer',
                }}
              >
                Remember me
              </InputLabel>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: '8px',
              }}
            >
              <Link
                to={routes.forgotPassword}
                style={{
                  fontSize: '14px',
                  lineHeight: '18px',
                  fontWeight: 500,
                  color: '#1068EB',
                }}
              >
                Forgot password?
              </Link>
            </Box>
          </Box>

          {error && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: '16px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#DC2020',
                }}
              >
                {error}
              </Box>
            </Box>
          )}

          <CustomButton
            type="button"
            appearance="primary-soft-large"
            onClick={login}
            isLoading={isLoading}
            styles={{
              width: '100%',
            }}
          >
            Log In
          </CustomButton>
        </Form>
      </Box>
    </Stack>
  )
}
