import React, { FC } from 'react'

import { Box, Checkbox, Typography } from '@mui/material'
import SVG from 'react-inlinesvg'

import { IconChevronDown } from 'src/assets/icons'

const SelectAssetButton: FC<{
  item: {
    id: number
    name: string
    rowIcon: string
    assetCount?: number
    isSelected: boolean
    isHidden?: boolean
  }
  type: 'factory' | 'line' | 'bodymaker'
  isLast: boolean
  onClick?: (id: number) => void
}> = ({ item, type, isLast, onClick }) => {
  const [checked, setChecked] = React.useState(false)
  // const [isSelected, setIsSelected] = React.useState(false)
  const isSelected = item.isSelected
  console.log('isSelected', isSelected)

  return (
    <Box
      component={'button'}
      sx={{
        backgroundColor: 'transparent',
        cursor: 'pointer',
        width: '100%',
        minHeight: '72.667px',
        display: item.isHidden ? 'none' : 'block',
      }}
      onClick={() => {
        if (onClick) {
          setChecked((prev) => !prev)
          onClick(item.id)
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          minHeight: '72.667px',
          alignItems: 'center',
          backgroundColor: isSelected ? '#12558E' : '#FFFFFF',
          p: '12px 16px',
          borderBottom: '1px solid #CED4DA',
          borderRadius: isLast ? '0px 0px 4px 4px' : '',
          '&:hover': {
            bgcolor: isSelected ? '#12558E' : '#E9ECEF',
          },
        }}
      >
        {/* {type !== 'factory' && (
          <Checkbox
            checked={checked}
            value={`${type}-${item.name}`}
            sx={{
              width: '24px',
              height: '24px',
              border: '1px solid #6A7178',
              svg: {
                fill: '#6A7178',
                strokeWidth: '1px',
              },
              '&.Mui-checked svg': {
                fill: '#1068EB',
                color: '#1068EB',
              },
              borderRadius: '4px',
              p: 0,
              '& .MuiSvgIcon-root': {
                fontSize: 30,
                // icon: {
                //   strokeWidth: '1px',
                // },
              },
            }}
            icon={<Box />}
            onChange={(e: any) => {
              setChecked(e.target.checked)
              onClick && onClick(item.id)
              // if (onChange) {
              //   onChange()
              // }
            }}
          />
        )} */}
        <Box
          component={SVG}
          src={item.rowIcon}
          sx={{
            mx: '16px',
            width: '36px',
            height: '36px',
            path: {
              fill:
                type === 'line' ? undefined : isSelected ? '#fff' : '#6A7178',
              stroke: isSelected ? '#fff' : '#6A7178',
            },
          }}
        ></Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 500,
                color: isSelected ? '#fff' : '#4F575E',
              }}
            >
              {item.name}
            </Typography>
            {type !== 'bodymaker' && (
              <Typography
                sx={{
                  fontSize: '12px',
                  color: isSelected ? 'rgba(255,255,255,0.7)' : '#ADB5BD',
                  textAlign: 'left',
                }}
                component={'p'}
              >
                {item.assetCount} assets
              </Typography>
            )}
          </Box>

          <SVG
            src={IconChevronDown}
            style={{
              fill: isSelected ? '#fff' : '#1068EB',
              rotate: '270deg',
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default SelectAssetButton
