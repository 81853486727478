import React, { FC, useState } from 'react'

import { KeyboardArrowUp } from '@mui/icons-material'
import {
  Box,
  TableRow,
  TableCell,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import SVG from 'react-inlinesvg'
import { useNavigate } from 'react-router-dom'
import { Row } from 'react-table'

import {
  IconFlagWarning,
  IconFlagSuccess,
  IconFlagFailure,
  IconClockVariant,
} from 'src/assets/icons'
import convertTimestampToLocal from 'src/helpers/timestamp-generator'
import { CustomButton } from 'src/ui/Button'

import { IDevice } from '../../types'

export const rowStyles = {
  Active: {
    bgGradient: {
      background: `linear-gradient(0deg, rgba(55, 178, 77, 0.05),
       rgba(55, 178, 77, 0.05))`,
    },
    border: {
      borderTop: '2px solid #37B24D',
    },
    bg: {
      background: '#37B24D',
    },
  },
  Idle: {
    bgGradient: {
      background: `linear-gradient(0deg, rgba(255, 196, 0, 0.05),
       rgba(255, 196, 0, 0.05))`,
    },
    border: {
      borderTop: '2px solid #FFC400',
    },
    bg: {
      background: '#FFC400',
    },
  },
  Disconnected: {
    bgGradient: {
      background: `linear-gradient(0deg, rgba(106, 113, 120, 0.05),
        rgba(106, 113, 120, 0.05))`,
    },
    border: {
      borderTop: '2px solid #6A7178',
    },
    bg: {
      background: '#6A7178',
    },
  },
  DEFAULT: {
    bgGradient: {
      background: `linear-gradient(0deg, rgba(201, 42, 42, 0.05),
       rgba(201, 42, 42, 0.05))`,
    },
    border: {
      borderTop: '2px solid #C92A2A',
    },
    bg: {
      background: '#C92A2A',
    },
  },
}

// const timesData = [
//   {
//     timestamp: '3:15:47 AM CEST 10/12/2022',
//     description: `Bottom Former production manually stoped.`,
//     status: 'Warning',
//   },
//   {
//     timestamp: '3:15:47 AM CEST 10/12/2022',
//     description: `Bottom Former production manually stoped.`,
//     status: 'Warning',
//   },
//   {
//     timestamp: '3:15:47 AM CEST 10/12/2022',
//     description: `Bottom Former production reached Threshold level.`,
//     status: 'Success',
//   },
//   {
//     timestamp: '3:15:47 AM CEST 10/12/2022',
//     description: `Bottom Former production manually stoped.`,
//     status: 'Success',
//   },
// ]

export const handleColorChip = (option?: string): string => {
  switch (option) {
    case 'Active':
      return '#37B24D'
    case 'Idle':
      return '#FFC400'
    case 'Disconnected':
      return '#6A7178'
    default:
      return '#C92A2A'
  }
}

export const handleFlagIcon = (option?: string): JSX.Element => {
  switch (option) {
    case 'Active':
      return <SVG src={IconFlagSuccess} />
    case 'Idle':
      return <SVG src={IconFlagWarning} />
    case 'Disconnected':
      return <SVG src={IconFlagFailure} />
    default:
      return <SVG src={IconFlagFailure} />
  }
}
export const handleLogBgColor = (option?: string): string => {
  switch (option) {
    case 'Active':
      return 'rgba(55, 178, 77, 0.15)'
    case 'Idle':
      return 'rgba(255, 196, 0, 0.15)'
    case 'Disconnected':
      return 'rgba(106, 113, 120, 0.15)'
    default:
      return 'rgba(201, 42, 42, 0.15)'
  }
}

type TStatus = keyof typeof rowStyles
export interface ExtendableLogRowProps {
  row: Row<IDevice>
  handleClick: () => void
}

export const ExtendableLogRow: FC<ExtendableLogRowProps> = ({
  row,
  handleClick,
}) => {
  const { connectionStatus: connectionStatusJSX, line, id } = row.original
  const connectionStatus =
    (connectionStatusJSX as any)?.props?.children || 'DEFAULT'
  // const [show, setShow] = useState(false)
  console.log(row.original, 'roww')
  console.log(connectionStatus)

  const navigate = useNavigate()

  const { key: rowKey, role: rowRole } = { ...row.getRowProps() }
  // const logs = (row.original as any).machineLogs
  // const [allLogs, setAllLogs] = useState<any>([])
  // const [loading, setLoading] = useState(false)
  // const [pagination, setPagination] = useState({
  //   page: 1,
  //   limit: 10,
  //   totalPages: 1,
  //   onPageChange: () => {
  //     setPagination((prev) => ({
  //       ...prev,
  //       page: prev.page + 1,
  //     }))
  //   },
  // })
  // useEffect(() => {
  //   const fetchData = async (bodymakerId: number): Promise<void> => {
  //   //   setLoading(true)
  //   //   const response = await axios.get(
  //   //     `/company/bodymaker-logs/${bodymakerId}`,
  //   //     {
  //   //       params: {
  //   //         page: pagination.page,
  //   //         limit: pagination.limit,
  //   //       },
  //   //     },
  //   //   )
  //   //   const data = await response.data
  //   //   setPagination((prev) => ({
  //   //     ...prev,
  //   //     totalPages: data.totalPages,
  //   //   }))
  //   //   if (pagination.page === 1) {
  //   //     setAllLogs(data.machineLogs)
  //   //   } else {
  //   //     setAllLogs((prev: any) => [...prev, ...data.machineLogs])
  //   //   }
  //   // }

  //   // if ((row.original as any).id && show) {
  //   //   fetchData((row.original as any).id)
  //   //     .then(() => {})
  //   //     .catch((error) => {
  //   //       console.log(error.message)
  //   //     })
  //   //     .finally(() => {
  //   //       setLoading(false)
  //   //     })
  //   // }
  //   // if (!show) {
  //   //   setAllLogs([])
  //   //   setPagination((prev) => ({
  //   //     ...prev,
  //   //     page: 1,
  //   //   }))
  //   // }
  // }, [row.original, show, pagination.page, pagination.limit])

  // const openLogModal = (): void => {
  //   setShow(true)
  // }
  // const closeLogModal = (): void => {
  //   setShow(false)
  //   // setAllLogs([])
  // }
  // console.log(logtype, 'logtype')

  return (
    <>
      <TableRow
        key={rowKey}
        role={rowRole}
        sx={{
          height: '72px',
          ...rowStyles[
            connectionStatus === 'N/A'
              ? 'DEFAULT'
              : (connectionStatus as TStatus)
          ].bgGradient,
          ...rowStyles[
            connectionStatus === 'N/A'
              ? 'DEFAULT'
              : (connectionStatus as TStatus)
          ].border,
        }}
      >
        <TableCell
          {...row.cells[0].getCellProps()}
          sx={{
            p: '20px 0 12px 0',
            display: 'flex',
            gap: '12px',
            alignItems: 'center',
            border: 'none',
          }}
        >
          <Box
            sx={{
              ...rowStyles[
                connectionStatus === 'N/A'
                  ? 'DEFAULT'
                  : (connectionStatus as TStatus)
              ].bg,
              display: 'grid',
              p: '6px 8px',
              borderRadius: '0px 4px 4px 0px',
            }}
          >
            {handleFlagIcon(connectionStatus)}
          </Box>
          {row.cells[0].render('Cell')}
        </TableCell>
        <TableCell
          sx={{
            border: 'none',
            '&>div>span': {
              color: '#272B30',
            },
            '&>div>svg>path': {
              stroke: '#272B30',
            },
          }}
          {...row.cells[1].getCellProps()}
        >
          {row.cells[1].render('Cell')}
        </TableCell>
        <TableCell
          sx={{
            border: 'none',
            '&>div>span': {
              color: '#272B30',
            },
            '&>div>svg>path': {
              stroke: '#272B30',
            },
          }}
          {...row.cells[2].getCellProps()}
        >
          {row.cells[2].render('Cell')}
        </TableCell>
        <TableCell {...row.cells[3].getCellProps()} sx={{ border: 'none' }}>
          {row.cells[3].render('Cell')}
        </TableCell>
        <TableCell {...row.cells[4].getCellProps()} sx={{ border: 'none' }}>
          {row.cells[4].render('Cell')}
        </TableCell>
        <TableCell
          key={'extendCell'}
          role="cell"
          sx={{
            p: '12px 16px',
            border: 'none',
          }}
        >
          <IconButton size="small" onClick={handleClick}>
            <KeyboardArrowUp color="action" />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          ...rowStyles[
            connectionStatus === 'N/A'
              ? 'DEFAULT'
              : (connectionStatus as TStatus)
          ].bgGradient,
        }}
      >
        <TableCell
          colSpan={row.cells.length + 1}
          sx={{
            p: '16px 0 0',
            // p: '16px 61px 48px 76px',
          }}
        >
          <Stack>
            <Box
              sx={{
                display: 'flex',
                gap: '24px',
                p: '0 64px 0',
              }}
            >
              <CustomButton
                appearance="primary-soft-small"
                onClick={() => {
                  navigate(
                    `/factories/${line.plant.id}/lines/${line.id}/machine/${id}`,
                  )
                }}
              >
                View Dashboard
              </CustomButton>
              {/* <CustomButton appearance="primary-soft-small">
                Add New Log
              </CustomButton> */}
              {/* <CustomButton appearance="primary-soft-small">
                Modify Asset
              </CustomButton> */}
              <CustomButton
                appearance="primary-soft-small"
                onClick={() => {
                  console.log(
                    `/factories/${line.plant.id}/lines/${line.id}/machine/${id}#Logs`,
                  )

                  navigate(
                    `/factories/${line.plant.id}/lines/${line.id}/machine/${id}#Logs`,
                  )
                }}
              >
                View Log History
              </CustomButton>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '24px',
                alignItems: 'center',
                justifyContent: 'space-between',
                my: '28px',
                p: '0 64px 0',
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '18px',
                    color: '#000',
                  }}
                >
                  Warning: Production Rate
                </Typography>
                <Typography variant="body2" sx={{ mt: '8px' }}>
                  - Threshold Rule for signal Production Rate has reached a
                  Warning Threshold Status.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: '24px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    bgcolor: '#DEE2E6',
                    p: '5.5px 16px',
                    height: '32px',
                    borderRadius: '100px',
                    whiteSpace: 'nowrap',
                    fontWeight: 700,
                  }}
                >
                  Threshold Value: 150
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    bgcolor: '#101213',
                    color: '#FFF',
                    p: '5.5px 16px',
                    height: '32px',
                    borderRadius: '100px',
                    whiteSpace: 'nowrap',
                    fontWeight: 700,
                  }}
                >
                  Received Value: 0
                </Box>
              </Box>
            </Box>
            {/* {logs.map((log: any) => (
              <Box
                key={log.machineLogId}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  lineHeight: '18px',
                  gap: '0 24px',
                  mt: '1px',
                  fontSize: '14px',
                  p: '16px 64px',
                  bgcolor: handleLogBgColor(log.connectionStatus),
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    // justifyContent: 'center',
                    p: '4px',
                    color: '#FFF',
                    borderRadius: '4px',
                    bgcolor: handleColorChip(log.connectionStatus),
                    minWidth: '170px',
                  }}
                >
                  <SVG src={IconClockVariant} />
                  <span
                    style={{
                      marginLeft: '4px',
                    }}
                  >
                    {log.time ? convertTimestampToLocal(log.time) : 'N/A'}
                  </span>
                </Box>
                <Typography sx={{ fontSize: 'inherit', color: '#6A7178' }}>
                  {log.data}
                </Typography>
              </Box>
            ))} */}
          </Stack>
        </TableCell>
      </TableRow>
      {/* <LogModal show={show} onClose={closeLogModal}>
        <>
          {allLogs &&
            allLogs.map((log: any) => (
              <Box
                key={log.machineLogId}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  lineHeight: '18px',
                  gap: '0 24px',
                  mt: '1px',
                  fontSize: '14px',
                  p: '16px 36px',
                  bgcolor: handleLogBgColor(log.status),
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    // justifyContent: 'center',
                    p: '4px',
                    color: '#FFF',
                    borderRadius: '4px',
                    bgcolor: handleColorChip(log.status),
                    minWidth: '170px',
                  }}
                >
                  <SVG src={IconClockVariant} />
                  <span
                    style={{
                      marginLeft: '4px',
                    }}
                  >
                    {convertTimestampToLocal(log.timestamp)}
                  </span>
                </Box>
                <Typography sx={{ fontSize: 'inherit', color: '#6A7178' }}>
                  {log.message}
                </Typography>
              </Box>
            ))}
        </>
                
      {/* {loading&& allLogs?.length === 0 && <CircularProgress />} */}
      {/* {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              my: 2,
            }}
          >
            <CircularProgress />
          </Box>
        )}

        {pagination.totalPages > pagination.page && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              my: 2,
            }}
          >
            {!loading && (
              <CustomButton
                appearance="primary-soft-default"
                onClick={pagination.onPageChange}
              >
                Load More Logs
              </CustomButton>
            )} 
          </Box>
        )}
      </LogModal>} */}
    </>
  )
}
