import { combineReducers } from 'redux'

import authReducer from './authReducer'
import portalReducer from './portalReducer'

const rootReducer = combineReducers({
  authReducer,
  portalReducer,
})

export default rootReducer
