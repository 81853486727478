import { FC, useMemo } from 'react'

import { Box } from '@mui/material'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { PageHeading } from 'src/components/PageHeading'
import { TabsRow } from 'src/components/TabsRow'
import { TabContainer } from 'src/ui/TabContainer'

const homeTaps = [
  {
    title: 'Organization',
    name: 'Overview',
    regex: /^\/$/,
    route: '',
  },
  {
    title: 'Map View',
    name: 'Map',
    regex: /^\/map/,
    route: 'map',
  },
  {
    title: 'Log',
    name: 'Log',
    regex: /^\/log/,
    route: 'log',
  },
]

export const FleetViewPage: FC = () => {
  const path = useLocation().pathname
  const navigation = useNavigate()

  const activeTab = useMemo(() => {
    for (const tab of homeTaps) {
      if (tab.regex.test(path)) {
        return tab
      }
    }
    return homeTaps[0]
  }, [path])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        m: '48px 0 224px',
      }}
    >
      <PageHeading title={activeTab.title} />
      <TabsRow
        tabsArr={homeTaps.map((e) => e.name)}
        active={activeTab.name}
        onClick={(d) =>
          navigation(homeTaps.filter((e) => e.name === d)[0].route ?? '')
        }
        styles={{ mt: '36px' }}
      />
      <TabContainer
        styles={{
          p: 0,
          backgroundColor: 'transparent',
        }}
      >
        <Outlet />
      </TabContainer>
    </Box>
  )
}
