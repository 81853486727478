import React, { FC, useEffect, useState } from 'react'

import { Box } from '@mui/material'
import SVG from 'react-inlinesvg'
import { toast } from 'react-toastify'

import {
  Icon3DCube,
  IconBuildingFactory,
  IconChevronDown,
  IconSitemap,
} from 'src/assets/icons'
import { PageHeading } from 'src/components/PageHeading'
import { Search } from 'src/components/Search'
import { CustomButton } from 'src/ui/Button'

import RadioSelectionCard from './RadioSelectionCard'
// import SelectionCard from './SelectionCard'

const SelectAsset: FC<{
  factory?: any
  lines?: any
  bodymakers?: any
  preSelectedAssets?: any
  reportFactories?: any
  selectAllAssets?: (
    selectedFactories: any,
    selectedLines: any,
    selectedBodymakers: any,
  ) => void
  onBackPress?: () => void
  onStepChange: (
    selectedFactory: any,
    selectedLines: any,
    selectedBodymakers: any,
    // factories: any,
    // lines: any,
    // bodymakers: any,
  ) => void
  // cachedValues: any
}> = ({
  onStepChange,
  selectAllAssets,
  preSelectedAssets: previousAssets,
  reportFactories,
  onBackPress,
  // factory,
  // lines: preservedLines,
  // bodymakers: preservedBodymakers,
  // cachedValues,
}) => {
  const [activeStep, setActiveStep] = useState<number>(
    previousAssets?.lines?.length > 0
      ? 2
      : previousAssets?.factories?.length > 0
      ? 1
      : 0,
  )

  const [preSelectedAssets, setPreSelectedAssets] =
    useState<any>(previousAssets)
  console.log(previousAssets, 'previousAssets')

  const subtitles = [
    'Choose the factory that you would like to generate report for.',
    'Choose the line that you would like to generate report for.',
    'Choose the machine that you would like to generate report for.',
  ]

  const [selectedFactory, setSelectedFactory] = useState<any>(
    preSelectedAssets?.factories ?? null,
  )
  const [selectedLines, setSelectedLines] = useState<any>(
    preSelectedAssets?.lines ?? [],
  )
  const [selectedBodymakers, setSelectedBodymakers] = useState<any>(
    preSelectedAssets?.bodymakers ?? [],
  )
  console.log(selectedBodymakers, selectedLines, selectedFactory)

  const [factories, setFactories] = useState<any>(reportFactories)

  const [lines, setLines] = useState<any>([])
  const [bodymakers, setBodymakers] = useState<any>([])
  const [allFactories, setAllFactories] = useState<any>(reportFactories)
  const [allLines, setAllLines] = useState<any>([])
  const [allBodymakers, setAllBodymakers] = useState<any>([])
  // console.log(preSelectedAssets.bodymakers)

  // useEffect(() => {
  //   // if (cachedValues.factories && cachedValues.factories.length > 0) {
  //   //   activeStep === 0 && setActiveStep(1)
  //   // }
  //   // if (cachedValues.lines && cachedValues.lines.length > 0) {
  //   //   activeStep === 1 && setActiveStep(2)
  //   // }

  //   // if (cachedValues.bodymakers && cachedValues.bodymakers.length > 0) {
  //   //   activeStep === 2 && setActiveStep(3)
  //   // }

  //   // if (cachedValues.factories && cachedValues.factories.length > 0) return
  //   const getFactories = async (): Promise<void> => {
  //     const response = await axios.get('/data/factories')
  //     const data = await response.data.factories
  //     // if (factory) {
  //     //   setFactories(
  //     //     data.map((item: any) => {
  //     //       return {
  //     //         ...item,
  //     //         isSelected: item.factoryId === factory.factoryId,
  //     //       }
  //     //     }),
  //     //   )

  //     //   setAllFactories(
  //     //     data.map((item: any) => {
  //     //       return {
  //     //         ...item,
  //     //         isSelected: item.factoryId === factory.factoryId,
  //     //       }
  //     //     }),
  //     //   )

  //     //   return
  //     // }
  //     setFactories(data)
  //     setAllFactories(data)
  //   }

  //   getFactories()
  //     .then(() => {})
  //     .catch((error) => {
  //       console.log(error)
  //     })
  // }, [])

  useEffect(() => {
    setFactories(reportFactories)
    setAllFactories(reportFactories)
  }, [reportFactories])

  useEffect(() => {
    // console.log(selectedFactory)
    console.log(activeStep)

    if (!selectedFactory) return
    const getLines = (): void => {
      // const response = await axios.get(
      //   `/data/factories/${
      //     selectedFactory.id ?? selectedFactory.factoryId
      //   }/lines`,
      // )
      // const data = await response.data.lines
      const lines = selectedFactory.lines

      setLines(lines.map((line: any) => ({ ...line, isHidden: false })))
      setAllLines(lines.map((line: any) => ({ ...line, isHidden: false })))

      setFactories(
        allFactories.map((factory: any) => {
          if (factory.id === selectedFactory.id && !factory.isSelected)
            factory.isSelected = !factory.isSelected

          return {
            ...factory,
            isHidden: false,
          }
        }),
      )
    }

    getLines()
  }, [selectedFactory])

  useEffect(() => {
    selectedLines?.length > 0 &&
      selectedLines.forEach((line: any) => {
        getBodymakers(line.id)
      })
  }, [previousAssets])

  const getBodymakers = (lineId: number): any => {
    if (!lineId) return
    console.log(lineId)

    // const response = await axios.get(`/data/lines/${lineId}/bodymakers`)
    const bodymakers = selectedFactory.lines.find((line: any) => {
      return line.id === lineId
    }).bodymakers

    if (!bodymakers) return

    setBodymakers((prev: any) => {
      const prevWithHiddenProp = prev?.map((item: any) => {
        return {
          ...item,
          isHidden: false,
        }
      })

      const bodymakersWithHiddenProp = bodymakers.map((item: any) => {
        return {
          ...item,
          isHidden: false,
        }
      })
      if (prev) {
        return [...prevWithHiddenProp, ...bodymakersWithHiddenProp]
      } else {
        return [...bodymakersWithHiddenProp]
      }
    })
    setAllBodymakers((prev: any) => {
      const prevWithHiddenProp = prev?.map((item: any) => {
        return {
          ...item,
          isHidden: false,
        }
      })

      const bodymakersWithHiddenProp = bodymakers.map((item: any) => {
        return {
          ...item,
          isHidden: false,
        }
      })
      if (prev) {
        return [...prevWithHiddenProp, ...bodymakersWithHiddenProp]
      } else {
        return [...bodymakersWithHiddenProp]
      }
    })

    return bodymakers
  }
  const [searchQuery, setSearchQuery] = useState<string>('')

  const searchOnChange = (e: any): void => {
    setSearchQuery(e.target.value)
    if (activeStep === 0) {
      if (e.target.value === '') {
        setFactories(
          allFactories.map((factory: any) => ({
            ...factory,
            isHidden: false,
          })),
        )
        return
      }
      const filteredFactories = allFactories.map((factory: any) => {
        console.log('werwer')

        if (factory.name.toLowerCase().includes(e.target.value.toLowerCase())) {
          return {
            ...factory,
            isHidden: false,
          }
        }
        return {
          ...factory,
          isHidden: true,
        }
      })

      setFactories(filteredFactories)
    } else if (activeStep === 1) {
      console.log('eqrrqwer')

      if (e.target.value === '') {
        setLines(allLines.map((line: any) => ({ ...line, isHidden: false })))
        return
      }
      const filteredLines = allLines.map((line: any) => {
        if (line.name.toLowerCase().includes(e.target.value.toLowerCase())) {
          return {
            ...line,
            isHidden: false,
          }
        }

        return {
          ...line,
          isHidden: true,
        }
      })

      setLines(filteredLines)
    } else {
      console.log(bodymakers, 'bodymakerss')

      if (e.target.value === '') {
        setBodymakers((prev: any) =>
          prev.map((bodymaker: any) => ({
            ...bodymaker,
            isHidden: false,
          })),
        )
      } else {
        const filteredBodymakers = bodymakers.map((bodymaker: any) => {
          console.log(bodymaker, 'fwefwer')

          if (
            bodymaker.name.toLowerCase().includes(e.target.value.toLowerCase())
          ) {
            return {
              ...bodymaker,
              isHidden: false,
            }
          }
          return {
            ...bodymaker,
            isHidden: true,
          }
        })
        setBodymakers(filteredBodymakers)
      }

      // console.log('hmmmm')
    }
  }

  // useEffect(() => {
  //   searchOnChange({ target: { value: '' } })
  // }, [activeStep])
  return (
    <Box
      sx={{
        width: '100%',
        pb: '180px',
      }}
    >
      <PageHeading title="Select Asset" subtitle={subtitles[activeStep]} />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Search
          value={searchQuery}
          onChange={searchOnChange}
          buttonTitle="Next Step"
          onClick={() => {
            if (selectedBodymakers?.length > 0) {
              onStepChange(
                selectedFactory,
                selectedLines,
                selectedBodymakers,
                // allFactories,
                // allLines,
                // allBodymakers,
              )
            } else {
              toast.error('Please select an asset')
            }
          }}
          styles={{
            mt: '48px',
            height: '40px',
          }}
          searchStyles={{
            width: '364px',
          }}
        />
        <Box
          sx={{
            marginTop: '48px',
          }}
        >
          {onBackPress && (
            <CustomButton
              styles={{
                marginRight: '16px',
                '& > div': {
                  marginRight: 0,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                },
              }}
              appearance="clear-white-default"
              onClick={onBackPress}
            >
              <SVG
                src={IconChevronDown}
                style={{
                  fill: '#1068EB',
                  //   marginRight: '8px',
                  marginBottom: '0px',
                  rotate: '90deg',
                }}
              />
              Back
            </CustomButton>
          )}
          {/* <CustomButton
            appearance="primary-soft-default"
            onClick={() => {
              // onStepChange(
              // )

              if (factories.length === 0) return

              console.log(
                factories,
                factories.map((factory: any) => {
                  return factory.lines
                }),
                factories.map((factory: any) => {
                  return factory.lines.map((line: any) => {
                    return line.bodymakers
                  })
                }),
              )

              selectAllAssets &&
                selectAllAssets(
                  factories,
                  factories.map((factory: any) => {
                    return factory.lines
                  }),
                  factories.map((factory: any) => {
                    return factory.lines.map((line: any) => {
                      return line.bodymakers
                    })
                  }),
                )
            }}
          >
            Generate report for all factories
          </CustomButton> */}
        </Box>
      </Box>

      <Box
        sx={{
          display: 'grid',
          gap: '3px',
          width: '100%',
          gridTemplateColumns: 'repeat(3, 1fr)',
          mt: '24px',
        }}
      >
        {factories && factories.length > 0 && (
          <RadioSelectionCard
            isActive={activeStep === 0}
            title="All Factories"
            type="factory"
            data={factories.map((factory: any) => {
              if (selectedFactory) {
                // setActiveStep(1)
                return {
                  ...factory,
                  rowIcon: IconBuildingFactory,
                  assetCount: factory?.lines?.length,
                  isSelected: factory.id === selectedFactory.id,
                }
              }

              return {
                ...factory,
                rowIcon: IconBuildingFactory,
                assetCount: factory?.lines?.length,
                isSelected: false,
              }
            })}
            onSelect={(id: number) => {
              if (selectedFactory && selectedFactory.id === id) {
                setActiveStep(0)
                setSelectedFactory(null)
                setSelectedLines(null)
                setSelectedBodymakers(null)
                return
              }
              const clickedFactory = factories.find((factory: any) => {
                return factory.id === id
              })
              setSelectedLines(null)
              setSelectedBodymakers(null)
              setBodymakers([])
              setLines([])
              setActiveStep(0)
              setSelectedFactory(clickedFactory)
              setActiveStep(1)
              setSearchQuery('')
            }}
          />
        )}

        {selectedFactory && lines && lines.length > 0 && (
          <RadioSelectionCard
            isActive={activeStep === 1}
            title={selectedFactory.name}
            type="line"
            data={lines.map((line: any) => {
              if (preSelectedAssets?.lines?.length > 0) {
                return {
                  ...line,
                  id: line.id,
                  name: line.name,
                  rowIcon: IconSitemap,
                  assetCount: line.numOfBodymakers,
                  isSelected: selectedLines?.find(
                    (selectedLine: any) => selectedLine.id === line.id,
                  )?.isSelected,
                }
              }

              return {
                ...line,
                id: line.id,
                name: line.name,
                rowIcon: IconSitemap,
                assetCount: line.numOfBodymakers,
                // isSelected: false,
              }
            })}
            // selected={
            //   selectedLines && selectedLines.map((line: any) => line.lineId)
            // }
            // onSelectingAllAssets={(lines) => {
            //   // setSelectedBodymakers(lines.map((line: any) => line.bodymakers))
            //   // setSelectedLines(lines)
            //   const bodymakers = lines.flatMap((line: any) => line.bodymakers)
            //   onStepChange(selectedFactory, lines, bodymakers)
            // }}
            onSelect={(id: number) => {
              if (selectedLines && selectedLines[0].id === id) {
                setSelectedLines(null)
                setSelectedBodymakers(null)
                setBodymakers([])
                setActiveStep(1)
                return
              }
              const clickedLine = lines.find((line: any) => line.id === id)

              setSelectedBodymakers(null)
              setBodymakers(
                clickedLine.bodymakers.map((bodymaker: any) => {
                  return {
                    ...bodymaker,
                    isHidden: false,
                  }
                }),
              )

              setSelectedLines([clickedLine])
              setActiveStep(2)
              // setSearchQuery('')

              // console.log(id)
              // setLines((prev: any) => {
              //   return prev.map((line: any) => {
              //     return {
              //       ...line,
              //       isHidden: false,
              //     }
              //   })
              // })

              // const lineWasSelected =
              //   selectedLines?.length &&
              //   selectedLines.find((line: any) => line.id === id)

              // if (lineWasSelected) {
              //   setSelectedLines((prev: any) =>
              //     prev.filter((line: any) => line.id !== id),
              //   )

              //   setBodymakers((prev: any) => {
              //     if (prev) {
              //       console.log(
              //         prev.filter((bodymaker: any) => bodymaker.id !== id),
              //         'filters',
              //       )

              //       return prev.filter((bodymaker: any) => bodymaker.id !== id)
              //     } else {
              //       return []
              //     }
              //   })

              //   setSelectedBodymakers((prev: any) => {
              //     if (prev) {
              //       return prev.filter((bodymaker: any) => bodymaker.id !== id)
              //     } else {
              //       return []
              //     }
              //   })

              //   if (selectedLines.length === 1) {
              //     setSelectedLines(null)
              //     setSelectedBodymakers(null)
              //     setBodymakers(null)
              //     // setLines([])
              //     setActiveStep(1)
              //   }
              //   return
              // }

              // const selectedLine = lines.find((line: any) => line.id === id)
              // getBodymakers(id)
              // setActiveStep(2)
              // setSelectedLines((prev: any) => {
              //   if (prev) {
              //     return [...prev, selectedLine]
              //   } else {
              //     return [selectedLine]
              //   }
              // })
              if (searchQuery) {
                setSearchQuery('')
                // setLines(allLines)
              }
            }}
          />
        )}
        {selectedLines && selectedLines.length > 0 && (
          <RadioSelectionCard
            isActive={activeStep === 2}
            title="Select Bodymaker"
            type="bodymaker"
            // selected={
            //   selectedBodymakers &&
            //   selectedBodymakers.map((bodymaker: any) => bodymaker.id)
            // }

            onSelect={(id: number) => {
              // if (id === -1) {
              //   setSelectedBodymakers(null)
              //   return
              // }
              const clickedBodymaker = bodymakers.find(
                (bodymaker: any) => bodymaker.id === id,
              )

              const bodymakerWasSelected = selectedBodymakers?.find(
                (bodymaker: any) => bodymaker.id === id,
              )

              if (bodymakerWasSelected) {
                setSelectedBodymakers((prev: any) =>
                  prev.filter((bodymaker: any) => bodymaker.id !== id),
                )
                return
              }

              setSelectedBodymakers([clickedBodymaker])

              // const activeBodymaker = bodymakers.find(
              //   (bodymaker: any) => bodymaker.id === id,
              // )

              // const bodymakerWasSelected = selectedBodymakers?.find(
              //   (bodymaker: any) => bodymaker.id === id,
              // )

              // if (bodymakerWasSelected) {
              //   setSelectedBodymakers((prev: any) =>
              //     prev.filter((bodymaker: any) => bodymaker.id !== id),
              //   )
              //   return
              // }

              // setSelectedBodymakers((prev: any) => {
              //   if (prev) {
              //     return [...prev, activeBodymaker]
              //   } else {
              //     return [activeBodymaker]
              //   }
              // })
            }}
            data={bodymakers.map((bodymaker: any) => {
              // console.log(bodymakers, 'bodymakers')
              // console.log(preSelecatedAssets.bodymakers)
              console.log(bodymakers, 'bodymakers')

              // if (preSelectedAssets?.bodymakers?.length > 0) {
              //   console.log(
              //     preSelectedAssets?.bodymakers,
              //     'preSelectedAssets?.bodymakers',
              //   )

              //   return {
              //     ...bodymaker,
              //     id: bodymaker.id,
              //     name: bodymaker.name,
              //     rowIcon: Icon3DCube,
              //     isSelected:
              //       bodymaker.id === preSelectedAssets?.bodymakers[0]?.id,
              //   }
              // }
              // console.log(
              //   preSelectedAssets?.bodymakers,
              //   'preSelectedAssets?.bodymakers',
              // )
              console.log(
                selectedBodymakers?.[0]?.id,
                'selectedBodymakers',
                bodymaker.id === selectedBodymakers?.[0]?.id,
                bodymaker.id,
              )
              console.log({
                ...bodymaker,
                id: bodymaker.id,
                name: bodymaker.name,
                rowIcon: Icon3DCube,
                isSelected: bodymaker.id === selectedBodymakers?.[0]?.id,
              })

              return {
                ...bodymaker,
                id: bodymaker.id,
                name: bodymaker.name,
                rowIcon: Icon3DCube,
                isSelected: bodymaker.id === selectedBodymakers?.[0]?.id,
              }
            })}
          />
        )}
      </Box>
    </Box>
  )
}

export default SelectAsset
