import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import { initSocket } from '../../helpers/socketManager'

interface User {
  id: number
  token: string
  username: string
  firstName: string
  lastName: string
  email: string
  phone?: string
  jobTitle: string
  homePage: string
  assignedMachine: string
  role: string
  status: string
  createdAt: Date
  updatedAt: Date
}

interface AuthState {
  isAuthenticated: boolean
  user: User | null
  loading: boolean
  error: string | null
}

interface LoginData {
  email: string
  password: string
}

interface RequestResetPasswordData {
  email: string
}

interface ResetPasswordData {
  password: string
  confirmPassword: string
  token: string
}

interface RequestResetPasswordResponse {
  success: boolean
  message: string
}

export const loginUser = createAsyncThunk<
  User,
  LoginData,
  { rejectValue: string }
>(
  'auth/loginUser',
  async (
    loginData: LoginData,
    thunkAPI,
  ): Promise<{ user: User; success: boolean } | any> => {
    try {
      const response = await axios.post(`auth/login`, loginData)

      return response.data as { user: User; success: boolean }
    } catch (err) {
      return thunkAPI.rejectWithValue(
        (err as any).response?.data?.message ?? 'An unexpected error occurred',
      )
    }
  },
)

export const checkAuthentication = createAsyncThunk(
  'authentication/checkAuthentication',
  async (_) => {
    const response = await axios.get(`auth/is-authenticated`)
    const isAuth = response.data
    if (isAuth.success) {
      const token = JSON.parse(localStorage.getItem('user') ?? '')?.token
      console.log(token)

      if (token) {
        initSocket(token) // Initialize the socket with the token
      }
    }
    return isAuth.success
  },
)

export const requestResetPassword = createAsyncThunk<
  RequestResetPasswordResponse,
  RequestResetPasswordData,
  { rejectValue: string }
>(
  'auth/requestResetPassword',
  async (resetData: RequestResetPasswordData, thunkAPI) => {
    try {
      const response = await axios.post(
        'auth/request-reset-password',
        resetData,
      )
      // Handle response here if needed

      return response.data as RequestResetPasswordResponse
    } catch (err) {
      console.log(err)

      return thunkAPI.rejectWithValue(
        (err as any).response?.data?.message ?? 'An unexpected error occurred',
      )
    }
  },
)

export const resetPassword = createAsyncThunk<
  RequestResetPasswordResponse,
  ResetPasswordData,
  { rejectValue: string }
>('auth/resetPassword', async (resetData: ResetPasswordData, thunkAPI) => {
  try {
    const response = await axios.post('auth/reset-password', resetData)
    // Handle response here if needed

    return response.data as RequestResetPasswordResponse
  } catch (err) {
    console.log(err)

    return thunkAPI.rejectWithValue(
      (err as any).response?.data?.message ?? 'An unexpected error occurred',
    )
  }
})

const initialState: AuthState = {
  isAuthenticated: false,
  user: localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user') ?? '{}')
    : null,
  loading: false,
  error: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutUser: (state) => {
      state.isAuthenticated = false
      state.user = null
      state.loading = false
      state.error = null
      localStorage.removeItem('user')
    },
    loginUser: (state, action) => {
      state.isAuthenticated = true
      state.user = action.payload.user
      state.loading = false
      state.error = null
      localStorage.setItem('user', JSON.stringify(action.payload.user))
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.isAuthenticated = true
      state.user = action.payload
      state.loading = false
      state.error = null

      // Update user in local storage on login
      localStorage.setItem('user', JSON.stringify(action.payload))
    })
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload ?? 'An error occurred'
    })
    builder.addCase(checkAuthentication.fulfilled, (state, action) => {
      state.isAuthenticated = action.payload

      // Update user in local storage on checkAuthentication
      if (action.payload) {
        const user = JSON.parse(localStorage.getItem('user') ?? '{}')
        state.user = user
      } else {
        localStorage.removeItem('user')
        state.user = null
      }
    })
    builder.addCase(logoutUser, (state) => {
      state.isAuthenticated = false
      state.user = null
      state.loading = false
      state.error = null

      // Remove user from local storage on logout
      localStorage.removeItem('user')
    })

    builder.addCase(requestResetPassword.pending, (state) => {
      state.loading = true
      state.error = null
    })

    builder.addCase(requestResetPassword.fulfilled, (state) => {
      state.loading = false
      state.error = null
      // You can handle successful password reset request here if needed
    })

    builder.addCase(requestResetPassword.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload ?? 'An error occurred'
    })

    builder.addCase(resetPassword.pending, (state) => {
      state.loading = true // Set loading state to true while the action is pending
      state.error = null // Clear any previous error
    })

    builder.addCase(resetPassword.fulfilled, (state) => {
      state.loading = false // Set loading state to false when the action is fulfilled
      state.error = null // Clear any previous error
      // You can handle successful password reset response here if needed
    })

    builder.addCase(resetPassword.rejected, (state, action) => {
      state.loading = false // Set loading state to false when the action is rejected
      state.error = action.payload ?? 'An error occurred'
    })
  },
})

export const { logoutUser } = authSlice.actions

export default authSlice.reducer
