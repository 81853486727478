import { FC, useState, useEffect } from 'react'

import { Box } from '@mui/material'
import GoogleMapReact from 'google-map-react'
import SVG from 'react-inlinesvg'

import { IconMapPin } from 'src/assets/icons'

import styles from './styles/black-white.style.json'
import { useActiveMapStyles } from 'src/modules/AccountProfile/components/MapSelector/helper/map.helpers'

interface MapProps {
  markers?: any[]
  changeMarker?: (marker: any) => void
  currentMarker?: any
  defaultGeolocation?: { lat: number; lng: number }
}

export const Map: FC<MapProps> = (props) => {
  const [activeMapStyle, _] = useActiveMapStyles()

  const {
    changeMarker,
    currentMarker,
    defaultGeolocation = { lat: 20.87900767901059, lng: -93.50215326469416 },
    markers = [],
  } = props
  const MAP_ZOOM = 18
  const [activeMarker, setActiveMarker] = useState<any>(null)

  const [map, setMap] = useState<google.maps.Map | null>(null)
  const apiLoaded = ({
    map,
    maps,
  }: {
    map: google.maps.Map
    maps: any
    ref: Element | null
  }): void => {
    if (map && maps) {
      // map.setMapTypeId('hybrid')
      setMap(map)
      findMapCenterAndSetIt(map, maps)
    }
  }

  // to change the map center when the filters are applied automatically
  useEffect(() => {
    if (map && markers) {
      findMapCenterAndSetIt(map, google.maps)
    }
  }, [markers, map])

  const findMapCenterAndSetIt = (map: google.maps.Map, maps: any): void => {
    const bounds = new maps.LatLngBounds()
    if (markers.length) {
      markers.forEach((marker) => {
        const { lat, lng } = marker
        bounds.extend(new maps.LatLng(lat, lng))
      })
      map.fitBounds(bounds, 600)
    }

    if (markers.length === 1) {
      map.setZoom(MAP_ZOOM)
      map.panTo(new maps.LatLng(markers[0].lat, markers[0].lng))
    }
  }
  const clickOnMap = (value: GoogleMapReact.ClickEventValue): void => {}

  const handleMapState = (
    map: google.maps.Map,
    lat: number,
    lng: number,
    marker: any,
  ): void => {
    if (marker) {
      setActiveMarker(marker)
      map.panTo({ lat, lng })
      map.setZoom(MAP_ZOOM)
    } else {
      findMapCenterAndSetIt(map, google.maps)

      setActiveMarker(null)
    }
  }

  useEffect(() => {
    console.log('defaultGeolocation', currentMarker)
    if (currentMarker) {
      if (map) {
        handleMapState(map, currentMarker.lat, currentMarker.lng, currentMarker)
      }
    } else if (map) {
      handleMapState(map, defaultGeolocation.lat, defaultGeolocation.lng, null)
    }
  }, [currentMarker, map])

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'rgb(147 197 253)',
      }}
    >
      <GoogleMapReact
        defaultZoom={4}
        defaultCenter={defaultGeolocation}
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_API_KEY ?? '',
          libraries: 'places,geometry',
          language: 'en',
          region: 'us',
        }}
        onClick={clickOnMap}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={apiLoaded}
        options={{
          mapTypeId: activeMapStyle.mapTypeId,
          styles:
            activeMapStyle?.styles &&
            require(`./styles/${activeMapStyle.styles}`),
        }}
        onMapTypeIdChange={(e) => {}}
      >
        {!activeMarker &&
          markers.map((marker) => (
            <Marker
              key={marker.id}
              id={marker.id}
              lat={marker.lat}
              lng={marker.lng}
              factoryName={marker.name}
              onClick={(event: any) => {
                if (map) {
                  handleMapState(map, event.lat, event.lng, marker)

                  changeMarker && changeMarker(marker)
                }
              }}
            />
          ))}

        {activeMarker && (
          <Marker
            id={activeMarker.id}
            key={activeMarker.id}
            lat={activeMarker.lat}
            lng={activeMarker.lng}
            factoryName={activeMarker.name}
            onClick={(event: any) => {
              if (map) {
                map.panTo({ lat: event.lat, lng: event.lng })
                map.setZoom(MAP_ZOOM)
              }
            }}
          />
        )}
      </GoogleMapReact>
    </Box>
  )
}

interface MarkerProps {
  id: string
  lat: number
  lng: number
  factoryName: string
  onClick: (event: any) => void
}

const Marker: FC<MarkerProps> = (props) => {
  const handleClick = (lng: number, lat: number): void => {
    props.onClick({
      lat,
      lng,
    })
  }
  return (
    <Box
      onClick={(event) => handleClick(props.lng, props.lat)}
      component={'div'}
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box component={'span'}>
        <SVG
          style={{
            height: '2.25rem',
            width: 'fit-content',
          }}
          src={IconMapPin}
        />
      </Box>
      <Box
        component={'span'}
        sx={{
          backgroundColor: '#303D60',
          color: '#F8F9FA',
          fontSize: '1rem',
          padding: '6px 8px 6px 8px',
          borderRadius: '4px',
          fontWeight: '700',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontFamily: 'Roboto',
        }}
      >
        {props.factoryName}
      </Box>
    </Box>
  )
}
