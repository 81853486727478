import { ReactElement } from 'react'

import { Box, SxProps, Theme } from '@mui/material'

interface TabContainerProps {
  children?: JSX.Element
  styles?: SxProps<Theme> | undefined
}

const defaultStyles: SxProps<Theme> = {
  backgroundColor: '#FFF',
  filter: 'drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.1))',
  padding: '36px 48px 64px 48px',
  borderRadius: '8px',
}

export const TabContainer = ({
  children,
  styles,
}: TabContainerProps): ReactElement => {
  return (
    <Box
      sx={{
        ...defaultStyles,
        ...styles,
      }}
    >
      {children}
    </Box>
  )
}
