import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react'

import * as d3 from 'd3'

import { ChartData } from 'src/modules/MachineView/components/MachineChart'
import { useChartData } from 'src/ui/MultiLineChart/data/DataFetcher'
import { useXScale, useYScale } from 'src/ui/MultiLineChart/data/Scales'
import { LineDrawer } from 'src/ui/MultiLineChart/LineDrawer'

import BottomAxis from './BottomAxis'
import { DashedLine } from './DashedLine'

export interface DataItem {
  date: Date
  value: number
}

export interface Margins {
  top: number
  right: number
  bottom: number
  left: number
}

interface AnimatedLineChartProps {
  data?: ChartData[]
  width: number
  height: number
  margins: Margins
  alerts?: { min: number; max: number }
  newData?: ChartData[]
  isLive: boolean
}

const calculatePadding = (data: DataItem[]): number => {
  const minValue = d3.min(data, (d) => d.value) as number
  const maxValue = d3.max(data, (d) => d.value) as number
  const paddingPercentage = 0.1 // 30% padding

  // Calculate the padding values
  return (maxValue - minValue) * paddingPercentage
}

const AnimatedLineChart: React.FC<AnimatedLineChartProps> = ({
  data: dataProp,
  width,
  height,
  margins,
  newData,
  alerts,
  isLive,
}) => {
  // const theme = useTheme
  const data = useChartData({
    isLive,
    data: dataProp,
    newData,
  })

  const svgRef = useRef<SVGSVGElement>(null)
  const hoverLineRef = useRef<SVGLineElement>(null)

  const [selectedDate, setSelectedDate] = useState<Date | null>(null)

  const padding = useMemo(
    () => calculatePadding(data.flatMap((e) => e.data)),
    [data],
  )

  const xScale = useXScale(data, margins, width, isLive)
  const yScale = useYScale(data, margins, width, height, padding, alerts)

  const onMouseLeave = useCallback(() => {
    setSelectedDate(null)
    d3.select(hoverLineRef.current).attr('stroke', 'transparent')
  }, [])

  const onMouseMove = useCallback(
    (mouse: any) => {
      const mousePos = d3.pointer(mouse, this)
      const sDate = xScale.invert(mousePos[0] - margins.left)

      if (data[0]?.data[0]?.date && sDate > data[0].data[0].date) {
        setSelectedDate(sDate)

        /** Show vertical line on mouse hover */
        d3.select(hoverLineRef.current)
          .attr('x1', mousePos[0] - margins.left)
          .attr('x2', mousePos[0] - margins.left)
          .attr('y1', margins.top)
          .attr('y2', height - margins.bottom)
          .attr('stroke', '#e0e0e0e0')
          .attr('stroke-width', 1)
          .attr('stroke-dasharray', '5,5')
      } else {
        onMouseLeave()
      }
    },
    [
      data,
      height,
      margins.bottom,
      margins.left,
      margins.top,
      xScale,
      onMouseLeave,
    ],
  )

  /**
   * Render the axis
   */
  useEffect(() => {
    d3.select(svgRef.current)
      .select<SVGGElement>('.x-axis')
      // .transition()
      // .duration(-1)
      .attr('transform', `translate(0, ${height - margins.bottom})`)
      .call(d3.axisBottom(xScale))

    const yAxis = d3
      .select(svgRef.current)
      .select<SVGGElement>('.y-axis')
      .attr('transform', `translate(${margins.left}, 0)`)
      .call(d3.axisLeft(yScale))

    yAxis
      .selectAll('.tick line')
      .attr('x2', width - margins.left - margins.right)
      .attr('stroke', '#e0e0e0e0')
    // remove the axis line
    yAxis.select('path').attr('stroke', 'transparent')
  }, [height, margins, width, xScale, yScale, data])

  // Add the mousemove event listener to the svg
  useEffect(() => {
    d3.select(svgRef.current)
      .on('mousemove', onMouseMove)
      .on('mouseleave', onMouseLeave)
  }, [onMouseMove, onMouseLeave])

  return (
    <svg
      className="lineChartSvg"
      width="100%"
      height={height}
      ref={svgRef}
      style={{ overflow: 'visible' }}
    >
      <g transform={`translate(${margins.left}, ${margins.top})`}>
        {/* <g className="x-axis" /> */}
        <BottomAxis
          height={height}
          margins={margins}
          xScale={xScale}
          selectedDate={selectedDate}
        />
        <g className="y-axis" />
        <line ref={hoverLineRef} />

        <linearGradient id="line-gradient" x1="0%" x2="0%" y1="0%" y2="100%">
          <stop offset="0%" stopColor={'#F99551'} stopOpacity={0.1} />
          <stop offset="100%" stopColor={'#F99551'} stopOpacity={0} />
        </linearGradient>
        {alerts?.min && (
          <DashedLine
            value={alerts?.min}
            title="Low-threshold"
            color="#F99551"
            xScale={xScale}
            yScale={yScale}
          />
        )}
        {alerts?.max && (
          <DashedLine
            value={alerts?.max}
            title="High-threshold"
            color="#F99551"
            xScale={xScale}
            yScale={yScale}
          />
        )}
        {data.map((d) => (
          <LineDrawer
            isLive={isLive}
            key={d.key}
            data={d}
            selectedDate={selectedDate}
            xScale={xScale}
            yScale={yScale}
            margin={margins}
          />
        ))}
      </g>
    </svg>
  )
}

export default AnimatedLineChart
