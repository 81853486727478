import { FC } from 'react'

interface ImageProps {
  src: string
  styles?: React.CSSProperties | undefined
}

const defaultStyles: React.CSSProperties | undefined = {
  objectFit: 'cover',
  width: '100%',
  height: '100%',
}

export const Image: FC<ImageProps> = ({ src, styles }) => {
  return (
    <img
      src={src}
      style={{ ...defaultStyles, ...styles }}
    />
  )
}
