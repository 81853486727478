import { FC } from 'react'

import { Typography, Box, SxProps, Theme } from '@mui/material'
import SVG from 'react-inlinesvg'

interface IconWithTextProps {
  src: string
  text: string
  stylesContainer?: SxProps<Theme> | undefined
  styles?: SxProps<Theme> | undefined
}

const defaultStylesContainer: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
}

const defaultStyles: SxProps<Theme> = {
  display: 'inline-block',
  ml: '8px',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 500,
}

export const IconWithText: FC<IconWithTextProps> = ({
  src,
  text,
  stylesContainer,
  styles,
}) => {
  return (
    <Box sx={{ ...defaultStylesContainer, ...stylesContainer }}>
      <SVG src={src} style={{ flexShrink: 0 }} />
      <Typography
        noWrap
        component={'span'}
        sx={{
          ...defaultStyles,
          ...styles,
        }}
      >
        {text}
      </Typography>
    </Box>
  )
}
