import { useEffect, useMemo, useState } from 'react'

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from '@mui/material'
import axios from 'axios'
import SVG from 'react-inlinesvg'
import { useTable } from 'react-table'

import { IconTableSort } from 'src/assets/icons'
import { Search } from 'src/components/Search'
import TableSkeleton from 'src/components/Table/components/TableSkeleton'
import { TabsRow } from 'src/components/TabsRow'
import { useSocket } from 'src/helpers/socketManager'
import AddUserModal from 'src/ui/Modals/AddUserModal'

import MyTableRow from './components/Table/TableRow'

const mockFilters = ['All', 'Online', 'Offline']

const UserManagementComp = () => {
  const [loading, setLoading] = useState(false)
  const [currentUser, setCurrentUser] = useState<any>({})
  const [users, setUsers] = useState<any>([])
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Role',
        accessor: 'role',
      },
      {
        Header: 'Organization',
        accessor: 'organization',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    [],
  )

  const [activeUserIds, setActiveUserIds] = useState<number[]>([])
  const [filter, setFilter] = useState('All' as string)
  const [searchQuery, setSearchQuery] = useState('')
  const [sorting, setSorting] = useState({
    sortBy: 'firstName',
    sortDirection: 'asc',
  })
  const [showAddUserModal, setShowAddUserModal] = useState(false)
  const [forceRefresh, setForceRefresh] = useState(false)
  const updateOriginalRow = (row: any) => {
    setForceRefresh((prev) => !prev)
  }

  useSocket((socket) => {
    socket.on('userActivity', (data: any) => {
      setActiveUserIds(data)
      console.log(data, 'userActivity')
    })
  })
  useEffect(() => {
    setCurrentUser(JSON.parse(localStorage.getItem('user') ?? '{}'))
  }, [])
  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true)
      const result = await axios.get('admin/users', {
        params: {
          searchQuery,
          sortBy: sorting.sortBy,
          sortOrder: sorting.sortDirection,
        },
      })
      const users = await result.data.users

      setUsers([
        ...users.map((user: any) => {
          return {
            ...user,
            organization: user.organization?.name,
            roleValue: user.role,
            role: mapUserRole(user.role),
            plants: user.plants,
          }
        }),
      ])
      setLoading(false)
    }

    void fetchUsers()
  }, [searchQuery, sorting.sortBy, sorting.sortDirection, forceRefresh])
  const handleFormConfirmation = (values: any) => {
    console.log(values)

    setUsers((prev: any) => {
      return [...prev, values]
    })
    setFilteredUsers((prev: any) => {
      return [...prev, values]
    })
  }

  useEffect(() => {
    setUsers((prev: any) => {
      return prev.map((item: any) => {
        if (activeUserIds.includes(item.id)) {
          return {
            ...item,
            status: true,
          }
        }
        return {
          ...item,
          status: false,
        }
      })
    })
  }, [activeUserIds])
  const [filteredUsers, setFilteredUsers] = useState<any>(users)

  useEffect(() => {
    setFilteredUsers(
      users.filter((item: any) => {
        if (filter === 'All') return true
        return item.status === (filter === 'Online')
      }),
    )
  }, [filter, users])

  const { rows, prepareRow } = useTable<any>({
    data: filteredUsers || [],
    columns,
  })
  return (
    <Box
      sx={{
        mt: 4,
      }}
    >
      <AddUserModal
        show={showAddUserModal}
        onClose={() => {
          setShowAddUserModal(false)
        }}
        onConfirm={(formValues) => {
          handleFormConfirmation({ ...formValues, status: false })
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: '48px',
        }}
      >
        <TabsRow<string> tabsArr={mockFilters} onClick={setFilter} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: '48px',
        }}
      >
        <Search
          buttonTitle="Add User"
          onChange={(e) => {
            setSearchQuery(e.target.value)
          }}
          onClick={() => {
            setShowAddUserModal(true)
          }}
          width={364}
          styles={{
            height: '40px',
          }}
        />
      </Box>
      <TableContainer
        component={Paper}
        sx={{ borderRadius: '8px', mt: '48px' }}
        elevation={1}
      >
        <Table size="medium">
          <TableHead>
            <TableRow>
              {/* <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  onChange={(event, checked) => {}}
                    checked={
                      selectedNotifications.length == notifications.length &&
                      selectedNotifications.length != 0
                    }
                    indeterminate={
                      selectedNotifications.length > 0 &&
                      selectedNotifications.length < notifications.length
                    }
                />
              </TableCell> */}
              <TableCell
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSorting({
                    sortBy: 'username',
                    sortDirection:
                      sorting.sortDirection === 'asc' ? 'desc' : 'asc',
                  })
                }}
              >
                Name
                <SVG
                  src={IconTableSort}
                  style={{ marginLeft: '8px', cursor: 'pointer' }}
                  //   onClick={() => handleSortClick(column.id)}
                />
              </TableCell>
              <TableCell>Role</TableCell>
              {currentUser.role === 'globalAdmin' && (
                <TableCell>Organization</TableCell>
              )}
              <TableCell>Status</TableCell>
              <TableCell
                key={'extendedColumn'}
                sx={{
                  width: '72px',
                  p: '17.5px 12px',
                  borderBottom: '1px solid #CED4DA',
                  backgroundColor: '#FFF',
                  color: '#272B30',
                  lineHeight: '21px',
                  '&:first-of-type': {
                    px: '24px',
                    borderTopLeftRadius: '8px',
                  },
                  '&:last-child': {
                    px: '24px',
                    borderTopRightRadius: '8px',
                  },
                  //   ...thStyles,
                }}
              />
            </TableRow>
          </TableHead>
          {loading && <TableSkeleton columnCount={6}></TableSkeleton>}
          {!loading && filteredUsers?.length > 0 && (
            <TableBody sx={{ width: '100%' }}>
              {rows.length > 0 &&
                rows.map((row) => {
                  prepareRow(row)

                  return (
                    <MyTableRow
                      currentUser={currentUser}
                      row={row}
                      key={row.id}
                      updateOriginalRow={(row: any) => {
                        console.log(row)
                        updateOriginalRow(row)
                      }}
                    />
                  )
                })}
            </TableBody>
          )}
          {!loading && filteredUsers.length === 0 && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: 'center', py: '16px' }}>
                  No users found
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {/* <TablePagination
        currentPage={page}
        limit={limit}
        total={totalPage}
        totalItems={(page - 1) * limit + notifications.length}
        onPageChange={(page, limit) =>
          setParams({ limit: limit.toString(), page: page.toString() })
        }
      ></TablePagination> */}
    </Box>
  )
}

export default UserManagementComp

const mapUserRole = (role: string) => {
  switch (role) {
    case 'globalAdmin':
      return 'Global Admin'
    case 'corporateAdmin':
      return 'Corporate Admin'
    case 'plantSupervisor':
      return 'Plant Supervisor'
    case 'corporateViewer':
      return 'Corporate Viewer'
    case 'plantViewer':
      return 'Plant Viewer'
    default:
      return 'Unknown'
  }
}
