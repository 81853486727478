import { FC, useEffect, useState } from 'react'

import { Box, Skeleton } from '@mui/material'
import axios from 'axios'
import { debounce } from 'lodash'
import { useLocation, useParams } from 'react-router-dom'

import { Article as ArticleType } from 'src/modules/KnowledgeBase/types'
import { routes } from 'src/router'
import { IOption } from 'src/ui/Select'

import BaseGrid from '../BaseGrid'
import BaseHeader from '../BaseHeader'

const Article: FC<{ articleData?: ArticleType }> = ({ articleData }) => {
  const location = useLocation()
  const [article, setArticle] = useState<ArticleType | undefined>(
    location.state.article ?? articleData,
  )
  const [filters, setFilters] = useState<IOption[]>([])
  const [selectedFilters, setSelectedFilters] = useState<string[]>([])
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { id } = useParams()

  // const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_PROD

  const handleSearchDebounced = debounce((value: string): void => {
    setSearchQuery(value)
  }, 500)

  const handleFilters = (filter: any): void => {
    setSelectedFilters(filter)
  }

  const setExistingFilters = (existingFilters: string[]): void => {
    const mappedFilters = existingFilters.map((filter) => {
      return {
        label: filter.split('')[0].toUpperCase() + filter.slice(1),
        value: filter,
      }
    })
    setFilters(mappedFilters)
  }

  useEffect(() => {
    const articleId = articleData?.id ?? location.state?.video?.id ?? id

    const getArticle = async (): Promise<void> => {
      const response = await axios.get(`articles/${articleId}`)

      setArticle(response.data.data)
    }

    if (!location.state?.article && !articleData) {
      getArticle().then().catch(console.error)
    } else {
      setArticle(location.state?.article ?? articleData)
      setIsLoading(false)
      location.state.article = undefined
    }
  }, [id, articleData])

  if (!article && !isLoading) return <h3>Article Not Found!</h3>

  return (
    <section>
      <BaseHeader
        title={article?.title ?? 'Article'}
        subtitle="In this section you can find out how to leverage the use of your Pride Platform."
        backButtonLink={`${routes.knowledgeBaseArticles}`}
        onSearchChange={(searchTerm) => {
          handleSearchDebounced(searchTerm)
        }}
        breadcrumbText={article?.title ?? 'Article'}
        onFilterChange={handleFilters}
        filters={filters}
      />

      {isLoading &&
        [...Array(3)].map((_, index) => {
          const minWidth = 50
          const maxWidth = 100
          const width1 =
            Math.floor(Math.random() * (maxWidth - minWidth + 1)) + minWidth
          const width2 =
            Math.floor(Math.random() * (maxWidth - minWidth + 1)) + minWidth
          const width3 =
            Math.floor(Math.random() * (maxWidth - minWidth + 1)) + minWidth
          const width4 =
            Math.floor(Math.random() * (maxWidth - minWidth + 1)) + minWidth

          return (
            <div key={index} style={{ width: '100%', marginBottom: '4rem' }}>
              <Skeleton
                variant="text"
                width={`${width1}%`}
                height={50}
                sx={{ mb: '1rem' }}
              />
              <Skeleton
                variant="text"
                width={`${width2}%`}
                height={50}
                sx={{ mb: '1rem' }}
              />
              <Skeleton
                variant="text"
                width={`${width3}%`}
                height={50}
                sx={{ mb: '1rem' }}
              />
              <Skeleton
                variant="text"
                width={`${width4}%`}
                height={50}
                sx={{ mb: '1rem' }}
              />
            </div>
          )
        })}

      {!isLoading && (
        <Box
          component="pre"
          sx={{
            fontSize: '1rem',
            fontWeight: 400,
            mb: '4rem',
            color: '#4F575E',
            whiteSpace: 'pre-line',
            width: '100%',
            lineHeight: '1.5rem',
          }}
        >
          {article?.content}
        </Box>
      )}

      <Box
        component="h3"
        sx={{
          fontSize: '1.5rem',
          fontWeight: 700,
          mb: '2rem',
          color: '#000',
        }}
      >
        Similar Articles
      </Box>
      <BaseGrid
        filters={selectedFilters}
        getFilters={setExistingFilters}
        key={article?.id}
        searchQuery={searchQuery}
        endpoint={`articles`}
        extraQueries={article?.id ? `currentItemId=${article?.id}` : ''}
        type="article"
        limit={3}
        skeletonCount={3}
      />
    </section>
  )
}

export default Article
