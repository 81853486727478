import React, { FC } from 'react'

import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import SVG from 'react-inlinesvg'

import { IconChevronDown, IconTrashcan } from 'src/assets/icons'
import { CustomButton } from 'src/ui/Button'
import { PageHeading } from 'src/components/PageHeading'
import axios from 'axios'

const PreviousReportSelection: FC<{
  onSkipPress: () => void
  onTemplatePress: (reportId: number) => void
  onDeleteReport: (reportId: number) => void
  reports: any
}> = ({ onSkipPress, onTemplatePress, reports, onDeleteReport }) => {
  return (
    <Box>
      <PageHeading
        title="Select a template report"
        subtitle="Select a previous report to use as a template for this report, or skip this step."
      />
      <Box
        sx={{
          my: '24px',
        }}
      >
        <CustomButton
          styles={{
            '& > div': {
              marginRight: 0,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          }}
          appearance="primary-soft-default"
          onClick={onSkipPress}
        >
          Skip step
          <SVG
            src={IconChevronDown}
            style={{
              fill: '#fff',
              marginLeft: '8px',
              marginBottom: '0px',
              rotate: '270deg',
            }}
          />
        </CustomButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#303D60',
          p: '12px 16px',
          borderRadius: '4px 4px 0px 0px',
        }}
      >
        <Typography
          sx={{
            fontSize: '22px',
            fontWeight: 500,
            color: '#fff',
          }}
        >
          Previous Reports
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: '#fff',
          //   height: '500px',
          //   width: '100%',
          padding: '24px',
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '4px',
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: '16px',
          }}
        >
          {reports.map((report: any) => (
            <Button
              key={report.id}
              component={Paper}
              variant="outlined"
              color="primary"
              onClick={() => {
                onTemplatePress(report.id)
              }}
              sx={{
                marginBottom: '10px',
                width: '100%',
                textAlign: 'left',
                border: '1px solid transparent',
              }}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {report.name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#303D60' }}>
                      {report.reportType}
                    </Typography>
                    <Typography variant="body2">
                      {report.reportTimezoneString}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} sx={{ textAlign: 'right' }}>
                  <Typography variant="body2" sx={{ color: '#303D60' }}>
                    {report.reportFrequency && report.reportFrequency}
                  </Typography>
                  <Typography variant="body2">
                    {report.reportTime && report.reportTime}
                  </Typography>
                </Grid>

                <Grid item sm={2} xs={12} sx={{ textAlign: 'right' }}>
                  <Button
                    onClick={async (e: any) => {
                      e.stopPropagation()

                      const confirm = window.confirm(
                        'Are you sure you want to delete this report?',
                      )

                      if (confirm) {
                        const reportId = report.id
                        try {
                          const deleteReport = await axios.delete(
                            `/reports/recurring-reports/${reportId}`,
                          )

                          if (deleteReport.status === 200) {
                            onDeleteReport(reportId)
                          }
                        } catch (err) {
                          console.log(err)
                        }
                      }
                    }}
                    sx={{
                      mx: '5px',
                    }}
                  >
                    <SVG src={IconTrashcan} />
                  </Button>
                </Grid>
              </Grid>
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default PreviousReportSelection
