import { useState, useEffect } from 'react'

import { KeyboardArrowUp } from '@mui/icons-material'
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  TableCell,
  TableRow,
  Typography,
  Select as MUISelect,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Chip,
  Backdrop,
} from '@mui/material'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import SVG from 'react-inlinesvg'
import { Row } from 'react-table'

import {
  IconUser,
  IconBuildingFactory,
  IconPhone,
  IconMail,
} from 'src/assets/icons'
import { IconWithText } from 'src/components/IconWithText'
import { generateUUID } from 'src/helpers/generateUUID'
import customTheme from 'src/themes/themeLight'
import { CustomButton } from 'src/ui/Button'
import { Select } from 'src/ui/Select'
import { toast } from 'react-toastify'
import PageLoader from 'src/components/PageLoader/PageLoader'
import { GridLoader, SquareLoader } from 'react-spinners'
import ConfirmationModal from 'src/ui/Modals/ConfirmationModal'
interface IUserRow {
  row: Row<any>
  handleCollapse: () => void
  updateRowValues: (values: any) => void
  currentUser: any
}

const ExtendedUserRow = ({
  row,
  handleCollapse,
  updateRowValues,
  currentUser,
}: IUserRow) => {
  const {
    username: name,
    plants: assignedPlants,
    email,
    // id,
    phone,
    role,
    status,
    roleValue,
    organization,
  } = row.original
  const [loading, setLoading] = useState(false)
  const [selectedOrganization, setSelectedOrganization] =
    useState<any>(organization)
  const plantName = row.original.plant
  const [isEditable, setIsEditable] = useState(false)
  // const { key: rowKey = 3, role: rowRole = '' } = { ...row.getRowProps() }

  const [roles, setRoles] = useState<any[]>([])
  const [selectedRole, setSelectedRole] = useState<any>({
    value: roleValue,
    label: role,
  })
  const [plants, setPlants] = useState<any[]>([])
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [organizations, setOrganizations] = useState<any[]>([])

  useEffect(() => {
    const fetchMachineNames = async (): Promise<void> => {
      console.log(selectedOrganization, 'selectedOrganization')

      setSelectedPlants([])
      const response = await axios.get('/data/factory-names', {
        params: {
          organizationId: selectedOrganization.value,
        },
      })
      console.log(response.data.factories)
      if (response.data.factories.length > 0) {
        setPlants(
          response.data.factories.map((plant: any) => {
            return {
              name: plant.name,
              id: plant.id,
            }
          }),
        )
      } else {
        setPlants([])
      }
    }

    const fetchOrgNames = async (): Promise<any> => {
      const response = await axios.get('/data/organization-names')
      console.log(response.data.organizations, 'orgsssssss')
      if (response.data.organizations.length > 0) {
        setOrganizations(
          response.data.organizations.map((org: any) => {
            return {
              label: org.name,
              value: org.id,
            }
          }),
        )
        return response.data.organizations.map((org: any) => {
          return {
            label: org.name,
            value: org.id,
          }
        })
      } else {
        setOrganizations([])
        return []
      }
    }
    if (currentUser.role === 'globalAdmin' && selectedOrganization !== null) {
      fetchOrgNames()
        .then((data: any) => {
          setSelectedOrganization((prev: any) => {
            if (prev && prev.value) return prev
            return {
              value: data.find(
                (organization: any) =>
                  organization.label === selectedOrganization,
              )?.value,
              label: prev,
            }
          })
        })
        .catch(console.log)
      fetchMachineNames().then().catch(console.log)
    } else if (currentUser.role === 'globalAdmin') {
      fetchOrgNames().then().catch(console.log)
    } else {
      fetchMachineNames().then().catch(console.log)
    }
  }, [currentUser.role, selectedOrganization])

  useEffect(() => {
    const fetchRoles = async (): Promise<void> => {
      const response = await axios.get('/admin/roles')
      console.log(response.data)
      if (response.data.length > 0) {
        setRoles(
          response.data.map((role: any) => {
            return {
              value: role.value,
              label: role.label,
            }
          }),
        )
      } else {
        setRoles([])
      }
      // setAssignedMachineValue(response.data[0])
    }

    fetchRoles()
      .then(() => {})
      .catch(console.log)
  }, [])

  useEffect(() => {
    setIsEditable(false)
  }, [row.original])
  const form = useForm({
    defaultValues: {
      name,
      phone,
      email,
      role,
      roleValue,
      assignedPlants,
      assignedPlantName: plantName,
      status: row.original.status,
      organization,
    },
  })

  const [selectedPlants, setSelectedPlants] = useState<any[]>(
    assignedPlants ?? [],
  )

  const handleUserDeletion = async (): Promise<void> => {
    try {
      setLoading(true)

      // deactivates the user
      const response = await axios.delete(
        `/admin/delete-user?id=${row.original.id}`,
      )

      if (response.data.success) {
        toast.success(response.data.message)
      } else {
        toast.error(response.data.message)
      }
    } catch (error: any) {
      setLoading(false)
      toast.error(error.message)
    }

    setShowConfirmation(false)
    setLoading(false)

    updateRowValues(undefined)
  }

  const handleUserDeactivation = async (): Promise<void> => {
    try {
      setLoading(true)

      // deactivates the user
      const response = await axios.patch(
        `/admin/deactivate-user?id=${row.original.id}`,
      )

      if (response.data.success) {
        toast.success(response.data.message)
      } else {
        toast.error(response.data.message)
      }
    } catch (error: any) {
      setLoading(false)
      toast.error(error.message)
    }

    setShowConfirmation(false)
    setLoading(false)

    updateRowValues({
      ...row.original,
      isDeactivated: true,
    })
  }

  const handleUserActivation = async (): Promise<void> => {
    try {
      setLoading(true)

      // activates the user
      const response = await axios.patch(
        `/admin/activate-user?id=${row.original.id}`,
      )

      if (response.data.success) {
        toast.success(response.data.message)
      } else {
        toast.error(response.data.message)
      }
    } catch (error: any) {
      setLoading(false)
      toast.error(error.message)
    }

    setShowConfirmation(false)
    setLoading(false)
    updateRowValues({
      ...row.original,
      isDeactivated: false,
    })
  }

  const handleChange = (event: any) => {
    const inputArray = event.target.value
    const property = 'id'
    const objectCountMap = new Map()

    // Count the occurrences of each property value
    for (const obj of inputArray) {
      const value = String(obj[property])
      objectCountMap.set(
        value,
        ((objectCountMap.get(value) || 0) as number) + 1,
      )
    }

    // Filter out objects with a count greater than one
    const uniquePlants = inputArray.filter((obj: any) => {
      const value = String(obj[property])
      return objectCountMap.get(value) === 1
    })

    setSelectedPlants(uniquePlants)
  }

  const handleFormSubmit = async () => {
    setLoading(true)
    const {
      name,
      email,
      phone,
      roleValue: role,
      // assignedPlants,
    } = form.getValues()
    let assignedPlants = selectedPlants
    if (
      role === 'corporateAdmin' ||
      (role === 'corporateViewer' && assignedPlants.length > 0)
    ) {
      setSelectedPlants([])
      assignedPlants = []
    }

    if (
      phone &&
      !phone.match(/^(?:\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$|^[0-9]{10}$/)
    ) {
      toast.error('Invalid phone number')
      setLoading(false)
      return
    }
    try {
      const response = await axios.patch(
        `/admin/update-user?id=${row.original.id}`,
        {
          username: name,
          role,
          email,
          phone,
          assignedPlants: assignedPlants.map((plant: any) => plant.id),
          organizationId: selectedOrganization?.value,
        },
      )

      if (response.data.success) {
        setIsEditable(false)
        toast.success(response.data.message)
        setSelectedRole({
          value: role,
          label: roles.find((r) => r.value === role)?.label,
        })
        updateRowValues({
          ...row.original,
          name,
          email,
          phone,
          role: roles.find((r) => r.value === role)?.label,
          roleValue: role,
          plants: assignedPlants,
        })
      } else {
        toast.error(response.data.message)
      }
      setLoading(false)
    } catch (err: any) {
      setLoading(false)
      if (err.response.data.message) {
        toast.error(err.response.data.message)
      } else if (err.message) {
        toast.error(err.message)
      } else {
        toast.error('Something went wrong')
      }
    }
  }

  const [buttonAction, setButtonAction] = useState<any>(null)
  return (
    <>
      {showConfirmation && (
        <ConfirmationModal
          onClose={() => setShowConfirmation(false)}
          onConfirm={
            buttonAction === 'deactivate'
              ? handleUserDeactivation
              : buttonAction === 'delete'
              ? handleUserDeletion
              : handleUserActivation
          }
          username={name}
          show={showConfirmation}
          type={buttonAction}
        />
      )}
      <TableRow
        sx={{
          background: `linear-gradient(0deg, rgba(16, 104, 235, 0.05),
           rgba(16, 104, 235, 0.05))`,
        }}
      >
        <TableCell
          sx={{
            borderBottom: 'none',
          }}
        >
          {isEditable ? (
            <OutlinedInput
              {...form.register('name')}
              sx={{
                color: 'secondary.main',
                py: '9.5px',
                height: '40px',
                maxWidth: '246px',
                backgroundColor: '#FFF',
                '&placeholder-shown': {
                  pt: '9.5px',
                },
              }}
              placeholder="Name"
            />
          ) : (
            <Typography
              color="primary"
              sx={{
                lineHeight: '22px',
                fontSize: '18px',
                fontWeight: 500,
                width: 'max-content',
              }}
            >
              {name}
            </Typography>
          )}
        </TableCell>
        <TableCell
          sx={{
            borderBottom: 'none',
          }}
        >
          {isEditable ? (
            <Select
              styles={{
                color: 'secondary.main',
                height: '40px',
                maxWidth: '246px',
                minWidth: '150px',
                backgroundColor: '#FFF',
              }}
              {...form.register('role')}
              options={roles}
              value={
                roles.find((role) => role.value === form.getValues().roleValue)
                  ?.value
              }
              onChange={(e: any) => {
                form.setValue('roleValue', e.target.value)
                form.setValue(
                  'role',
                  roles.find((r) => r.value === e.target.value)?.label,
                )
                setSelectedRole({
                  value: e.target.value,
                  label: roles.find((r) => r.value === e.target.value)?.label,
                })

                console.log(e.target.value, 'e.target.value')
              }}
            />
          ) : (
            <IconWithText
              src={IconUser}
              text={form.getValues().role ?? 'No role assigned'}
              stylesContainer={{
                mt: '16px',
              }}
              styles={{
                color: '#4F575E',
              }}
            />
          )}
        </TableCell>
        {currentUser.role === 'globalAdmin' && (
          <TableCell
            sx={{
              borderBottom: 'none',
            }}
          >
            {isEditable ? (
              <Select
                styles={{
                  color: 'secondary.main',
                  height: '40px',
                  maxWidth: '246px',
                  minWidth: '150px',
                  backgroundColor: '#FFF',
                }}
                placeholder="Select Organization"
                {...form.register('organization')}
                options={organizations}
                value={
                  organizations.find(
                    (org) => org.label === form.getValues().organization,
                  )?.value
                }
                onChange={(e: any) => {
                  form.setValue('organization', e.target.value)

                  setSelectedOrganization({
                    value: e.target.value,
                    label: organizations.find(
                      (organization) => organization.value === e.target.value,
                    )?.label,
                  })

                  console.log(e.target.value, 'e.target.value')
                }}
              />
            ) : (
              <Typography
                sx={{
                  mt: '16px',
                  color: '#4F575E',
                }}
              >
                {form.getValues().organization}
              </Typography>
            )}
          </TableCell>
        )}
        <TableCell
          sx={{
            borderBottom: 'none',
          }}
        >
          <StatusCell status={status} />
        </TableCell>
        <TableCell
          sx={{
            borderBottom: 'none',
          }}
        >
          <IconButton size="small" onClick={handleCollapse}>
            <KeyboardArrowUp color="action" />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          background: `linear-gradient(0deg, rgba(16, 104, 235, 0.05),
           rgba(16, 104, 235, 0.05))`,
        }}
      >
        <TableCell
          sx={{
            p: '22px 24px 0',
            verticalAlign: 'top',
            border: 'none',
          }}
        >
          <Box>
            <Typography sx={{ fontWeight: 500, color: '#6A7178' }}>
              Assigned Plants
            </Typography>
            {isEditable ? (
              <FormControl
                sx={{
                  width: '100%',
                  maxWidth: '246px',
                  mt: 3,
                }}
                size={'small'}
                //  disabled={disabled}
                //  error={error}
              >
                <InputLabel
                  // shrink={Boolean(form.getValues().assignedPlantName)}
                  sx={{
                    '&.MuiInputLabel-shrink': {
                      color: customTheme.palette.action.main,
                    },
                    '& + .Mui-focused fieldset': {
                      borderColor: customTheme.palette.action.main,
                    },
                  }}
                  id="assignedPlants"
                >
                  Select Plants
                </InputLabel>
                <MUISelect
                  disabled={
                    selectedRole.value !== 'plantViewer' &&
                    selectedRole.value !== 'plantSupervisor'
                  }
                  {...form.register('assignedPlants')}
                  labelId="assignedPlants"
                  id="plantSelect"
                  value={selectedPlants}
                  onChange={handleChange}
                  label={'Select Plants'}
                  //  ref={ref}
                  input={<OutlinedInput label="Select Plants" />}
                  renderValue={(selected: any) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value: any) => (
                        <Chip
                          key={value.name}
                          label={value.name}
                          size="small"
                        />
                      ))}
                    </Box>
                  )}
                  multiple
                  sx={{
                    height: '100%',
                  }}
                >
                  {plants &&
                    plants.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option}
                        //  style={getStyles(option.value, selectedValues, theme)}
                      >
                        <Checkbox
                          sx={{
                            'input:checked ~ svg': {
                              color: customTheme.palette.action.main,
                            },
                          }}
                          //  checked={selectedValues.includes(option.value)}
                          checked={selectedPlants
                            .map((plant: any) => plant.id)
                            .includes(option.id)}
                        />
                        <SVG
                          src={IconBuildingFactory}
                          style={{
                            fill: '#6A7178',
                            marginRight: '10px',
                            width: '20px',
                          }}
                        />
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    ))}
                </MUISelect>
              </FormControl>
            ) : assignedPlants?.length > 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 0.5,
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  mt: 3,
                }}
              >
                {assignedPlants.map((value: any) => (
                  <Chip
                    key={value.name}
                    label={value.name}
                    icon={
                      <SVG
                        src={IconBuildingFactory}
                        style={{
                          width: '17.5px',
                          height: '17.5px',
                        }}
                      />
                    }
                    size="medium"
                    sx={{
                      paddingLeft: '10px',
                    }}
                  />
                ))}
              </Box>
            ) : (
              <IconWithText
                src={IconBuildingFactory}
                text={'No plants assigned'}
                stylesContainer={{
                  mt: '16px',
                }}
                styles={{
                  color: '#4F575E',
                }}
              />
            )}
          </Box>
        </TableCell>
        <TableCell
          sx={{
            p: '22px 24px 0',
            verticalAlign: 'top',
            border: 'none',
          }}
        >
          <Typography sx={{ fontWeight: 500, color: '#6A7178' }}>
            Phone Number
          </Typography>
          {isEditable ? (
            <OutlinedInput
              {...form.register('phone')}
              // onChange={(e: any) => {
              //   form.setValue('phone', e.target.value)
              // }}
              // rules={{
              //   required: 'Phone is required',
              // }}
              placeholder="Phone"
              sx={{
                color: 'secondary.main',
                py: '9.5px',
                height: '40px',
                maxWidth: '246px',
                mt: 3,
                backgroundColor: '#FFF',
                '&placeholder-shown': {
                  pt: '9.5px',
                },
              }}
            />
          ) : phone ? (
            <IconWithText
              src={IconPhone}
              text={phone}
              stylesContainer={{
                mt: '16px',
              }}
              styles={{
                color: '#1068EB',
              }}
            />
          ) : (
            <Typography mt={3}>Not set</Typography>
          )}
        </TableCell>

        <TableCell
          // key={`${row.cells[0].getCellProps().key}-extended`}
          key={generateUUID()}
          sx={{
            p: '22px 24px 0',
            verticalAlign: 'top',
            border: 'none',
          }}
        >
          <Typography sx={{ fontWeight: 500, color: '#6A7178' }}>
            E-mail
          </Typography>
          {isEditable ? (
            <OutlinedInput
              {...form.register('email')}
              // value={email}
              // value={formData.email}
              // onChange={(e: any) => {
              //   // handleFormDataChange('email', e.target.value)
              //   form.setValue('email', e.target.value)
              // }}
              // rules={{
              //   required: `Email is required`,
              // }}
              placeholder="Email"
              sx={{
                color: 'secondary.main',
                py: '9.5px',
                height: '40px',
                maxWidth: '246px',
                backgroundColor: '#FFF',
                mt: 3,
                '&placeholder-shown': {
                  pt: '9.5px',
                },
              }}
            />
          ) : (
            <IconWithText
              src={IconMail}
              text={email}
              stylesContainer={{
                mt: '16px',
              }}
              styles={{
                color: '#1068EB',
              }}
            />
          )}
        </TableCell>
        <TableCell
          sx={{
            borderBottom: 'none',
          }}
        />
        {currentUser.role === 'globalAdmin' && (
          <TableCell
            sx={{
              borderBottom: 'none',
            }}
          />
        )}
      </TableRow>
      <TableRow
        sx={{
          '&:last-child': {
            borderBottom: 'none',
          },
          background: `linear-gradient(0deg, rgba(16, 104, 235, 0.05),
           rgba(16, 104, 235, 0.05))`,
        }}
      >
        <TableCell
          colSpan={row.cells.length + 1}
          sx={{
            p: '30px 24px',
            textAlign: 'end',
          }}
        >
          {isEditable ? (
            <>
              <CustomButton
                appearance="primary-soft-default"
                type="button"
                styles={{
                  backgroundColor: '#DC2020',
                  borderColor: '#DC2020',
                  mr: '16px',
                }}
                onClick={() => {
                  setShowConfirmation(true)
                  setButtonAction('delete')
                }}
              >
                Delete User
              </CustomButton>
              {!row.original.isDeactivated ? (
                <CustomButton
                  appearance="primary-soft-default"
                  type="button"
                  styles={{
                    backgroundColor: '#DC2020',
                    borderColor: '#DC2020',
                    mr: '16px',
                  }}
                  onClick={() => {
                    setShowConfirmation(true)
                    setButtonAction('deactivate')
                  }}
                >
                  Deactivate User
                </CustomButton>
              ) : (
                <CustomButton
                  appearance="primary-soft-default"
                  type="button"
                  styles={{
                    mr: '16px',
                  }}
                  onClick={() => {
                    setShowConfirmation(true)
                    setButtonAction('activate')
                  }}
                >
                  Activate User
                </CustomButton>
              )}

              <CustomButton
                appearance="primary-soft-default"
                type="button"
                styles={{ backgroundColor: '#37B24D', borderColor: '#37B24D' }}
                onClick={handleFormSubmit}
              >
                Update
              </CustomButton>
            </>
          ) : (
            <CustomButton
              appearance="primary-soft-default"
              type="button"
              disabled={!row.original.canBeUpdated}
              onClick={() => {
                if (row.original.canBeUpdated) {
                  setIsEditable(true)
                }
              }}
            >
              Edit Info
            </CustomButton>
          )}
        </TableCell>
      </TableRow>

      {loading && (
        <Backdrop
          open={true}
          sx={{ zIndex: 9999, background: 'transparent' }}
        ></Backdrop>
      )}
    </>
  )
}
// {isEditable ? () => <Input {...form.register('phone')} /> : phone}

export default ExtendedUserRow

const StatusCell = ({ status }: { status: boolean }) => {
  return (
    <Box
      sx={{
        display: 'inline-block',
        bgcolor: '#37B24D',
        color: '#FFF',
        p: '3px 8px',
        borderRadius: '100px',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '18px',
      }}
    >
      {status ? 'Online' : 'Offline'}
    </Box>
  )
}
