import { FC, useEffect, useState } from 'react'

import { Box, Typography } from '@mui/material'

import { ChartData } from '../MachineChart'

interface ChartTitleProps {
  title: string
  chartAvg?: string
  data: ChartData[]
  newData?: ChartData[]
  unit?: string
  isLive?: boolean
}

const ChartTitle: FC<ChartTitleProps> = ({
  chartAvg,
  data,
  title,
  unit,
  newData,
  isLive = false,
}) => {
  const [chartMax, setChartMax] = useState(
    data[2]?.data[data[1]?.data?.length - 1]?.value ?? 0,
  )

  useEffect(() => {
    if (newData) {
      setChartMax(newData[1]?.data[newData[1]?.data?.length - 1]?.value ?? 0)
    } else if (data && !isLive) {
      setChartMax(
        data[1]?.data?.sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
        )[data[1]?.data?.length - 1]?.value ?? 0,
      )
    } else {
      setChartMax(0)
    }
  }, [newData, data, isLive])

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: '700',
          marginBottom: '36px',
          color: '#101213',
        }}
      >
        {title}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
        {[
          { key: 'Max', light: '#F99551' },
          { key: 'Min', light: '#F99551' },
        ].map((item, index) => (
          <Box
            key={item.key}
            sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}
          >
            <Box
              sx={{
                width: '16px',
                height: '16px',
                borderRadius: '50%',
                border: `4px solid ${item.light}`,
              }}
            />
            <Typography sx={{ color: item.light, fontWeight: '500' }}>
              {item.key.replace('Data', '').toUpperCase()}
            </Typography>
          </Box>
        ))}
        <Box
          sx={{
            backgroundColor: '#272B30',
            borderRadius: '8px',
            padding: '8px 16px',
            display: 'flex',
            alignItems: 'center',
            marginX: '10px',
          }}
        >
          <Typography sx={{ fontSize: '26px', color: '#FFFFFF' }}>
            {chartMax}
            <span style={{ fontWeight: 300, paddingLeft: '12px' }}>
              {unit ?? 'lbf'}
            </span>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default ChartTitle
