import { FC, useState } from 'react'

import styled from '@emotion/styled'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material'
import { toast } from 'react-toastify'
import { IconBuildingFactory } from 'src/assets/icons'
import customTheme from 'src/themes/themeLight'

const observations = [
  'Normal',
  'Tear-off',
  'Alignment change (domer)',
  'Alignment change (punch)',
  'Bottom former maintenance',
  'Can height error',
  'Chime Wrinkles',
  'Coil Change',
  'Cup Feed Error',
  'Dome door loose',
  'Dome Wrinkles',
  'Double Punch',
  'Elephant foot',
  'Fractured Dome',
  'Major BM maintenance',
  'No can',
  'Punch bolt loose',
  'Punch loose',
  'Punch-through',
  'Shallow Dome',
  'Sugar Scoop and Earing',
  'Tooling change (domer)',
  'Tooling change (toolpack)',
]

export const ClassifyForm: FC<{
  onConfirm: (items: any[]) => void
  onIgnore: () => void
}> = ({ onConfirm, onIgnore }) => {
  const [checked, setChecked] = useState<any>([])

  const handleToggle = (event: any) => {
    console.log(event?.target?.value)

    const inputArray = event?.target?.value

    // const newChecked = inputArray.filter((item: any) => {
    //   return checked.includes(item)
    // })

    setChecked(inputArray)
  }

  return (
    <ClassifyStyles>
      <h4>Classify Behavior</h4>
      <p>
        Choose the most appropriate way to describe the behavior you see in the
        chart on the left.
      </p>

      <FormControl
        sx={{
          width: '100%',
          maxWidth: '246px',
          mt: 3,
        }}
        size={'small'}
        //  disabled={disabled}
        //  error={error}
      >
        <InputLabel
          // shrink={Boolean(form.getValues().assignedPlantName)}
          sx={{
            '&.MuiInputLabel-shrink': {
              color: customTheme.palette.action.main,
            },
            '& + .Mui-focused fieldset': {
              borderColor: customTheme.palette.action.main,
            },
          }}
          id="selectBehavior"
        >
          Select Behavior
        </InputLabel>
        <Select
          labelId="selectBehavior"
          id="behaviorSelect"
          value={checked}
          onChange={handleToggle}
          label={'Select Behavior'}
          //  ref={ref}
          input={<OutlinedInput label="Select Behavior" />}
          renderValue={(selected: any) => (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                overflowY: 'auto',
                gap: 0.5,
                maxHeight: 75,
              }}
            >
              {selected.map((value: any) => (
                <Chip key={value} label={value} size="small" />
              ))}
            </Box>
          )}
          multiple
          sx={{
            height: '100%',
          }}
        >
          {observations &&
            observations.map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox
                  sx={{
                    'input:checked ~ svg': {
                      color: customTheme.palette.action.main,
                    },
                  }}
                  //  checked={selectedValues.includes(option.value)}
                  checked={checked.includes(option)}
                />
                <ListItemText primary={option} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      {/* {observations.map((observation) => (
        <label
          key={observation}
          className="observation-row"
          htmlFor={observation}
        >
          <input
            type="checkbox"
            id={observation}
            title={observation}
            name={observation}
            value={observation}
            onChange={handleToggle(observation)}
          />
          <span>{observation}</span>
        </label>
      ))} */}
      <div className="action-buttons">
        <Button
          color="info"
          variant="contained"
          className="primary"
          onClick={() => {
            if (checked.length === 0) {
              toast.error('Please select at least one behavior')
            }
            onConfirm(checked)
          }}
        >
          Confirm
        </Button>
        <Button color="error" variant="contained" onClick={onIgnore}>
          Ignore
        </Button>
      </div>
    </ClassifyStyles>
  )
}

const ClassifyStyles = styled.div`
  border-radius: 8px;
  background: #f1f3f5;

  /* Shadow / L */
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.1);

  padding: 24px;
  padding-top: 16px;

  color: #6a7178;
  font-weight: 400;

  h4 {
    color: #000;

    font-size: 22px;
    font-weight: 500;
  }

  p {
    color: #4f575e;
    font-weight: 400;
    margin-top: 16px;
    margin-bottom: 36px;

    max-width: 500px;
  }

  .action-buttons {
    display: flex;
    gap: 16px;

    margin-top: 100px;
  }

  .primary {
    background: #1068eb;
  }

  .observation-row {
    display: flex;
    align-items: center;
    cursor: pointer !important;

    input {
      margin: 0px;
      width: 24px;
      aspect-ratio: 1;

      margin: 12px 10px;
      box-shadow: 0px 0px 5px -2px #6a7178;

      /* -moz-appearance: none;
      -webkit-appearance: none;
      -o-appearance: none; */
    }
  }
`
