import { FC, useEffect, useState } from 'react'

import { Box, Stack, Typography, InputLabel } from '@mui/material'
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Form, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { ImagePrideLogin } from 'src/assets/images'
import { routes } from 'src/router'
import { resetPassword } from 'src/store/reducers/authReducer'
import { RootState } from 'src/store/store'
import { CustomButton } from 'src/ui/Button'
import { Image } from 'src/ui/Image'
import { Input } from 'src/ui/Input'

export const ResetPasswordForm: FC = () => {
  const [error, setError] = useState('')
  // get token from url
  const router = useLocation()
  const [token] = useState(new URLSearchParams(router.search).get('token'))
  const navigate = useNavigate()
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch()
  const isLoading = useSelector((state: RootState) => state.authReducer.loading)
  const form = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  })

  useEffect(() => {
    if (localStorage.getItem('user')) {
      navigate('/')
    }

    if (!token) {
      navigate(routes.login)
    }
  }, [navigate, token])

  const resetPasswordReq = async (e: Event): Promise<void> => {
    e.preventDefault()

    const { password, confirmPassword } = form.getValues()

    if (password.length < 8) {
      setError('Password must be at least 8 characters long')
      return
    }

    if (!password || !confirmPassword) {
      setError('Please fill all the fields')
      return
    }

    if (password !== confirmPassword) {
      setError('Password and confirm password must be same')
      return
    }

    if (!token || token === '') {
      setError('No token found')
      return
    }
    try {
      const res = (await dispatch(
        resetPassword({ password, confirmPassword, token }),
      ).unwrap()) as unknown as { success: boolean; message: string }

      if (!res.success) {
        throw new Error(res.message)
      } else {
        setError('')
        toast.success('Password reset successfully, redirecting to login...')
        setTimeout(() => {
          navigate(routes.login)
        }, 2000)
      }
    } catch (err) {
      console.log(err)

      const error = err as Error
      if (error.message) {
        setError(error.message)
      } else if (typeof err === 'string') {
        setError(err)
      } else {
        setError('Something went wrong')
      }
    }
  }

  return (
    <Stack
      alignItems="center"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '435px',
        bgcolor: '#FFF',
        p: '0',
        borderRadius: '24px',
        boxShadow: '0px 20px 40px rgba(0,0,0,0.4)',
      }}
    >
      <Image
        styles={{
          width: '468px',
          height: '652px',
          borderRadius: '24px 0 0 24px',
        }}
        src={ImagePrideLogin}
      />
      {/* <Image src={IconLogo} styles={{ width: '100px', marginBottom: '32px' }} /> */}
      <Box
        sx={{
          width: '632px',
          p: '48px',
        }}
      >
        <Typography
          sx={{
            color: '#000',
            fontWeight: 700,
            textAlign: 'center',
            mb: '16px',
            fontSize: '36px',
          }}
        >
          Reset Password
        </Typography>

        <Form>
          <Stack sx={{ mb: '16px' }}>
            <InputLabel
              htmlFor="password"
              sx={{
                fontSize: '14px',
                lineHeight: '18px',
                mb: '16px',
                fontWeight: 500,
                color: '#4F575E',
              }}
            >
              Password
            </InputLabel>
            <Input
              value={form.watch('password')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                form.setValue('password', e.target.value)
              }
              type="password"
              name="password"
              id="password"
              styles={{
                p: '8px',
                fontSize: '14px',
                '&>input': {
                  p: 0,
                },
              }}
            />
          </Stack>

          <Stack sx={{ mb: '16px' }}>
            <InputLabel
              htmlFor="password"
              sx={{
                fontSize: '14px',
                lineHeight: '18px',
                mb: '16px',
                fontWeight: 500,
                color: '#4F575E',
              }}
            >
              Confirm Password
            </InputLabel>
            <Input
              value={form.watch('confirmPassword')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                form.setValue('confirmPassword', e.target.value)
              }
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              styles={{
                p: '8px',
                fontSize: '14px',
                '&>input': {
                  p: 0,
                },
              }}
            />
          </Stack>

          {error && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: '16px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#DC2020',
                }}
              >
                {error}
              </Box>
            </Box>
          )}

          <CustomButton
            type="button"
            appearance="primary-soft-large"
            onClick={resetPasswordReq}
            isLoading={isLoading}
            styles={{
              width: '100%',
            }}
          >
            Reset Password
          </CustomButton>
        </Form>
      </Box>
    </Stack>
  )
}

export default ResetPasswordForm
