import { FC } from 'react'

import { Box, Grid, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import customTheme from 'src/themes/themeLight'

const ViewAllChip: FC<{ buttonLabel: string; navigateTo: string }> = ({
  buttonLabel,
  navigateTo,
}) => {
  const theme = useTheme<typeof customTheme>()
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.static.main,
        px: '2.5rem',
        py: '1.5rem',
      }}
    >
      <Box
        component="h5"
        sx={{
          color: theme.typography.h5.color,
          fontSize: '1.375rem',
          fontWeight: 500,
          lineHeight: '1.75rem',
        }}
      >
        Didn’t find what you are looking for?
      </Box>
      <Box
        component={Link}
        sx={{
          color: theme.palette.static.main,
          border: `1px solid ${theme.palette.action.main}`,
          borderRadius: '0.25rem',
          backgroundColor: theme.palette.action.main,
          cursor: 'pointer',
          fontSize: '0.875rem',
          fontWeight: 500,
          lineHeight: '1.25rem',
          padding: '0.75rem 1.5rem',
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            backgroundColor: theme.palette.action.main,
            color: theme.palette.static.main,
          },
        }}
        to={navigateTo}
      >
        {buttonLabel}
      </Box>
    </Grid>
  )
}

export default ViewAllChip
