import { FC, ReactNode, useState } from 'react'

import { Box, Button, CircularProgress } from '@mui/material'
import SVG from 'react-inlinesvg'

// refer to figma buttons design system
const defaultStyles = {
  fontWeight: 500,
  borderRadius: '4px',
  whiteSpace: 'nowrap',
  '&:hover': {
    boxShadow: 'inset 0px 250px 0px rgba(16, 18, 19, 0.1)',
  },
  '&:disabled': {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  '&:disabled:hover': {
    opacity: 0.5,
  },
}

export const appearanceStyles = {
  'primary-soft-large': {
    default: {
      lineHeight: '1.5rem',
      color: '#FFF',
      backgroundColor: '#1068EB',
      px: '1rem',
      py: '11px',
      filter: 'drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.1))',
      border: '1px solid #1068EB',
      '&:disabled': {
        color: '#FFF',
        opacity: 0.5,
        cursor: 'not-allowed',
      },
      '&:disabled:hover': {
        opacity: 0.5,
      },
    },

    withIcon: {},
    withoutIcon: {},
  },
  'primary-soft-default': {
    default: {
      fontSize: '14px',
      color: '#FFF',
      backgroundColor: '#1068EB',
      px: '1rem',
      filter: 'drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.1))',
      border: '1px solid #1068EB',
      '&:disabled': {
        color: '#FFF',
        opacity: 0.5,
        cursor: 'not-allowed',
      },
      '&:disabled:hover': {
        opacity: 0.5,
      },
    },

    withIcon: {
      py: '7px',
    },
    withoutIcon: {
      py: '8.5px',
    },
  },
  'primary-soft-small': {
    default: {
      fontSize: '12px',
      lineHeight: '18px',
      color: '#FFF',
      backgroundColor: '#1068EB',
      px: '0.5rem',
      py: '7px',
      filter: 'drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.1))',
      border: '1px solid #1068EB',
      '&:disabled': {
        color: '#FFF',
        opacity: 0.5,
        cursor: 'not-allowed',
      },
      '&:disabled:hover': {
        opacity: 0.5,
      },
    },

    withIcon: {},
    withoutIcon: {},
  },
  'cancel-soft-default': {
    default: {
      fontSize: '14px',
      lineHeight: '1.25rem',
      color: 'secondary.main',
      backgroundColor: '#FFF',
      px: '1rem',
      py: '8.5px',
      border: '1px solid #4F575E',
    },
    withIcon: {
      py: '7px',
    },
    withoutIcon: {
      py: '8.5px',
    },
  },
  'error-soft-large': {
    default: {
      color: '#FFF',
      backgroundColor: '#DC2020',
      px: '1rem',
      py: '11px',
      border: '1px solid #DC2020',
    },
    withIcon: {},
    withoutIcon: {},
  },
  'error-soft-default': {
    default: {
      fontSize: '14px',
      lineHeight: '1.25rem',
      color: '#FFF',
      backgroundColor: '#DC2020',
      px: '1rem',
      py: '8.5px',
      border: '1px solid #DC2020',
    },
    withIcon: {
      py: '7px',
    },
    withoutIcon: {
      py: '8.5px',
    },
  },
  'clear-white-default': {
    default: {
      fontSize: '14px',
      lineHeight: '1.25rem',
      color: '#1068EB',
      backgroundColor: '#FFF',
      border: 'none',
      py: '9.5px',
      px: '14px',
      '&:hover': {
        backgroundColor: '#F5F5F5',
      },
    },
  },
}

const getButtonStyle = (
  appearance: keyof typeof appearanceStyles,
  icon?: string,
  styles?: {},
): {} => {
  const targetStyle = appearanceStyles[appearance] as {
    default: {}
    withIcon?: {}
    withoutIcon?: {}
  }

  const result = icon
    ? {
        ...defaultStyles,
        ...targetStyle.default,
        ...targetStyle.withIcon,
        ...styles,
      }
    : {
        ...defaultStyles,
        ...targetStyle.default,
        ...targetStyle.withoutIcon,
        ...styles,
      }

  return result
}

interface ButtonProps {
  onClick?: (e?: any) => void
  styles?: {}
  icon?: string
  children?: ReactNode
  appearance: keyof typeof appearanceStyles
  type?: 'button' | 'submit' | 'reset'
  isLoading?: boolean
  disabled?: boolean
}

export const CustomButton: FC<ButtonProps> = ({
  onClick,
  styles,
  children,
  appearance,
  type = 'submit',
  icon,
  isLoading,
  disabled,
}) => {
  return (
    <Button
      onClick={onClick}
      type={type}
      sx={getButtonStyle(appearance, icon, styles)}
      startIcon={icon && <SVG src={icon} />}
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      disabled={isLoading || disabled}
    >
      <Box
        sx={{
          marginRight: isLoading ? '8px' : undefined,
        }}
      >
        {children}
      </Box>
      {isLoading && <CircularProgress size={24} color="inherit" />}
    </Button>
  )
}
