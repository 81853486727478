import { FC } from 'react'

import { Box, Typography, useTheme } from '@mui/material'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import { IconUser } from 'src/assets/icons'
import {
  ImageThemeDark,
  ImageThemeDefault,
  ImageThemeLight,
} from 'src/assets/images'
import { IconWithText } from 'src/components/IconWithText'
import { RootState } from 'src/store'
import { toggleColorMode } from 'src/store/action-creators/portalActionCreators'
import customTheme from 'src/themes/themeLight'
import { Image } from 'src/ui/Image'

import { ProfileForm } from './components/ProfileForm'
import { IUser } from '../../types'
import MapSelector from '../MapSelector/MapSelector'

interface PersonalInfoProps {
  user: IUser
}

export const PersonalInfo: FC<PersonalInfoProps> = ({ user }) => {
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch()
  const theme = useTheme<typeof customTheme>()
  const changeTheme = (theme: 'dark' | 'light'): void => {
    dispatch(toggleColorMode(theme))
  }

  return (
    <Box>
      <IconWithText
        src={IconUser}
        text="Personal Info"
        styles={{
          fontSize: '22px',
          lineHeight: '27px',
          color: 'typography.main',
        }}
      />
      {/* <Box
        sx={{
          position: 'relative',
          display: 'inline-block',
          mt: '24px',
        }}
      >
        <Image
          src={ImageUser}
          styles={{
            width: '120px',
            height: '120px',
            border: '2px solid action.main',
            borderRadius: '100px',
          }}
        />
        <Box
          sx={{
            display: 'grid',
            position: 'absolute',
            bottom: '2px',
            right: '-6px',
            width: '40px',
            height: '40px',
            borderRadius: '100px',
            cursor: 'pointer',
            bgcolor: 'action.main',
            placeContent: 'center',
          }}
        >
          <SVG src={IconPencil} />
        </Box>
      </Box> */}
      <ProfileForm user={user} />
      <Box
        sx={{
          p: '16px 24px 30px',
          mt: '36px',
          bgcolor: '#F1F3F5',
          borderRadius: '8px',
        }}
      >
        <Typography
          variant="h6"
          sx={{ lineHeight: '22px', color: 'primary.main' }}
        >
          Theme
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '24px',
            mt: '24px',
          }}
        >
          <Box
            component={'button'}
            onClick={() => {
              changeTheme('light')
            }}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              height: '86px',
              background: `linear-gradient(97.22deg, 
                ${theme.palette.static.main} 9.37%, #DEE2E6 95.83%)`,
              boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
              overflow: 'hidden',
              borderRadius: '4px',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                lineHeight: '22px',
                color: '#000',
                pt: '16px',
                pl: '16px',
              }}
            >
              Light
            </Typography>
            <Image
              src={ImageThemeLight}
              styles={{ width: '135px', objectFit: 'contain' }}
            />
          </Box>
          <Box
            component={'button'}
            onClick={() => {
              changeTheme('dark')
            }}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              height: '86px',
              background: `linear-gradient(97.22deg, ${theme.typography.h5.color} 9.37%, #303D60 95.83%)`,
              boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
              overflow: 'hidden',
              borderRadius: '4px',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                lineHeight: '22px',
                color: '#FFF',
                pt: '16px',
                pl: '16px',
              }}
            >
              Dark
            </Typography>
            <Image
              src={ImageThemeDark}
              styles={{ width: '110px', objectFit: 'contain' }}
            />
          </Box>
          <Box
            component={'button'}
            onClick={() => {
              const prefersDarkMode =
                window.matchMedia &&
                window.matchMedia('(prefers-color-scheme: dark)').matches

              if (prefersDarkMode) {
                changeTheme('dark')
              } else {
                changeTheme('light')
              }
            }}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              height: '86px',
              background: `linear-gradient(97.22deg,
                 ${theme.palette.secondary.main} 9.37%, #ADB5BD 95.83%)`,
              boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
              overflow: 'hidden',
              borderRadius: '4px',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                lineHeight: '22px',
                color: '#FFF',
                pt: '16px',
                pl: '16px',
              }}
            >
              System Default
            </Typography>
            <Image
              src={ImageThemeDefault}
              styles={{ width: '125px', objectFit: 'contain' }}
            />
          </Box>
        </Box>
      </Box>
      <MapSelector />
    </Box>
  )
}
