import React, { FC, useEffect, useState } from 'react'

import CheckIcon from '@mui/icons-material/Check'
import { Box, Breadcrumbs, Typography } from '@mui/material'
import axios from 'axios'
import SVG from 'react-inlinesvg'
import { useBeforeUnload, useParams } from 'react-router-dom'

import {
  IconDashedCircle,
  IconDeviceId,
  IconLayout,
  IconMapWithPin,
  IconSitemap,
} from 'src/assets/icons'
import convertTimestampToLocal from 'src/helpers/timestamp-generator'
import { CustomButton } from 'src/ui/Button'
const handleChipColor = (lastSeen: string, status: string): string => {
  if (status === 'Online') {
    return '#37B24D'
  } else if (status === 'Idle') {
    return '#084F9B'
  } else {
    return '#6A7178'
  }
}
const MachineOverview: FC = () => {
  const { plantId, lineId, bodymakerId } = useParams()
  const [bodymaker, setBodymaker] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(true)
  const [eventSource, setEventSource] = useState<EventSource | null>(null)
  const [status, setStatus] = useState<string>('')
  const [lastSeen, setLastSeen] = useState<string>('')
  const [statusCode, setStatusCode] = useState<number>(0)
  const [statusDescription, setStatusDescription] = useState<string>('')
  const [updateVersion, setUpdateVersion] = useState<string>('')

  const [user, setUser] = useState<any>({})

  useEffect(() => {
    const userString = localStorage.getItem('user') ?? ''
    const user = JSON.parse(userString)
    setUser(user)
  }, [])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true)
      const res = await axios.get(`company/bodymaker-info/${bodymakerId}`)
      const data = await res.data
      setBodymaker(data.data)
    }

    fetchData()
      .then()
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [bodymakerId])

  useBeforeUnload(() => {
    eventSource?.close()
  })

  useEffect(() => {
    const userString = localStorage.getItem('user') ?? ''
    const user = JSON.parse(userString)
    const token = user?.token
    // /factories/:plantId/lines/:lineId/bodymakers/:bodymakerId/status
    // get cpm from an event source
    const newEventSource = new EventSource(
      `${axios.defaults.baseURL}company/factories/${plantId}/lines/${lineId}/bodymakers/${bodymakerId}/status?token=${token}`,
      { withCredentials: true },
    )

    setEventSource(newEventSource)

    return () => {
      newEventSource.close()
    }
  }, [bodymakerId, lineId, plantId])

  useEffect(() => {
    eventSource?.addEventListener('message', (event) => {
      const data = JSON.parse(event.data)

      setLastSeen(data.lastSeen)
      setStatus(data.connectionStatus)
      setStatusCode(data.statusCode)
      setStatusDescription(data.statusDescription)
      setUpdateVersion(data.updateVersion)
    })

    return () => {
      eventSource?.close()
    }
  }, [eventSource])

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        padding: '36px 48px',
        mt: '28px',
        borderRadius: '8px',
        display: 'grid',
        gap: '36px',
      }}
    >
      <StyledBox icon={IconDeviceId} title="Device ID">
        <Box
          sx={{
            backgroundColor: '#4F575E',
            borderRadius: '4px',
            padding: '8px 24px',
            width: 'fit-content',
          }}
        >
          <Typography
            sx={{
              color: '#fff',
              fontWeight: 500,
              letterSpacing: '0.24em',
              textAlign: 'center',
              textTransform: 'uppercase',
            }}
          >
            {loading ? 'Loading...' : bodymaker.id}
          </Typography>
        </Box>
      </StyledBox>

      <StyledBox icon={IconDashedCircle} title="Status">
        <Box
          sx={{
            backgroundColor: handleChipColor(lastSeen, status),
            borderRadius: '4px',
            padding: '8px 24px 8px 16px',
            width: 'fit-content',
          }}
        >
          <Typography
            sx={{
              color: '#fff',
              fontWeight: 500,
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            Last seen:{' '}
            {loading
              ? 'Loading...'
              : lastSeen
              ? convertTimestampToLocal(lastSeen)
              : 'unknown'}
            {lastSeen && <CheckIcon />}
          </Typography>
        </Box>
        <Typography
          sx={{
            color: '#4F575E',
            paddingLeft: '24px',
            mt: '16px',
          }}
        >
          Status: {loading ? 'Loading...' : status}
        </Typography>
        <Typography
          sx={{
            color: '#4F575E',
            paddingLeft: '24px',
            mt: '16px',
          }}
        >
          Status code: {loading ? 'Loading...' : statusCode ?? 'unknown'}
          {statusDescription && ' - '}
          {loading ? '' : statusDescription ?? ''}
        </Typography>
        <Typography
          sx={{
            color: '#4F575E',
            paddingLeft: '24px',
            mt: '16px',
          }}
        >
          IP Address: {loading ? 'Loading...' : bodymaker.ipAddress}
        </Typography>
      </StyledBox>
      <StyledBox
        icon={IconSitemap}
        iconStyles={{
          '& path': {
            stroke: '#1068EB',
          },
        }}
        title="Group"
      >
        {loading ? (
          <Typography>Loading...</Typography>
        ) : (
          <Breadcrumbs separator={'›'}>
            <Typography>
              {bodymaker?.line?.plant?.organization?.name}
            </Typography>
            <Typography>{bodymaker?.line?.plant?.region}</Typography>
            <Typography>{bodymaker?.line?.plant?.name}</Typography>
            <Typography>{bodymaker?.line?.name}</Typography>
            <Typography>{bodymaker?.name}</Typography>
          </Breadcrumbs>
        )}
      </StyledBox>
      <StyledBox icon={IconMapWithPin} title="Location">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '24px',
          }}
        >
          <Typography sx={{ color: '#4F575E' }}>
            {loading ? 'Loading...' : bodymaker?.line?.plant?.address}
          </Typography>
          {user &&
            (user.role === 'corporateAdmin' ||
              user.role === 'globalAdmin' ||
              user.role === 'plantSupervisor') && (
              <CustomButton appearance="primary-soft-small">
                Edit Address
              </CustomButton>
            )}
        </Box>
      </StyledBox>
      <StyledBox icon={IconLayout} title="Software updates">
        <Typography sx={{ color: '#4F575E' }}>
          Synapse Software Update{' '}
          {loading ? 'Loading...' : updateVersion ?? 'unknown'}
        </Typography>
      </StyledBox>
    </Box>
  )
}

export default MachineOverview

const StyledBox: FC<{
  children: any
  icon: string
  title: string
  iconStyles?: any
}> = ({ children, icon, title, iconStyles }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#F1F3F5',
        p: '16px 24px 24px',
        borderRadius: '8px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          mb: '16px',
        }}
      >
        <Box component={SVG} sx={iconStyles} src={icon} />
        <Typography
          sx={{
            fontWeight: 500,
            color: '#101213',
          }}
        >
          {title}
        </Typography>
      </Box>
      {children}
    </Box>
  )
}
