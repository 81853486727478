import React, { FC } from 'react'

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

interface PageHeadingProps {
  title: string
  subtitle?: string
  backButtonLink?: string
  margin?: string
  children?: React.ReactNode
}

export const PageHeading: FC<PageHeadingProps> = ({
  title,
  subtitle,
  backButtonLink,
  margin,
  children,
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          margin,
        }}
      >
        {backButtonLink && (
          <Box
            component={Link}
            to={backButtonLink}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '2.5rem',
              height: '2.5rem',
              borderRadius: '50%',
              bgcolor: 'action.main',
              marginRight: '1rem',
            }}
          >
            <ArrowBackIosNewIcon
              sx={{ color: 'access.main', fontSize: '0.75rem' }}
            />
          </Box>
        )}
        <Typography
          variant="h3"
          sx={{
            color: 'typography.main',
            fontSize: '2.25rem',
          }}
        >
          {title}
        </Typography>
        <Box sx={{ flexGrow: '1' }}></Box>
        {children}
      </Box>
      {subtitle && (
        <Typography sx={{ mt: '12px', color: 'secondary.main' }}>
          {subtitle}
        </Typography>
      )}
    </>
  )
}
