import { FC, useEffect, useState } from 'react'

import { Backdrop, Box, CircularProgress } from '@mui/material'
import axios from 'axios'
import { ClipLoader } from 'react-spinners'
import { toast } from 'react-toastify'

import TopNotification from 'src/components/TopNotification/TopNotification'
import HorizontalStepper from 'src/ui/HorizontalStepper'

import PreviousReportSelection from './components/PreviousReportSelection'
import ReportsAlert from './components/ReportsAlert'
import ReviewData from './components/ReviewData'
import SelectAsset from './components/SelectAsset'
import SelectTimeframe from './components/SelectTimeframe'
import { Link } from 'react-router-dom'

const Reports: FC = () => {
  const [hasPendingReports, setHasPendingReports] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isGeneratingReport, setIsGeneratingReport] = useState<boolean>(false)
  const [reports, setReports] = useState<any[]>([])
  const [activeStep, setActiveStep] = useState<number>(
    reports && reports?.length > 0 ? 0 : 1,
  )
  const [closing, setClosing] = useState<boolean>(false)
  const [showNotification, setShowNotification] = useState<boolean>(false)
  const [previousAssets, setPreviousAssets] = useState<any>([])
  const handleNotificationClose = (): void => {
    setClosing(true)
    setTimeout(() => {
      setShowNotification(false)
    }, 300)
  }
  const [factory, setFactory] = useState<any | any[]>()
  const [factories, setFactories] = useState<any[]>([])
  const [lines, setLines] = useState<any[]>()
  const [bodymakers, setBodymakers] = useState<any[]>()
  // const [cachedValues, setCachedValues] = useState<any>({})
  const [timeframeData, setTimeframeData] = useState<any>({})
  const [isEditingTemplate, setIsEditingTemplate] = useState<boolean>(false)
  const [selectedReportId, setSelectedReportId] = useState<number | null>(null)
  const steps = [
    'Previous reports',
    'Select Asset',
    'Select Timeframe',
    'Review Data',
    'Download',
  ]

  useEffect(() => {
    const userHasPendingReports = async (): Promise<void> => {
      setIsLoading(true)
      const response = await axios.get(
        '/reports/recurring-reports/pending-reports',
      )
      const data = await response.data.hasPendingReport

      setHasPendingReports(data)

      if (data) {
        setActiveStep(4)
      }
    }

    userHasPendingReports()
      .then(() => {})
      .catch((error) => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    if (!hasPendingReports) {
      const getReports = async (): Promise<void> => {
        setIsLoading(true)
        const response = await axios.get('/reports/recurring-reports')
        const data = await response.data.recurringReports
        setReports(data)
      }

      getReports()
        .then(() => {
          setIsLoading(false)
        })
        .catch((error) => {
          setIsLoading(false)
          console.log(error)
        })
    }
  }, [hasPendingReports])

  useEffect(() => {
    if (!hasPendingReports) {
      if (reports && reports.length > 0) {
        setActiveStep(0)
      } else {
        setActiveStep(1)
      }
    }
  }, [reports, hasPendingReports])

  const handleAssetSelection = (
    selectedFactory: any,
    selectedLines: any,
    selectedBodymakers: any,
    moveSteps: boolean = true,
    // factories: any,
    // lines: any,
    // bodymakers: any,
  ): void => {
    if (moveSteps) {
      console.log('hmm')

      setActiveStep(2)
    }

    selectedFactory.isSelected = true

    setPreviousAssets({
      factories: { ...selectedFactory, isSelected: true },
      lines: selectedLines.map((item: any) => ({
        ...item,
        isSelected: true,
      })),
      bodymakers: selectedBodymakers.map((item: any) => ({
        ...item,
        isSelected: true,
      })),
    })
    setFactory(selectedFactory)

    setLines(
      selectedLines.map((item: any) => ({
        ...item,
        isSelected: true,
      })),
    )
    setBodymakers(
      selectedBodymakers.map((item: any) => ({
        ...item,
        isSelected: true,
      })),
    )
  }

  useEffect(() => {
    const getFactories = async (): Promise<void> => {
      const response = await axios.get('/data/factories')
      const data = await response.data.factories

      setFactories(data)
      console.log(data)
    }

    getFactories()
      .then(() => {})
      .catch((error) => {
        console.log(error)
      })
  }, [])
  const handleSelectAllAssets = (
    selectedFactories: any,
    selectedLines: any,
    selectedBodymakers: any,
  ): void => {
    // console.log(
    //   selectedFactories,
    //   ...selectedLines,
    //   selectedBodymakers,
    //   'all',
    // )
    // const bodymakers = selectedBodymakers

    setActiveStep(2)
    setPreviousAssets(undefined)

    setFactory(selectedFactories)

    setLines(
      selectedLines.flat().map((item: any) => ({
        ...item,
        isSelected: true,
      })),
    )
    setBodymakers(
      selectedBodymakers.flat(Infinity).map((item: any) => {
        return {
          ...item,
          isSelected: true,
        }
      }),
    )
  }

  const handleTimeframeSelection = (
    type: 'recurring' | 'onDemand',
    reportTimezone: any,
    // reportTimestampFormat: any,
    dates?: any,
    // timeframe: any,
    reportFrequency?: any,
    reportTimezoneString?: string,
    reportTime?: any,
    moveSteps: boolean = true,
  ): void => {
    console.log(
      reportTimezone,
      // reportTimestampFormat,
      dates,
      // timeframe,
      reportFrequency,
      reportTimezoneString,
      reportTime,
    )

    let selectedDates: any

    if (type === 'recurring') {
      switch (reportFrequency) {
        case 'daily':
          selectedDates = {
            startDate: new Date(),
            endDate: new Date(),
          }

          console.log(selectedDates, 'daily')

          break

        case 'weekly':
          selectedDates = {
            startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
            endDate: new Date(),
          }

          break
        case 'biweekly':
          selectedDates = {
            startDate: new Date(new Date().setDate(new Date().getDate() - 14)),
            endDate: new Date(),
          }

          break
        case 'monthly':
          selectedDates = {
            startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
            endDate: new Date(),
          }

          break
        default:
          selectedDates = {
            startDate: new Date(),
            endDate: new Date(),
          }
      }
    } else {
      selectedDates = dates
      reportTime = undefined
      reportFrequency = undefined
    }

    setTimeframeData({
      type,
      reportTimezone,
      // reportTimestampFormat,
      dates: selectedDates, // timeframe,
      reportFrequency,
      reportTimezoneString,
      reportTime,
    })

    if (moveSteps) {
      setActiveStep(3)
      setShowNotification(true)
    }
  }

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 94px)',
          width: '100%',
        }}
      >
        <ClipLoader color="#1068EB" size={56} />
      </Box>
    )
  }

  return (
    <>
      {showNotification && (
        <TopNotification>
          <ReportsAlert closing={closing} onClick={handleNotificationClose} />
        </TopNotification>
      )}
      <Box
        sx={{
          mt: '56px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '422px',
            mb: '48px',
          }}
        >
          <HorizontalStepper steps={steps} activeStep={activeStep} />
        </Box>
        {activeStep === 0 && reports.length > 0 && (
          <PreviousReportSelection
            reports={reports}
            onDeleteReport={(reportId: number) => {
              setReports((prev: any) => {
                return prev.filter((report: any) => report.id !== reportId)
              })
            }}
            onSkipPress={() => {
              setActiveStep(1)
              setIsEditingTemplate(false)
              setSelectedReportId(null)
            }}
            onTemplatePress={(templateId: number) => {
              setActiveStep(1)
              setIsEditingTemplate(true)
              const selectedReport = reports.find(
                (report) => report.id === templateId,
              )

              setSelectedReportId(selectedReport.id)

              if (selectedReport) {
                const {
                  reportFrequency,
                  reportType,
                  reportTime,
                  reportTimezone,
                } = selectedReport
                // const reportTimestampFormat =
                //   selectedReport.reportTimestampFormat
                const type = reportType.toLowerCase()

                // const timeframe = 'Last 24 hours'
                handleTimeframeSelection(
                  type as 'recurring' | 'onDemand',
                  reportTimezone,
                  // reportTimestampFormat,
                  // timeframe,
                  undefined,
                  reportFrequency,
                  undefined,
                  reportTime,
                  false,
                )
                if (selectedReport.plants.length > 1) {
                  handleSelectAllAssets(
                    selectedReport.plants,
                    selectedReport.lines,
                    selectedReport.bodymakers,
                  )
                } else {
                  handleAssetSelection(
                    selectedReport.plants[0],
                    selectedReport.lines,
                    selectedReport.bodymakers,
                    false,
                  )
                }
              }
            }}
          />
        )}
        {activeStep === 1 && (
          <SelectAsset
            onBackPress={
              reports && reports.length > 0
                ? () => {
                    setActiveStep(0)
                    setPreviousAssets(undefined)
                    setTimeframeData({})
                  }
                : undefined
            }
            preSelectedAssets={previousAssets}
            reportFactories={factories}
            factory={factory}
            lines={lines}
            bodymakers={bodymakers}
            selectAllAssets={handleSelectAllAssets}
            // cachedValues={cachedValues}
            onStepChange={handleAssetSelection}
          />
        )}
        {activeStep === 2 && (
          <SelectTimeframe
            isEditingTemplate={isEditingTemplate}
            onNextPress={handleTimeframeSelection}
            timeframeData={timeframeData}
            onBackPress={() => {
              setActiveStep(1)
              if (factories.every((item: any) => item.isSelected)) {
                setFactories((prev: any) => {
                  return prev.map((item: any) => {
                    return { ...item, isSelected: false }
                  })
                })
                setLines((prev: any) => {
                  return prev.map((item: any) => {
                    return { ...item, isSelected: false }
                  })
                })
                setBodymakers((prev: any) => {
                  return prev.map((item: any) => {
                    return { ...item, isSelected: false }
                  })
                })
              }
            }}
          />
        )}

        {activeStep === 3 && (
          <ReviewData
            bodymakers={bodymakers}
            lines={lines}
            selectedDates={timeframeData.dates}
            onBackPress={() => {
              setActiveStep(2)
              setShowNotification(false)
            }}
            onGenerateReport={async (reportData: any) => {
              setIsGeneratingReport(true)
              let report: any

              if (isEditingTemplate) {
                report = {
                  factories: factory.length > 1 ? factory : [factory],
                  ...reportData,
                  reportTimezone: timeframeData.reportTimezone,
                  reportFrequency: timeframeData.reportFrequency,
                  reportTime: timeframeData.reportTime,
                  reportTimezoneString: timeframeData.reportTimezoneString,
                }
              } else {
                report = {
                  // factories: factory.length > 1 ? factory : [factory],
                  factories: [factory],
                  ...reportData,
                  ...timeframeData,
                  reportStartDate:
                    timeframeData.type === 'recurring'
                      ? undefined
                      : timeframeData.dates.startDate,
                  reportEndDate:
                    timeframeData.type === 'recurring'
                      ? undefined
                      : timeframeData.dates.endDate,
                  dates: undefined,
                  reportType: timeframeData.type,
                  type: undefined,
                  reportTimezoneString: timeframeData.reportTimezoneString,
                }
              }

              try {
                if (isEditingTemplate) {
                  const response = await axios.patch(
                    `/reports/recurring-reports/${selectedReportId}`,
                    report,
                  )

                  if (response.status === 200) {
                    toast.success(
                      'Report updated successfully, regenerating... 🚀',
                    )
                  }
                } else {
                  const response = await axios.post(
                    '/reports/recurring-reports',
                    report,
                  )
                  if (response.status === 201) {
                    toast.success('Your report is being generated... 🚀')
                  }
                }
              } catch (error) {
              } finally {
                setIsGeneratingReport(false)
              }

              setActiveStep(4)
            }}
          />
        )}

        {activeStep === 4 && (
          <div>
            Your report is being generated, feel free to continue browsing and
            we will email you when it&apos;s done!{' '}
            <span
              onClick={() => {
                setActiveStep(1)
                setIsEditingTemplate(false)
                setSelectedReportId(null)
                setPreviousAssets(undefined)
              }}
              style={{
                color: '#1068EB',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              generate another report
            </span>
          </div>
        )}
      </Box>

      {isGeneratingReport && (
       <Backdrop
       open={true}
       sx={{ zIndex: 9999, background: 'transparent' }}
     ></Backdrop>
      )}
    </>
  )
}

export default Reports
