import { forwardRef, useEffect, useState } from 'react'

import { Input as MUIInput, TextField } from '@mui/material'

import customTheme from 'src/themes/themeLight'

const defaultStyles = {
  width: '100%',
  fontSize: '14px',
  border: '1px solid rgba(0,0,0,0.23)',
  borderRadius: '4px',
  outlineWidth: '0px',
  p: 0,
  '&:focus-visible': {
    outlineWidth: '0px',
  },
  '&:disabled': {
    backgroundColor: '#F2F2F2',
  },
  '&:before': {
    display: 'none',
  },
  '&:after': {
    display: 'none',
  },
  '& input:not(:disabled)::placeholder': {
    color: customTheme.palette.secondary.main,
    opacity: 1,
  },
  '& input:disabled::placeholder': {
    color: 'rgba(0,0,0,0.87)!important',
    WebkitTextFillColor: 'initial!important;',
  },
  '& input:disabled': {
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'not-allowed',
  },
  '& input:not(:disabled)': {
    color: customTheme.palette.secondary.main,
    WebkitTextFillColor: 'initial!important;',
  },
}

export const Input = forwardRef<HTMLInputElement, any>(
  ({ styles, ...props }, ref) => {
    const [value, setValue] = useState(props.value || '')

    useEffect(() => {
      setValue(props.value ?? '')
    }, [props.value])

    if (props.label) {
      return (
        <TextField
          ref={ref}
          {...props}
          autoComplete="off"
          variant="outlined"
          sx={{
            ...styles,
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            '& .MuiInputLabel-shrink:not(.Mui-disabled)': {
              color: props.error
                ? customTheme.palette.error.main
                : customTheme.palette.action.main,
            },
            '& .Mui-focused:not(.Mui-disabled) fieldset': {
              borderColor: props.error
                ? customTheme.palette.error.main
                : customTheme.palette.action.main + ' !important',
            },
          }}
        />
      )
    }

    return (
      <MUIInput
        // helperText={props.helperText}
        ref={ref}
        {...props}
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
          props.onChange(e)
        }}
        autoComplete="off"
        sx={{
          ...defaultStyles,
          ...styles,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        }}
      />
    )
  },
)
