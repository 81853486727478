import React, { FC, useEffect, useState } from 'react'

import { Box, Typography } from '@mui/material'

import { generateUUID } from 'src/helpers/generateUUID'

import SelectAssetButton from './SelectAssetButton'
interface SelectionCardProps {
  title: string
  isActive: boolean
  type: 'factory' | 'line' | 'bodymaker'
  data: any[]
  onSelect?: (id: number) => void
}

const RadioSelectionCard: FC<SelectionCardProps> = ({
  title,
  isActive,
  data,
  type,
  onSelect,
}) => {
  console.log(data[0]?.isSelected, 'data.isSelected')

  const [cardData, setCardData] = useState<any[]>(data)
  console.log('cardData', cardData)

  useEffect(() => {
    if (type === 'line') {
      setCardData((prev) => {
        return data.map((item, index) => {
          if (item.id === prev[index]?.id && prev[index]?.isSelected) {
            return {
              ...item,
              isSelected: true,
            }
          }
          return { ...item, isSelected: false }
        })
      })
    } else {
      setCardData(data)
    }
  }, [data, type])

  // useEffect(() => {}, [data])

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: isActive ? '#12558E' : '#303D60',
          p: '12px 16px',
          borderRadius: '4px 4px 0px 0px',
        }}
      >
        <Typography
          sx={{
            fontSize: '22px',
            fontWeight: 500,
            color: '#fff',
          }}
        >
          {title}
        </Typography>
      </Box>

      {cardData.map((item: any, index) => (
        <SelectAssetButton
          key={generateUUID()}
          item={item}
          type={type}
          isLast={index === data.length - 1}
          // isHidden={item.isHidden}
          onClick={(id: number) => {
            if (onSelect) {
              onSelect(id)
            }
            setCardData((prev) =>
              prev.map((item) => {
                if (item.id === id && !item.isSelected) {
                  return {
                    ...item,
                    isSelected: true,
                  }
                }
                return {
                  ...item,
                  isSelected: false,
                }
              }),
            )
          }}
        />
      ))}
    </Box>
  )
}

export default RadioSelectionCard
