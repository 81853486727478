import { FC, useEffect, useState } from 'react'

import { Backdrop, CircularProgress, Box } from '@mui/material'

import { IconMobileMessage } from 'src/assets/icons'

import { CollapsibleContainer } from './components/CollapsibleContainer'
import { MessagesSettings } from './components/MessagesSettings'
import axios from 'axios'
import { toast } from 'react-toastify'

export const AccountNotifications: FC = () => {
  const handlePhoneUpdate = async (phoneNumber: string) => {
    try {
      setIsLoading(true)
      if (
        !phoneNumber.match(
          /^(?:\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$|^[0-9]{10}$/,
        )
      ) {
        toast.error('Please enter a valid phone number')
        return
      }

      const res = await axios.patch('/user/update-sms-status', {
        phone: phoneNumber,
        smsStatus: true,
      })

      setIsLoading(false)
      if (res.data.user) {
        setUser(res.data)
        localStorage.setItem(
          'user',
          JSON.stringify({ ...res.data.user, token: user.token }),
        )
        toast.success('Phone number updated successfully')
      }
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }

  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user') ?? '{}'),
  )

  const handlePhoneToggle = async (isOn: boolean) => {
    // try {
    //   console.log(isOn)
    //   setIsLoading(true)
    //   const res = await axios.patch('/user/update-sms-status', {
    //     phone: user.phone,
    //     smsStatus: isOn,
    //   })
    //   if (res.data.user) {
    //     setUser(res.data)
    //     localStorage.setItem(
    //       'user',
    //       JSON.stringify({ ...res.data.user, token: user.token }),
    //     )
    //   }
    //   toast.success(`SMS notifications are now ${isOn ? 'on' : 'off'}`)
    //   setIsLoading(false)
    // } catch (err) {
    //   setIsLoading(false)
    //   console.log(err)
    // }
  }
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user') ?? '{}'))
  }, [])
  const [isLoading, setIsLoading] = useState(false)
  return (
    <>
      <CollapsibleContainer
        icon={IconMobileMessage}
        title="Messages"
        styles={{
          mt: '16px',
        }}
        onToggle={handlePhoneToggle}
        state={user.phoneNotifications}
      >
        <MessagesSettings
          onPhoneUpdate={handlePhoneUpdate}
          phoneNumber={user.phone}
        />
      </CollapsibleContainer>

      {isLoading && (
        <Backdrop
          open={true}
          sx={{ zIndex: 9999, background: 'transparent' }}
        ></Backdrop>
      )}
      {/* <CollapsibleContainer icon={IconMailbox} title="E-mails">
        <EmailsSettings />
      </CollapsibleContainer>
      <CollapsibleContainer
        icon={IconSchoolBell}
        title="Level of notification and alerts"
      >
        <LevelSettings />
      </CollapsibleContainer>
      <CollapsibleContainer icon={IconReportSearch} title="Assets">
        <AssetsSettings />
      </CollapsibleContainer> */}
    </>
  )
}
