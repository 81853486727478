import React, { FC, useEffect, useState } from 'react'

import { Box, Typography, debounce } from '@mui/material'
import axios from 'axios'
import SVG from 'react-inlinesvg'

import { IconBuildingFactory } from 'src/assets/icons'
import { Search } from 'src/components/Search'
import { ITableColumns, Table } from 'src/components/Table'
import { ITablePaginationProps } from 'src/components/Table/types'
import convertTimestampToLocal from 'src/helpers/timestamp-generator'
import { CustomButton } from 'src/ui/Button'
import PieChart from 'src/ui/PieChart/PieChart'

interface FactoryOverviewProps {
  data?: any
}

// const mockTableData: IFactoryOverviewData[] = [
//   {
//     id: 1,
//     name: 'North America overview Asset',
//     state: 'Tampa',
//     cumulativeRate: '2345 CPM',
//     averageRate: '0.0846',
//     timestamp: '3:15:47 AM CEST 10/12/2022',
//   },
//   {
//     id: 2,
//     name: 'North America overview Asset',
//     state: 'Texas',
//     cumulativeRate: '2345 CPM',
//     averageRate: '0.0846',
//     timestamp: '3:15:47 AM CEST 10/12/2022',
//   },
//   {
//     id: 3,
//     name: 'North America overview Asset',
//     state: 'Kansas',
//     cumulativeRate: '2345 CPM',
//     averageRate: '0.0846',
//     timestamp: '3:15:47 AM CEST 10/12/2022',
//   },
//   {
//     id: 4,
//     name: 'North America overview Asset',
//     state: 'North Dakota',
//     cumulativeRate: '2345 CPM',
//     averageRate: '0.0846',
//     timestamp: '3:15:47 AM CEST 10/12/2022',
//   },
//   {
//     id: 5,
//     name: 'North America overview Asset',
//     state: 'North Carolina',
//     cumulativeRate: '2345 CPM',
//     averageRate: '0.0846',
//     timestamp: '3:15:47 AM CEST 10/12/2022',
//   },
// ]

const columns: ITableColumns[] = [
  {
    accessor: 'name',
    header: 'Plants',
    width: 0,
    isSortable: true,
  },
  // {
  //   accessor: 'modifiedCumulativeRate',
  //   header: 'Production Rate Cumulative',
  //   width: 250,
  //   isSortable: true,
  // },
  // {
  //   accessor: 'modifiedAverageRate',
  //   header: 'Production Rate Average',
  //   width: 0,
  //   isSortable: true,
  // },
  {
    accessor: 'synapseStatus',
    header: 'Synapse Status',
    width: 0,
    isSortable: true,
  },
  {
    accessor: 'lastUpdated',
    header: 'Timestamp',
    width: 0,
    isSortable: true,
  },
]

export const FactoryOverview: FC<FactoryOverviewProps> = (props) => {
  const [data, setData] = useState<any>()
  const [regions, setRegions] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(true)
  // const [thresholds, setThresholds] = useState<any>()
  // add a search column as well defaults to the name column
  const [pagination, setPagination] = useState<ITablePaginationProps>({
    page: 1,
    totalPages: 1,
    limit: 5,
    totalItems: 1,
    searchQuery: '',
    sort: {
      field: 'name',
      order: 'asc',
    },
    regions: [],
    onPageChange: (page, limit) => {
      setPagination((prevState) => ({
        ...prevState,
        page,
        limit,
      }))
    },
  })

  const handleSorting = (column: string, dir: any): void => {
    setPagination((prevState) => {
      return {
        ...prevState,
        page: 1,
        sort: {
          field: column,
          order: dir,
        },
      }
    })

    // console.log(pagination.sort.order === 'asc')

    // if (column === pagination.sort.field) {

    // }

    // setPagination((prevState) => ({
    //   ...prevState,
    //   sort: {
    //     field: column,
    //     order:
    //       prevState.sort.field === column
    //         ? prevState.sort.order === 'asc'
    //           ? 'desc'
    //           : 'asc'
    //         : 'asc',
    //   },
    // }))
  }

  const handleSearchDebounced = debounce((value) => {
    setPagination((prevState) => ({
      ...prevState,
      searchQuery: value,
      // change this later if needed to be dynamic
      searchField: 'name',
      page: 1,
    }))
  }, 500)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setIsLoading(true)
      const { data } = await axios.get<any, any>('/company/factories', {
        params: {
          ...pagination,
          sort: undefined,
          sortColumn: pagination.sort.field,
          sortOrder: pagination.sort.order,
          onPageChange: undefined,
          region: pagination.regions,
          regions: undefined,
        },
      })

      setData({
        status: data.synapsesStatusThresholds,
        data: data.items,
      })

      setRegions(data.regions)

      setPagination((prevState) => ({
        ...prevState,
        totalPages: data.totalPages,
        totalItems: data.totalItems,
      }))
    }

    fetchData()
      .then(() => {
        setData((state: any) => {
          const prevState = state.data
          console.log(state)

          const temp = prevState.map((item: any) => {
            return {
              ...item,
              name: (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      '& path': { stroke: '#29823B' },
                    }}
                  >
                    <SVG
                      src={IconBuildingFactory}
                      style={{
                        minWidth: 'fit-content',
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        ml: '8px',
                        color: '#000',
                        fontWeight: 600,
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: '12px',
                      mt: '12px',
                      color: 'secondary.main',
                    }}
                  >
                    {item.region}
                  </Typography>
                </>
              ),
              // modifiedCumulativeRate: (
              //   <Box
              //     sx={{
              //       p: '5.5px 16px',
              //       display: 'inline-block',
              //       borderRadius: '100px',
              //       backgroundColor: '#EBF7ED',
              //       color: '#267D36',
              //       fontWeight: 500,
              //     }}
              //   >
              //     {item.cumulativeRate}
              //   </Box>
              // ),
              // modifiedAverageRate: <Box>{item.avgProductionRate}</Box>,
              synapseStatus: (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <PieChart
                    props={{
                      data: [
                        {
                          label: 'normal',
                          value: item.synapseStatus.normal,
                        },
                        {
                          label: 'warning',
                          value: item.synapseStatus.warning,
                        },
                        {
                          label: 'fault',
                          value: item.synapseStatus.fault,
                        },
                        {
                          label: 'failure',
                          value: item.synapseStatus.failure,
                        },
                        {
                          label: 'unknown',
                          value: item.synapseStatus.unknown,
                        },
                      ],
                      thresholds: {
                        normal: {
                          value: state.status.synapseNormal,
                          color: '#37B24D',
                        },
                        warning: {
                          value: state.status.synapseWarning,
                          color: '#E9A100',
                        },
                        fault: {
                          value: state.status.synapseFault,
                          color: '#b95000',
                        },
                        failure: {
                          value: state.status.synapseFailure,
                          color: '#C92A2A',
                        },
                        unknown: {
                          value: state.status.synapseUnknown,
                          color: '#6A7178',
                        },
                      },
                      innerRadius: 10,
                      outerRadius: 20,
                    }}
                  />

                  <Box
                    sx={{
                      ml: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '12px',
                      fontWeight: 600,
                    }}
                  >
                    <Box
                      component={'span'}
                      sx={{ color: '#37B24D', ml: '12px', mr: '4px' }}
                    >
                      Normal
                    </Box>
                    = {item.synapseStatus.normal}
                    <Box
                      component={'span'}
                      sx={{ color: '#E9A100', ml: '12px', mr: '4px' }}
                    >
                      Warning
                    </Box>
                    = {item.synapseStatus.warning}
                    <Box
                      component={'span'}
                      sx={{ color: '#C92A2A', ml: '12px', mr: '4px' }}
                    >
                      Fault
                    </Box>
                    = {item.synapseStatus.fault}
                  </Box>
                </Box>
              ),
              lastUpdated: (
                <Box
                  sx={{
                    p: '4px',
                    display: 'inline-block',
                    borderRadius: '4px',
                    backgroundColor: 'secondary.main',
                    color: '#F8F9FA',
                    whiteSpace: 'nowrap',
                    lineHeight: '18px',
                    fontSize: '12px',
                  }}
                >
                  {convertTimestampToLocal(item.updatedAt)}
                </Box>
              ),
              // actions: (
              //   <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              //     <CustomButton
              //       appearance="primary-soft-small"
              //       styles={{ py: '12px !important' }}
              //     >
              //       Edit
              //     </CustomButton>
              //   </Box>
              // ),
            }
          })
          return temp
        })
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))
  }, [
    pagination.page,
    pagination.limit,
    pagination.searchQuery,
    pagination.sort,
    pagination.regions,
  ])

  const filters =
    regions &&
    regions.map((region) => {
      return {
        label: region,
        value: region,
      }
    })

  const onFilterChange = (value: string[]): void => {
    setPagination((prevState) => ({
      ...prevState,
      regions: value,
      page: 1,
    }))
  }

  return (
    <Box>
      <Typography
        variant="h4"
        sx={{ mt: '48px', mb: '24px', color: '#101213' }}
      >
        Plant Overview
      </Typography>

      {filters && (
        <Search
          searchStyles={{
            width: '364px',
            padding: '0.3rem 3rem 0.3rem 0',
          }}
          selectLabel={{ name: 'Region', id: 'region' }}
          styles={{ width: 'auto' }}
          onChange={(e) => handleSearchDebounced(e.target.value)}
          hasFilters={true}
          onFilterChange={(e: any) => {
            onFilterChange(e)
          }}
          options={filters}
          isMultiFilter={true}
          selectStyles={{
            backgroundColor: '#F5F7F9',
            borderRadius: '4px',
          }}
        />
      )}
      <Table
        data={data}
        loading={isLoading}
        columns={columns}
        // sortDirection={pagination.sort.order}
        handleSorting={handleSorting}
        containerStyles={{
          mt: '48px',
        }}
        tdStyles={{
          p: '16px',
        }}
        pagination={pagination}
      />
    </Box>
  )
}
