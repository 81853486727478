import { FC } from 'react'

import { Box } from '@mui/material'

import { PageHeading } from 'src/components/PageHeading'
import { UserManagement } from 'src/modules/UserManagement'
import UserManagementComp from 'src/modules/UserManagement/UserManagementComp'

export const UserManagementPage: FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        m: '48px 0 224px 0',
      }}
    >
      <PageHeading title="User Management" />
      <UserManagementComp />
      {/* <UserManagement /> */}
    </Box>
  )
}
