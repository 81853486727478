import React, { FC, useEffect, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  Box,
  DialogTitle,
  IconButton,
  DialogContent,
} from '@mui/material'

interface BaseModalProps {
  show: boolean
  onClose: () => void
  title: string
  children: React.ReactNode
  fullWidth?: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  titleStyles?: any
  contentStyles?: any
}

const BaseModal: FC<BaseModalProps> = ({
  show,
  onClose,
  title,
  children,
  fullWidth,
  maxWidth,
  titleStyles,
  contentStyles,
}) => {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(show)
  }, [show])

  const handleClose = (): void => {
    setOpen(false)
    onClose()
  }
  return (
    <Dialog
      scroll="paper"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <DialogTitle
          sx={{
            fontWeight: 'bold',
            color: '#000',
            px: '36px',
            ...titleStyles,
          }}
        >
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            px: '36px',
            pb: 0,
            ...contentStyles,
          }}
        >
          {children}
        </DialogContent>
      </Box>
    </Dialog>
  )
}

export default BaseModal
