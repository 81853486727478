import { Box, Typography } from '@mui/material'
import { Table } from 'src/components/Table'
import SVG from 'react-inlinesvg'
import { Icon3DCube, IconBuildingFactory } from 'src/assets/icons'
import { PageHeading } from 'src/components/PageHeading'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { formatDateShort } from 'src/helpers/timestamp-generator'

const columns = [
  {
    accessor: 'orgName',
    header: 'Organization Name',
    width: 0,
    isSortable: true,
    Cell: ({ value }: any) => {
      return (
        <span
          style={{
            fontWeight: 600,
          }}
        >
          {value}
        </span>
      )
    },
  },
  {
    accessor: 'region',
    header: 'Region',
    width: 0,
    isSortable: true,
  },
  {
    accessor: 'plantName',
    header: 'Plant name',
    width: 0,
    isSortable: true,

    Cell: ({ value }: any) => {
      console.log(value, 'plant name')

      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            component={SVG}
            sx={{
              '& path': {
                stroke: 'rgb(106, 113, 120)',
                fill: 'rgb(106, 113, 120)',
              },
            }}
            src={IconBuildingFactory}
          />
          <Typography
            sx={{
              ml: 1,
            }}
          >
            {value}
          </Typography>
        </Box>
      )
    },
  },
  {
    accessor: 'bodymaker',
    header: 'Bodymaker',
    width: 0,
    isSortable: true,

    Cell: ({ value }: any) => {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <SVG src={Icon3DCube} />
          <Typography
            sx={{
              ml: 1,
            }}
          >
            {value.name}
          </Typography>
        </Box>
      )
    },
  },
  {
    accessor: 'startDate',
    header: 'Start Date',
    width: 0,
    isSortable: true,
  },
  {
    accessor: 'renewalDate',
    header: 'Renewal Date',
    width: 0,
    isSortable: true,
  },
]

export const Billing = () => {
  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const fetchData = async () => {
      const dummyPlantNames = ['PlantA', 'PlantB', 'PlantC', 'PlantD', 'PlantE']

      const dummyOrgNames = ['OrgX', 'OrgY', 'OrgZ', 'OrgW', 'OrgV']

      try {
        setLoading(true)
        const response = await axios.get('company/billing')

        let plantNameIndex = 0
        let orgNameIndex = 0

        setData(
          response.data.map((item: any) => {
            const plantName = dummyPlantNames[plantNameIndex]
            plantNameIndex = (plantNameIndex + 1) % dummyPlantNames.length

            const orgName = dummyOrgNames[orgNameIndex]
            orgNameIndex = (orgNameIndex + 1) % dummyOrgNames.length

            return {
              ...item,
              bodymaker: item.bodymaker,
              plantName,
              orgName,
              region: item.plant.region,
              startDate: formatDateShort(item.startDate),
              renewalDate: formatDateShort(item.renewalDate),
            }
          }),
        )
        setLoading(false)
      } catch (err) {
        console.log(err)
      }

      setLoading(false)
    }

    void fetchData()
  }, [])

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          pt: 6,
          mb: 4,
        }}
      >
        <PageHeading title="Billing Details" />
      </Box>
      {!loading && data?.length > 0 && (
        <Table
          containerStyles={{
            w: '100%',
          }}
          columns={columns}
          data={data}
          loading={loading}
        />
      )}
      {!loading && !data.length && (
        <Box>
          <Typography>No available billing data.</Typography>
        </Box>
      )}
    </Box>
  )
}
