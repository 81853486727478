import React, { FC, useEffect, useState } from 'react'

import { Box, Typography } from '@mui/material'
import SVG from 'react-inlinesvg'

import { IconTrashcan } from 'src/assets/icons'
import { PageHeading } from 'src/components/PageHeading'
import { ITableColumns, Table } from 'src/components/Table'
import { formatDateShort } from 'src/helpers/timestamp-generator'
import { CustomButton } from 'src/ui/Button'
import axios from 'axios'
import { GridLoader } from 'react-spinners'

const columns: ITableColumns[] = [
  {
    accessor: 'secretKey',
    header: 'Secret Key',
    width: 0,
  },
  {
    accessor: 'created',
    header: 'Created',
    width: 0,
  },
  {
    accessor: 'status',
    header: 'Status',
    width: 0,
  },
  {
    accessor: 'actions',
    header: '',
    width: 0,
  },
]
const ApiKeys: FC = () => {
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)
  const deleteToken = async (id: number) => {
    setLoading(true)
    try {
      const response = await axios.delete(`/admin/api-keys/delete/${id}`)
      const data = await response.data.apiToken
      console.log(data)

      setData((prev: any) => {
        const newData = prev.filter((item: any) => item.id !== id)
        console.log(newData)
        console.log([
          ...newData,
          {
            id: data.id,
            secretKey: data.token,
            created: formatDateShort(data.createdAt),
            status: data.deletedAt === null ? 'Active' : 'Deleted',
          },
        ])

        return [
          ...newData,
          {
            secretKey: data.token,
            created: formatDateShort(data.createdAt),
            status: data.deletedAt === null ? 'Active' : 'Deleted',
          },
        ]
      })
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    const fetchTokens = async () => {
      setLoading(true)
      const response = await axios.get('/admin/api-keys')
      const data = await response.data
      console.log(data)

      setData(
        data.map((item: any) => {
          return {
            ...item,
            secretKey: item.token,
            created: formatDateShort(item.createdAt),
            status: item.deletedAt === null ? 'Active' : 'Deleted',
            actions:
              item.deletedAt === null ? (
                <Box
                  component={'button'}
                  sx={{
                    cursor: 'pointer',
                    backgroundColor: 'transparent',
                  }}
                  onClick={() => deleteToken(item.id)}
                >
                  <SVG src={IconTrashcan} />
                </Box>
              ) : null,
          }
        }),
      )
    }

    fetchTokens()
      .then(() => setLoading(false))
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }, [])
  const createToken = async () => {
    setLoading(true)
    try {
      const tokenReq = await axios.get('/admin/api-keys/create')
      const token = await tokenReq.data
      setData([
        ...data,
        {
          ...token,
          secretKey: token.token,
          created: formatDateShort(token.createdAt),
          status: token.deletedAt === null ? 'Active' : 'Deleted',
          actions: (
            <Box
              component={'button'}
              sx={{
                cursor: 'pointer',
                backgroundColor: 'transparent',
              }}
              onClick={() => deleteToken(token.id)}
            >
              <SVG src={IconTrashcan} />
            </Box>
          ),
        },
      ])
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  return (
    <Box>
      <Box
        sx={{
          mt: '48px',
          mb: '24px',
        }}
      >
        <PageHeading title="Api Keys" />
      </Box>
      <Typography
        sx={{
          mb: '24px',
          maxWidth: '1180px',
          width: '100%',
          fontSize: '21px',
          color: '#4F575E',
        }}
      >
        Your secret API keys are listed below. Please note that we do not
        display your secret API keys again after you generate them.
      </Typography>
      <Typography
        sx={{
          mb: '48px',
          maxWidth: '1180px',
          width: '100%',
          fontSize: '21px',
          color: '#4F575E',
        }}
      >
        Do not share your API key with others, or expose it in the browser or
        other client-side code. In order to protect the security of your
        account, OpenAI may also automatically rotate any API key that
        we&apos;ve found has leaked publicly.
      </Typography>
      <Box>
        <CustomButton
          styles={{
            mb: '24px',
          }}
          appearance="primary-soft-default"
          onClick={createToken}
          disabled={loading}
        >
          Create New Secret Key
        </CustomButton>
        {!loading && data && data?.length > 0 && (
          <Table columns={columns} data={data} loading={false} />
        )}
        {!loading && data && !data?.length && (
          <Typography>
            You have no API keys. Create a new one to get started.
          </Typography>
        )}

        {loading && (
          <Box textAlign="center">
            <GridLoader color="#106eeb" />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ApiKeys
