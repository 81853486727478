import { FC } from 'react'
import { useSelector } from 'react-redux'

import { PersonalInfo } from './components/PersonalInfo'
import { Privacy } from './components/Privacy'

export const AccountProfile: FC = () => {
  const user = useSelector((state: any) =>
    state.authReducer.success !== undefined
      ? state.authReducer.user.user
      : state.authReducer.user,
  )

  return (
    <>
      <PersonalInfo user={user.user ?? user} />
      <Privacy />
    </>
  )
}
