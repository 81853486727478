import { FC } from 'react'

import { Box, Typography } from '@mui/material'
import SVG from 'react-inlinesvg'

interface MachineCardToolTipProps {
  title: string
  value: string
  icon: string
  top: string
  left: string
  status?: 'normal' | 'warning' | 'error'
}

const MachineCardToolTip: FC<MachineCardToolTipProps> = ({
  title,
  value,
  icon,
  top,
  left,
  status,
}) => {
  const statusColor =
    status === 'normal'
      ? 'rgba(41, 130, 59, 0.75)'
      : status === 'warning'
      ? 'rgba(233, 161, 0, 0.75)'
      : '#d0021b'
  return (
    <Box
      sx={{
        display: 'flex',
        padding: '5px 6px',
        backgroundColor: statusColor,
        width: 'fit-content',
        boxShadow: '0px 8px 10px 0px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        position: 'absolute',
        whiteSpace: 'nowrap',
        top,
        left,
      }}
    >
      <Box
        sx={{
          marginRight: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <SVG src={icon} />
      </Box>
      <Box>
        <Typography sx={{ color: '#fff', fontSize: '10px', fontWeight: 400 }}>
          {title}
        </Typography>
        <Typography sx={{ color: '#fff', fontSize: '16px', fontWeight: 700 }}>
          {value}
        </Typography>
      </Box>
    </Box>
  )
}
export default MachineCardToolTip
