import { useMemo } from 'react'

import * as d3 from 'd3'

import { ChartData } from 'src/modules/MachineView/components/MachineChart'
import { Margins } from 'src/ui/MultiLineChart/AnimatedLineChart'

export function useXScale(
  data: ChartData[],
  margins: Margins,
  width: number,
  isLive?: boolean,
): d3.ScaleTime<number, number> {
  if (isLive) {
    return d3
      .scaleTime()
      .domain([Date.now() - 300000, Date.now()] as [number, number])
      .range([margins.left, width - margins.right])
  } else {
    const items = data.flatMap((e) => e.data)
    return d3
      .scaleTime()
      .domain(d3.extent(items, (d) => d.date) as [Date, Date])

      .range([margins.left, width - margins.right])
  }
}

export function useYScale(
  data: ChartData[],
  margins: Margins,
  width: number,
  height: number,
  padding: number,
  alert?: { min: number; max: number },
): d3.ScaleLinear<number, number, never> {
  return useMemo(() => {
    const items = data.flatMap((e) => e.data)
    const domain = [
      d3.min(items, (d) => d.value) as number,
      d3.max(items, (d) => d.value) as number,
      ...(alert?.min && alert?.max ? [alert.min, alert.max] : []),
    ].sort((a, b) => a - b)

    return d3
      .scaleLinear()
      .domain([domain[0] - padding, domain[domain.length - 1] + padding])
      .range([height - margins.bottom, margins.top])
      .nice()
  }, [data, padding, height])
}
