export const getHoursWith30MinuteIncrements = (): string[] => {
  const hours: string[] = []
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const formattedHour = hour.toString().padStart(2, '0')
      const formattedMinute = minute.toString().padStart(2, '0')
      hours.push(`${formattedHour}:${formattedMinute}`)
    }
  }
  return hours
}
interface TimeZoneInfo {
  friendlyName: string
  offsetString: string
  offsetNumeric: number
  longTimezones: string[]
}

function offsetStringToNumeric(offsetString: string): number {
  const sign = offsetString.startsWith('GMT-') ? -1 : 1
  const [hours, minutes] = offsetString.slice(4).split(':').map(Number)
  return sign * (hours * 60 + minutes)
}

interface TimezoneEntry {
  offset: string
  name: string
}

interface ConvertedTimezoneEntry {
  value: string
  label: string
}

export function getTimeZoneInfo(): ConvertedTimezoneEntry[] {
  const data = [
    {
      offset: '-12:00',
      name: 'AoE (Anywhere on Earth)',
    },
    {
      offset: '-11:00',
      name: 'Niue Time',
    },
    {
      offset: '-10:00',
      name: 'Hawaii-Aleutian Standard Time',
    },
    {
      offset: '-09:00',
      name: 'Alaska Standard Time',
    },
    {
      offset: '-08:00',
      name: 'Pacific Standard Time',
    },
    {
      offset: '-07:00',
      name: 'Mountain Standard Time',
    },
    {
      offset: '-06:00',
      name: 'Central Standard Time',
    },
    {
      offset: '-05:00',
      name: 'Eastern Standard Time',
    },
    {
      offset: '-04:00',
      name: 'Atlantic Standard Time',
    },
    {
      offset: '-03:30',
      name: 'Newfoundland Standard Time',
    },
    {
      offset: '-03:00',
      name: 'Argentina Time',
    },
    {
      offset: '-02:00',
      name: 'South Georgia Time',
    },
    {
      offset: '-01:00',
      name: 'Azores Standard Time',
    },
    {
      offset: '00:00',
      name: 'Greenwich Mean Time',
    },
    {
      offset: '+01:00',
      name: 'Central European Time',
    },
    {
      offset: '+02:00',
      name: 'Eastern European Time',
    },
    {
      offset: '+03:00',
      name: 'Moscow Standard Time',
    },
    {
      offset: '+04:00',
      name: 'Azerbaijan Standard Time',
    },
    {
      offset: '+05:00',
      name: 'Pakistan Standard Time',
    },
    {
      offset: '+05:30',
      name: 'Indian Standard Time',
    },
    {
      offset: '+06:00',
      name: 'Bangladesh Standard Time',
    },
    {
      offset: '+07:00',
      name: 'Indochina Time',
    },
    {
      offset: '+08:00',
      name: 'China Standard Time',
    },
    {
      offset: '+09:00',
      name: 'Japan Standard Time',
    },
    {
      offset: '+10:00',
      name: 'Australian Eastern Standard Time',
    },
    {
      offset: '+11:00',
      name: 'Solomon Islands Time',
    },
    {
      offset: '+12:00',
      name: 'New Zealand Standard Time',
    },
  ]
  const result: ConvertedTimezoneEntry[] = []
  for (const entry of data) {
    const { offset, name } = entry
    const value = `${offset} ${name}`
    const label = `GMT${offset}`
    result.push({ value, label })
  }
  return result
}

// export function getTimeZoneInfo(): TimeZoneInfo[] {
//   const supportedTimeZones = (Intl as any).supportedValuesOf('timeZone')
//   const timeZoneMap = new Map<string, TimeZoneInfo>()

//   supportedTimeZones.forEach((tz:any) => {
//     const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
//       timeZone: tz,
//       timeZoneName: 'longGeneric',
//     })
//     const offsetFormat = new Intl.DateTimeFormat('en-US', {
//       timeZone: tz,
//       timeZoneName: 'longOffset',
//     })

//     const formattedDate = dateTimeFormat.format(new Date())
//     const [, friendlyName] = formattedDate.split(', ')

//     const formattedOffset = offsetFormat.format(new Date())
//     const [, offsetString] = formattedOffset.split(', ')

//     const key = `${friendlyName}:${offsetString}`
//     const offsetNumeric = offsetStringToNumeric(offsetString)

//     if (timeZoneMap.has(key)) {
//       timeZoneMap.get(key)?.longTimezones.push(tz)
//     } else {
//       timeZoneMap.set(key, {
//         friendlyName,
//         offsetString,
//         offsetNumeric,
//         longTimezones: [tz],
//       })
//     }
//   })

//   return [...(timeZoneMap.values() as any)]
// }
