import { FC, useCallback, useEffect, useState } from 'react'

import { Backdrop, Box } from '@mui/material'
import axios from 'axios'
import { useBeforeUnload, useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { timestampButtons } from 'src/helpers/getTimestampButtons'

import { HistoricalValues } from './components/HistoricalValues'
import MachineChart from './components/MachineChart'
import MachineDetails from './components/MachineDetails'
import TimePicker from './components/TimePicker'
import { useWidth } from './hooks/use-width'

const MachineView: FC = () => {
  const [width, elementRef] = useWidth()
  const [eventSource, setEventSource] = useState<EventSource | null>(null)

  const [machineData, setMachineData] = useState<any>([])

  const [newDomeSettingsData, setNewDomeSettingsData] = useState<any>([])
  const [newOvertravelData, setNewOvertravelData] = useState<any>([])
  const [newClampRingData, setNewClampRingData] = useState<any>([])
  // const [newCpm, setNewCpm] = useState([])
  const [liveData, setLiveData] = useState<any>([])
  const [dataAverages, setDataAverages] = useState<any>({})
  console.log(dataAverages)

  const [thresholds, setThresholds] = useState<any>({})
  const [statuses, setStatuses] = useState<any>({})
  // replace with a date range obj
  const [selectedRange, setSelectedRange] = useState<any | 'live'>(
    timestampButtons[0].getTimestamp(),
  )
  const [isLive, setIsLive] = useState<boolean>(false)
  const [lastUpdated, setLastUpdated] = useState<{
    dome: Date
    overtravel: Date
    clampRing: Date
  }>()

  const { pathname } = useLocation()
  const { plantId, lineId, bodymakerId } = useParams<{
    plantId: string
    lineId: string
    bodymakerId: string
  }>()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const clearData = useCallback(() => {
    setNewClampRingData([])
    setNewDomeSettingsData([])
    setNewOvertravelData([])
    setMachineData([])
    // setNewCpm([])
  }, [])

  useEffect(() => {
    setSelectedRange(timestampButtons[0].getTimestamp())
  }, [pathname])
  const fetchData = useCallback(
    async (startDate?: Date, endDate?: Date): Promise<void> => {
      console.log('fetching data', startDate, endDate)

      setIsLoading(true)
      clearData()

      const start = startDate
        ? startDate?.toISOString()
        : selectedRange.startDate?.toISOString()
      const end = endDate
        ? endDate?.toISOString()
        : selectedRange.endDate?.toISOString()

      const machineData = await axios.get(
        `/company/factories/${plantId}/lines/${lineId}/bodymakers/${bodymakerId}`,
        {
          params: {
            startDate: start,
            endDate: end,
          },
        },
      )
      console.log('machineData', machineData.data)

      machineData.data.averages &&
        setDataAverages({
          dome: machineData?.data?.averages?.domeAverage,
          overtravel: machineData?.data?.averages?.overtravelAverage,
          clampRing: machineData?.data?.averages?.clampRingAverage,
          // cpmAverage: machineData.data.averages.cpmAverage,
        })
      machineData.data.lastUpdated &&
        setLastUpdated(machineData.data.lastUpdated)

      machineData.data.thresholds &&
        setThresholds({
          domeHigh: machineData.data.thresholds.domeThresholdHigh,
          domeLow: machineData.data.thresholds.domeThresholdLow,
          overtravelHigh: machineData.data.thresholds.overTravelThresholdHigh,
          overtravelLow: machineData.data.thresholds.overTravelThresholdLow,
          cpmThresholdHigh: machineData.data.thresholds.cpmThresholdHigh,
          cpmThresholdLow: machineData.data.thresholds.cpmThresholdLow,
          clampRingHigh: machineData.data.thresholds.clampRingThresholdHigh,
          clampRingLow: machineData.data.thresholds.clampRingThresholdLow,
        })

      machineData.data.statuses &&
        setStatuses({
          dome: machineData.data.statuses.domeStatus,
          overtravel: machineData.data.statuses.overTravelStatus,
          cpm: machineData.data.statuses.cpmStatus,
          clampRing: machineData.data.statuses.clampRingStatus,
        })

      machineData.data.dome =
        machineData.data?.dome.length > 0 && machineData.data?.dome !== null
          ? machineData.data.dome.map(transformData)
          : []
      machineData.data.overtravel =
        machineData.data?.overtravel.length > 0 &&
        machineData.data?.overtravel !== null
          ? machineData.data.overtravel.map(transformData)
          : []
      machineData.data.clampRing =
        machineData.data?.clampRing.length > 0 &&
        machineData.data?.clampRing !== null
          ? machineData.data.clampRing.map(transformData)
          : []
      machineData.data.cpm = machineData.data.cpm?.map(transformData)
      console.log(machineData.data, 'machineData.data')

      setMachineData(machineData.data)
      // setCpmData(machineData.data.cpm.map(transformData))
    },
    [
      selectedRange.startDate,
      selectedRange.endDate,
      plantId,
      lineId,
      bodymakerId,
    ],
  )

  useEffect(() => {
    let mounted = true
    if (mounted) {
      if (selectedRange !== 'live') {
        console.log(selectedRange, 'hmm')

        setIsLive(false)

        fetchData()
          .then(() => {
            setIsLoading(false)
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        console.log(selectedRange, 'live')

        setIsLive(true)
      }
    }

    return () => {
      mounted = false
    }
  }, [selectedRange])

  useBeforeUnload(() => {
    eventSource?.close()
  })
  useEffect(() => {
    const userString = localStorage.getItem('user') ?? ''
    const user = JSON.parse(userString)
    const token = user?.token
    let errorRetryCount = 0
    // const handleMockupEvent = (event: any): void => {
    //   console.log('mockup', JSON.parse(event.data))
    // }

    const handlePingEvent = (): void => {
      errorRetryCount = 0
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(() => {
        console.warn('Connection lost, reconnecting...')
        eventSource?.close()
        connectToSSE()
      }, 8000)
    }

    const handleInitialData = (event: any): void => {
      errorRetryCount = 0
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(() => {
        console.warn('Connection lost, reconnecting...')
        eventSource?.close()
        connectToSSE()
      }, 8000)
      try {
        const newData = JSON.parse(event.data)
        console.log(newData, 'newData in handleInitialData')

        setNewDomeSettingsData(newData?.dome?.map(transformData))
        setNewOvertravelData(newData?.overtravel?.map(transformData))
        setNewClampRingData(newData?.clampRing?.map(transformData))

        setLastUpdated({
          dome: newData?.lastUpdated,
          overtravel: newData?.lastUpdated,
          clampRing: newData?.lastUpdated,
        })

        setDataAverages({
          dome: newData?.dome[1]?.data[0]?.value,
          overtravel: newData?.overtravel[1]?.data[0]?.value,
          clampRing: newData?.clampRing[1]?.data[0]?.value,
          // cpmAverage: newData.cpmAverage,
        })
        setLastUpdated({
          dome: newData?.lastUpdated,
          overtravel: newData?.lastUpdated,
          clampRing: newData?.lastUpdated,
        })
      } catch (err) {
        console.error('Failed to parse event data', err)
      }
    }

    const handleMessageEvent = (event: any): void => {
      errorRetryCount = 0
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(() => {
        console.warn('Connection lost, reconnecting...')
        eventSource?.close()
        connectToSSE()
      }, 8000)
      try {
        const newData = JSON.parse(event.data)
        errorRetryCount = 0
        console.log(newData.clampRing.map(transformData))
        console.log(newData, 'newData in handleMessageEvent')

        setNewDomeSettingsData(newData?.dome?.map(transformData))
        setNewOvertravelData(newData?.overtravel?.map(transformData))
        setNewClampRingData(newData?.clampRing?.map(transformData))

        setLastUpdated({
          dome: newData?.lastUpdated,
          overtravel: newData?.lastUpdated,
          clampRing: newData?.lastUpdated,
        })

        setDataAverages({
          dome: newData?.dome[1]?.data[0]?.value,
          overtravel: newData?.overtravel[1]?.data[0]?.value,
          clampRing: newData?.clampRing[1]?.data[0]?.value,
          // cpmAverage: newData.cpmAverage,
        })
        setLastUpdated({
          dome: newData?.lastUpdated,
          overtravel: newData?.lastUpdated,
          clampRing: newData?.lastUpdated,
        })
      } catch (err) {
        console.error('Failed to parse event data', err)
      }
    }

    const handleErrorEvent = (event: any): void => {
      errTimeout && clearTimeout(errTimeout)
      try {
        errorRetryCount++
        if (errorRetryCount >= 5) {
          disconnect()
          throw new Error('Error connecting to live data')
        }

        eventSource?.close()
        errTimeout = setTimeout(connectToSSE, 5000) // Retry connection after 5 seconds
      } catch (err: string | any) {
        if (typeof err === 'string') {
          toast.error(err)
          return
        }
        toast.error(err.message)
      }
    }

    let timeout: NodeJS.Timeout | null = null
    let errTimeout: NodeJS.Timeout | null = null
    let newEventSource: EventSource | null = null
    const connectToSSE = (): void => {
      setIsLoading(true)
      newEventSource = new EventSource(
        `${
          axios.defaults.baseURL
        }company/factories/${plantId}/lines/${lineId}/bodymakers/${bodymakerId}/live-data?token=${token}&initialData=${
          newDomeSettingsData?.data?.length > 0 &&
          newOvertravelData?.data?.length > 0 &&
          newClampRingData?.data?.length > 0
        }`,
        { withCredentials: true },
      )

      setEventSource(newEventSource)
      // if (timeout) clearTimeout(timeout)
      // if (errorRetryCount >= 5) return
      // timeout = setTimeout(() => {
      //   console.warn('Connection lost, reconnecting...')
      //   toast.warn('Connection lost, reconnecting...')
      //   eventSource?.close()
      //   connectToSSE()
      // }, 16000)

      // eventSource.addEventListener('mockup', handleMockupEvent)
      newEventSource.onopen = () => {
        setIsLoading(false)
        setDataAverages({
          dome: 0,
          overtravel: 0,
          clampRing: 0,
        })

        setLastUpdated({
          dome: new Date(),
          overtravel: new Date(),
          clampRing: new Date(),
        })
      }
      newEventSource.addEventListener('init', handleInitialData)
      newEventSource.addEventListener('ping', handlePingEvent)
      newEventSource.addEventListener('message', handleMessageEvent)
      newEventSource.addEventListener('error', handleErrorEvent)
    }

    const fetchDataAndConnect = (): void => {
      // fetchData(subMinutes(new Date(), 30), new Date())
      //   .then(() => {
      //     connectToSSE()
      //     setIsLoading(false)
      //     console.log('Connecting to SSE...')
      //   })
      //   .catch((err) => {
      //     console.error('Failed to fetch initial data', err)
      //   })
      setMachineData([])
      connectToSSE()
    }

    const disconnect = (): void => {
      console.log('Disconnecting...')
      timeout && clearTimeout(timeout)
      errTimeout && clearTimeout(errTimeout)
      eventSource?.close()
      // eventSource?.removeEventListener('mockup', handleMockupEvent)
      eventSource?.removeEventListener('ping', handlePingEvent)
      eventSource?.removeEventListener('message', handleMessageEvent)
      eventSource?.removeEventListener('error', handleErrorEvent)
    }

    if (isLive) {
      disconnect() // Disconnect if already connected
      fetchDataAndConnect()
    } else {
      disconnect()
    }

    return () => {
      disconnect()
      newEventSource?.close()
    }
  }, [bodymakerId, plantId, isLive, lineId])

  const handleDateSelection = (dateRange: any | 'live'): void => {
    console.log(dateRange, 'dateRange')

    setSelectedRange(dateRange)
  }

  return (
    <>
      <Box
        sx={{
          mt: '36px',
          width: '100%',
        }}
        ref={elementRef}
      >
        <TimePicker
          timestampButtons={timestampButtons}
          onDateRangeSelected={(dateRange: any | 'live') => {
            handleDateSelection(dateRange)
          }}
        />
        <MachineDetails
          averages={dataAverages}
          statuses={statuses}
          isLoading={isLoading}
          lastUpdated={lastUpdated}
        />

        <MachineChart
          isLive={isLive}
          chartData={machineData.dome ?? []}
          chartWidth={width}
          chartAvg={`${dataAverages.dome} lbf`}
          title="Dome Settings"
          newData={newDomeSettingsData}
          isLoading={isLoading}
          highThreshold={thresholds.domeHigh}
          lowThreshold={thresholds.domeLow}
        />

        <MachineChart
          isLive={isLive}
          unit="in"
          chartData={machineData.overtravel ?? []}
          chartWidth={width}
          chartAvg={`${dataAverages.overtravel} in`}
          title="Overtravel"
          newData={newOvertravelData}
          highThreshold={thresholds.overtravelHigh}
          lowThreshold={thresholds.overtravelLow}
          isLoading={isLoading}
        />

        <MachineChart
          isLive={isLive}
          chartData={machineData.clampRing ?? []}
          chartWidth={width}
          chartAvg={`${dataAverages.clampRing} lbf`}
          title="Clamp Ring"
          newData={newClampRingData}
          highThreshold={thresholds.clampRingHigh}
          isLoading={isLoading}
          lowThreshold={thresholds.clampRingLow}
        />

        <HistoricalValues bodyMakerId={bodymakerId} />
      </Box>
      {isLoading && (
        <Backdrop
          open={true}
          sx={{ zIndex: 9999, background: 'transparent' }}
        ></Backdrop>
      )}
    </>
  )
}

export default MachineView

const transformData = (data: any): any => {
  console.log(data, 'data in transformData')
  console.log(
    {
      ...data,
      data: data.data.reverse().map((d: any) => ({
        ...d,
        date: new Date(d.date),
      })),
    },
    'transformed data',
  )

  return {
    ...data,
    data: data.data.map((d: any) => ({
      ...d,
      date: new Date(d.date),
    })),
  }
}
