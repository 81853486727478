// A custom theme for this app
import { Theme } from '@mui/system'

export const theme = {
  typography: {
    fontFamily: ['Roboto'].join(','),
    fontSize: 14,
    allVariants: {
      lineHeight: 1.25,
    },
    h3: {
      fontWeight: 700,
      fontSize: 36,
      color: '#101213',
    },
    h4: {
      fontWeight: 700,
      fontSize: 28,
      color: '#101213',
    },
    h5: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: 1.5,
      color: '#101213',
    },
    h6: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: 1.5,
      color: '#101213',
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        a: {
          textDecoration: 'none',
          cursor: 'pointer',
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          transform: 'inherit',
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 3,
      },
      styleOverrides: {
        root: {
          padding: 20,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: '#f7f7f7',
          },
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingTop: 10,
          paddingBottom: 10,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          padding: 9,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '14px',
          lineHeight: '21px',
          borderWidth: '0px ',
        },
      },
      variants: [
        {
          props: { variant: 'google' },
          style: {
            backgroundColor: `#ffffff`,
            border: '1px solid #000000',
            '&:hover': {
              backgroundColor: `#ffffff`,
            },
          },
        },
      ],
    },
  },
  palette: {
    primary: {
      main: '#1068EB',
    },
    action: {
      main: '#1068EB',
    },
    access: {
      main: '#EBF7ED',
    },
    warning: {
      main: '#F59F00',
    },
    error: {
      main: '#DC2020',
    },
    static: {
      main: '#FFF',
    },
    secondary: {
      main: '#6A7178',
    },
    background: {
      default: '#E3E6E8',
    },
  },
}
