import { ReactElement, useState } from 'react'

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import {
  IconButton,
  SxProps,
  TableCell,
  TableRow as MUITableRow,
  Theme,
} from '@mui/material'
import { Row } from 'react-table'

interface TableRowProps {
  row: Row<any>
  extendableComponent?: React.ComponentType<{
    row: Row<any>
    handleClick: () => void
    onUserDeletion?: (id: number) => void
    tdStyles?: SxProps<Theme> | undefined
  }>
  tdStyles?: SxProps<Theme> | undefined
  styles?: any
  onUserDeletion?: (id: number) => void
}

export const TableRow = ({
  row,
  extendableComponent: Component,
  tdStyles,
  styles,
  onUserDeletion,
}: TableRowProps): ReactElement => {
  const [isExtended, setIsExtended] = useState(false)

  const handleClick = (): void => {
    setIsExtended((prevState) => !prevState)
  }

  const handleUserDelete = (id: number): void => {
    onUserDeletion && onUserDeletion(id)
  }

  return (
    <>
      {isExtended ? (
        Component && (
          <Component
            row={row}
            handleClick={handleClick}
            onUserDeletion={handleUserDelete}
            tdStyles={tdStyles}
          />
        )
      ) : (
        <MUITableRow
          {...row.getRowProps()}
          sx={{
            backgroundColor: '#FFF',
            borderBottom: '1px solid #CED4DA',
            '&:last-child': {
              border: 'none',
            },
            ...styles,
          }}
        >
          {row.cells.map((cell) => {
            const { key, role } = { ...cell.getCellProps() }
            return (
              <TableCell
                key={key}
                role={role}
                sx={{
                  p: '12px 16px',
                  ...tdStyles,
                }}
              >
                {cell.render('Cell')}
              </TableCell>
            )
          })}
          {!!Component && (
            <TableCell
              key={'extendCell'}
              role="cell"
              sx={{
                p: '12px 16px',
                ...tdStyles,
              }}
            >
              <IconButton size="small" onClick={handleClick}>
                {isExtended ? (
                  <KeyboardArrowUp color="action" />
                ) : (
                  <KeyboardArrowDown color="action" />
                )}
              </IconButton>
            </TableCell>
          )}
        </MUITableRow>
      )}
    </>
  )
}
