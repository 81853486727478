import React, { FC, useEffect, useState } from 'react'

import { Backdrop, Box, CircularProgress } from '@mui/material'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

import { PageHeading } from 'src/components/PageHeading'
import { TabsRow } from 'src/components/TabsRow'
import MachineConnectedDevices from 'src/modules/MachineConnectedDevices'
import MachineLogs from 'src/modules/MachineLogs'
import MachineOverview from 'src/modules/MachineOverview'
import MachineView from 'src/modules/MachineView'
import Breadcrumb from 'src/ui/Breadcrumbs/Breadcrumb'
import { TabContainer } from 'src/ui/TabContainer'

const tabsContent = {
  Chart: { component: <MachineView />, title: 'Machine View' },
  Overview: { component: <MachineOverview />, title: 'Machine Overview' },
  Channels: {
    component: <MachineConnectedDevices />,
    title: 'Connected Data Channels',
  },
  Logs: { component: <MachineLogs />, title: 'Machine Logs' },
}

type TTabsNames = keyof typeof tabsContent

export const MachineViewPage: FC = () => {
  const tabsArr = Object.keys(tabsContent) as TTabsNames[]
  const [currentTab, setCurrentTab] = useState<TTabsNames>(tabsArr[0])
  const handleChangeTab = (tabName: TTabsNames): void => {
    setCurrentTab(tabName)
    navigate(`#${tabName}`)
  }

  const navigate = useNavigate()
  const [breadcrumbs, setBreadcrumbs] = useState<any>()
  const { plantId, lineId, bodymakerId } = useParams()

  useEffect(() => {
    const req = async (): Promise<void> => {
      const res = await axios.get(
        `data/factories/${plantId}/lines/${lineId}/bodymakers/${bodymakerId}`,
      )
      setBreadcrumbs([
        {
          label: res.data.factoryName,
          href: '/',
        },
        {
          label: res.data.lineName,
          href: `/factories/${plantId}/lines/${lineId}`,
        },
        {
          label: res.data.bodymakerName,
        },
      ])
    }

    req()
      .then()
      .catch((err) => {
        console.log(err)

        if (err.response.status === 404) {
          navigate('/', { replace: true })
        }
      })
  }, [bodymakerId, plantId, lineId])

  useEffect(() => {
    const fragment = window.location.hash.substring(1) // Get the fragment from the URL
    const tabNames = tabsArr.map((tab) => tab as string) // Convert tab names to lowercase for comparison

    if (tabNames.includes(fragment)) {
      // If the fragment matches a tab name, set it as the current tab
      setCurrentTab(fragment as TTabsNames)
    }
    // else {
    //   // If the fragment doesn't match any tab name, navigate to the default tab (Machine View)
    //   // navigate(`#${tabsArr[0]}`, {
    //   //   replace: true,
    //   // })
    //   console.log('hmm2')
    // }
  }, [tabsArr])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          m: '36px 0 224px',
        }}
      >
        <Box
          sx={{
            mb: '48px',
            width: '100%',
          }}
        >
          <Breadcrumb
            breadcrumbs={
              breadcrumbs ?? [
                {
                  label: 'Loading...',
                },
              ]
            }
          />
        </Box>
        <PageHeading title={tabsContent[currentTab].title} />
        <TabsRow<TTabsNames>
          tabsArr={tabsArr}
          active={currentTab}
          onClick={handleChangeTab}
          styles={{ mt: '36px' }}
        />
        <TabContainer
          styles={{
            p: 0,
            backgroundColor: 'transparent',
          }}
        >
          {tabsContent[currentTab].component}
        </TabContainer>
      </Box>
    </>
  )
}
