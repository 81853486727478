import React, { FC, useEffect, useState } from 'react'

import {
  Box,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
} from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import SVG from 'react-inlinesvg'
import { toast } from 'react-toastify'

import { IconChevronDown } from 'src/assets/icons'
import { PageHeading } from 'src/components/PageHeading'
import { CustomButton } from 'src/ui/Button'
import BpRadio from 'src/ui/CustomRadioButton'

import OnDemandReport from './OnDemandReport'
// import RecurringReport from './RecurringReport'

const SelectTimeframe: FC<{
  onBackPress: () => void
  isEditingTemplate?: boolean
  timeframeData?: any
  onNextPress: (
    type: 'recurring' | 'onDemand',
    reportTimezone: any,
    reportTimestampFormat: any,
    dates?: any,
    timeframe?: any,
    reportFrequency?: any,
    reportTimezoneString?: any,
    reportTime?: any,
  ) => void
}> = ({
  onBackPress,
  onNextPress,
  timeframeData: prevTimeframeData,
  isEditingTemplate,
}) => {
  const [value, setValue] = useState(prevTimeframeData.type ?? 'onDemand')
  const [timeframeData, setTimeframeData] = useState<any>({
    type: prevTimeframeData.type ?? 'onDemand',
    reportTimezone: prevTimeframeData.reportTimezone,
    // reportTimestampFormat: undefined,
    dates: {
      startDate: dayjs.utc(prevTimeframeData.dates?.startDate),
      endDate: dayjs.utc(prevTimeframeData.dates?.endDate),
    },
    // timeframe: undefined,
    reportFrequency: prevTimeframeData.reportFrequency,
    reportTimezoneString: prevTimeframeData.reportTimezoneString,
    reportTime: prevTimeframeData.reportTime,
  })

  useEffect(() => {
    setTimeframeData({
      ...timeframeData,
      type: value,
    })
  }, [value])

  // useEffect(() => {
  //   setTimeframeData({
  //     reportTimezone: undefined,
  //     reportTimestampFormat: undefined,
  //     dates: undefined,
  //     timeframe: undefined,
  //     reportFrequency: undefined,
  //     reportTime: undefined,
  //   })
  // }, [value])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue((event.target as HTMLInputElement).value)
  }
  const handletypeSubmission = (): void => {
    onNextPress(
      timeframeData.type,
      timeframeData.reportTimezone,
      // timeframeData.reportTimestampFormat,
      timeframeData.dates,
      // timeframeData.timeframe,
      timeframeData.reportFrequency,
      timeframeData.reportTimezoneString,
      timeframeData.reportTime,
    )
  }
  return (
    <Box
      sx={{
        width: '100%',
        pb: '240px',
      }}
    >
      <PageHeading
        title="Select Timeframe"
        subtitle="Insert the values that you want for your report."
      />
      <Box
        sx={{
          mt: '48px',
          mb: '24px',
        }}
      >
        <CustomButton
          styles={{
            marginRight: '16px',
            '& > div': {
              marginRight: 0,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          }}
          appearance="clear-white-default"
          onClick={onBackPress}
        >
          <SVG
            src={IconChevronDown}
            style={{
              fill: '#1068EB',
              //   marginRight: '8px',
              marginBottom: '0px',
              rotate: '90deg',
            }}
          />
          Back
        </CustomButton>
        <CustomButton
          styles={{
            '& > div': {
              marginRight: 0,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          }}
          appearance="primary-soft-default"
          onClick={() => {
            if (
              !timeframeData.reportTimezone ||
              // !timeframeData.reportTimestampFormat ||
              (value === 'onDemand' && !timeframeData.dates) ||
              (value === 'recurring' &&
                (!timeframeData.reportFrequency || !timeframeData.reportTime))
            ) {
              toast.error('Please select all the fields')
              return
            }
            handletypeSubmission()
          }}
        >
          Next Step
          <SVG
            src={IconChevronDown}
            style={{
              fill: '#fff',
              marginLeft: '8px',
              marginBottom: '0px',
              rotate: '270deg',
            }}
          />
        </CustomButton>
      </Box>

      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#303D60',
            p: '12px 16px',
            borderRadius: '4px 4px 0px 0px',
          }}
        >
          <Typography
            sx={{
              fontSize: '22px',
              fontWeight: 500,
              color: '#fff',
            }}
          >
            Report Type
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: '#E5F0FE',
            width: '100%',
            height: '88px',
            display: 'flex',
            alignItems: 'center',
            padding: '36px 32px',
          }}
        >
          <FormControl>
            <RadioGroup
              aria-labelledby="timeframe-label"
              name="timeframe"
              value={value}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="onDemand"
                disabled={isEditingTemplate}
                control={<BpRadio />}
                label="On Demand"
                sx={{
                  '& .Mui-checked + .MuiFormControlLabel-label': {
                    color: '#1068EB',
                  },
                }}
              />
              <FormControlLabel
                value="recurring"
                control={<BpRadio />}
                label="Recurring"
                sx={{
                  '& .Mui-checked + .MuiFormControlLabel-label': {
                    color: '#1068EB',
                  },
                }}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        {/* {value === 'recurring' && (
          <RecurringReport
            onTimeChange={(time: string) => {
              console.log(time)
              setTimeframeData({
                ...timeframeData,
                reportTime: time,
              })
            }}
            onTimezoneChange={(timezone: string) => {
              console.log(timezone)
              setTimeframeData({
                ...timeframeData,
                reportTimezone: timezone,
              })
            }}
            onFrequencyChange={(frequency: string) => {
              console.log(frequency)
              setTimeframeData({
                ...timeframeData,
                reportFrequency: frequency,
              })
            }}
            onTimeFormatChange={(timeFormat: string) => {
              console.log(timeFormat)
              setTimeframeData({
                ...timeframeData,
                reportTimestampFormat: timeFormat,
              })
            }}
          />
        )} */}
        <OnDemandReport
          defaults={prevTimeframeData}
          isRecurring={value === 'recurring'}
          onTimeChange={(time: string) => {
            console.log(time)

            setTimeframeData({
              ...timeframeData,
              reportTime: time,
            })
          }}
          onFrequencyChange={(frequency: string) => {
            console.log(frequency)

            setTimeframeData({
              ...timeframeData,
              reportFrequency: frequency,
            })
          }}
          onDatesSelection={(
            startDate?: Dayjs | Date,
            endDate?: Dayjs | Date,
          ) => {
            console.log(startDate, endDate)

            if (!startDate || !endDate) {
              setTimeframeData({
                ...timeframeData,
                dates: undefined,
              })
              return
            }

            setTimeframeData({
              ...timeframeData,
              dates: {
                startDate,
                endDate,
              },
            })
          }}
          // onTimeFormatSelection={(timeFormat: string) => {
          //   console.log(timeFormat)

          //   setTimeframeData({
          //     ...timeframeData,
          //     reportTimestampFormat: timeFormat,
          //   })
          // }}
          // onTimeframeSelection={(startDate?: Dayjs, endDate?: Dayjs) => {
          //   console.log(startDate, endDate)

          //   if (!startDate || !endDate) {
          //     setTimeframeData({
          //       ...timeframeData,
          //       timeframe: undefined,
          //     })
          //     return
          //   }

          //   setTimeframeData({
          //     ...timeframeData,
          //     timeframe: {
          //       startDate,
          //       endDate,
          //     },
          //   })
          // }}
          onTimezoneSelection={(timezone: string, timezoneString: string) => {
            console.log(timezone)

            setTimeframeData({
              ...timeframeData,
              reportTimezone: timezone,
              reportTimezoneString: timezoneString,
            })
          }}
        />
      </Box>
    </Box>
  )
}

export default SelectTimeframe
