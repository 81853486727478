import { FC } from 'react'

import styled from '@emotion/styled'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { Icon3DCube } from 'src/assets/icons'
import { routes } from 'src/router'

export const ClassifyCard: FC<{
  item: any
}> = ({ item }) => {
  const router = useNavigate()

  return (
    <ClassifyCardStyles>
      <div className="header">
        <div className="title">{`${item.clusterStatus
          .split('')[0]
          .toUpperCase()}${item.clusterStatus.slice(1)} Cluster ${
          item.cluster_id
        } - ${item.sensorName}`}</div>
        {/* <IconButton size="small" className="button">
          <Close />
        </IconButton> */}
      </div>
      <div className="chart">
        <img
          style={{ width: '100%' }}
          src={`data:image/png;base64, ${item.image}`}
          alt="Sample chart"
        />
      </div>
      <CardHeader>{`${item.clusterStatus
        .split('')[0]
        .toUpperCase()}${item.clusterStatus.slice(1)} behaviour`}</CardHeader>
      <CardLabel>
        <img src={Icon3DCube} alt="Cubic icon" />
        {item.bodymaker.name}
      </CardLabel>
      <ClassifyCardMessage>
        Choose the most appropriate way to describe the behavior in the chart
      </ClassifyCardMessage>
      <ClassifyActionRow>
        <Button
          variant="contained"
          color="primary"
          className="primary"
          onClick={() => {
            router(routes.activeLearningSample.replace(':id', `${item.GUID}_${item.Sensor_id}_${item.Sensor_type}`))
          }}
        >
          View
        </Button>
        {/* <Button
          variant="outlined"
          color="secondary"
          className="outlined"
          onClick={() => {}}
        >
          Auto Approve
        </Button> */}
      </ClassifyActionRow>
    </ClassifyCardStyles>
  )
}

const ClassifyCardStyles = styled.div`
  position: relative;
  background-color: white;
  padding: 16px;
  padding-bottom: 24px;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  .header {
    display: flex;
    justify-content: space-between;

    .title {
      color: #4f575e;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }

    .button {
      position: absolute;
      right: 16px;
    }
  }

  .chart {
    width: 100%;
    margin: 16px 0;
  }
`

const CardHeader = styled.h5`
  color: #000;
  font-size: 20px;
  font-weight: 700;
`

const CardLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  // text styles
  color: #4f575e;
  size: 14px;

  img {
    height: 24px;
    width: 24px;

    margin: 8px 0 8px 0;
    /* background-color: red; */
  }
`

const ClassifyCardMessage = styled.p`
  margin-top: 16px;
  width: 250px;

  color: var(--neutral-neutral-6, #adb5bd);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

const ClassifyActionRow = styled.div`
  margin-top: 36px;

  display: flex;
  gap: 16px;

  .primary {
    background-color: #1068eb;

    &:hover {
      background-color: #2f80ed;
    }
  }

  .outlined {
    color: #1068eb;
    border: 1px solid var(--brand-primary-base, #1068eb);
    box-sizing: border-box;
  }
`
