import { useEffect, useState } from 'react'

import { Box, Button } from '@mui/material'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { GridLoader } from 'react-spinners'

import { PageHeading } from 'src/components/PageHeading'
import {
  SamplesGrid,
  SamplesItem,
  SampleItemTitle,
  SampleItemSubtitle,
} from 'src/pages/ActiveLearning/SamplesGrid'
import { routes } from 'src/router'
import { Select } from 'src/ui/Select'
import { TablePagination } from 'src/components/Table/components/TablePagination'
import { formatDateTime } from 'src/helpers/timestamp-generator'

export const sensorNames = {
  Sensor1: 'Overtravel',
  Sensor2: 'Clamp Ring',
  Sensor3: 'Dome',
}

const ActiveLearning = () => {
  const router = useNavigate()
  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [sensorsName, setSensorsName] = useState<any[]>([
    { label: 'Overtravel', value: 'Overtravel' },
    { label: 'Clamp Ring', value: 'Clamp Ring' },
    { label: 'Dome', value: 'Dome' },
  ])
  const [selectedPlants, setSelectedPlants] = useState<any[]>([])
  const [selectedSensors, setSelectedSensors] = useState<any[]>([])
  const [selectedRegions, setSelectedRegions] = useState<any[]>([])
  const [selectedDevices, setSelectedDevices] = useState<any[]>([])
  const [selectedOrder, setSelectedOrder] = useState<any>({
    orderBy: 'dt',
    order: 'asc',
  })
  const [pagination, setPagination] = useState<any>({
    page: 1,
    pageSize: 20,
    totalItems: 0,
  })
  const [plants, setPlants] = useState<any[]>([])
  const [regions, setRegions] = useState<any[]>([])
  const [devices, setDevices] = useState<any[]>([])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const response = await axios.get('active-learning/questions', {
        params: {
          plantIds: selectedPlants.join(','),
          sensorNames: selectedSensors.join(','),
          regions: selectedRegions.join(','),
          bodymakerIds: selectedDevices.join(','),
          orderBy: selectedOrder.orderBy,
          order: selectedOrder.order,
          ...pagination,
        },
      })
      const data = await response.data

      setPagination({
        ...pagination,
        totalItems: data.totalItems,
      })
      return data
    }

    fetchData()
      .then((data) => {
        setData(data.questions)
        setPlants(
          data.assignedPlants.map((item: any) => {
            return { label: item.name, value: item.id }
          }),
        )
        setRegions(
          data.regions.map((item: any) => {
            return { label: item, value: item }
          }),
        )
        setDevices(
          data.bodymakers.map((item: any) => {
            return { label: item.name, value: item.id }
          }),
        )
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }, [
    selectedPlants,
    selectedSensors,
    selectedRegions,
    selectedDevices,
    selectedOrder,
    pagination.page,
    pagination.pageSize,
  ])

  const handleMultiSelectChange = (value: string[], setEvent: any) => {
    setPagination({ ...pagination, page: 1 })
    setEvent(value)
  }
  return (
    <div style={{ width: '100%' }}>
      <Box m={'48px 0 36px 0'}>
        <PageHeading title={'Samples to Classify'} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          flexWrap: 'wrap',
          marginBottom: '48px',
        }}
      >
        <Select
          key="orderBy"
          options={[
            {
              label: 'Timeframe',
              value: 'dt',
            },
            {
              label: 'Plant name',
              value: 'plantName',
            },

            {
              label: 'Region',
              value: 'region',
            },
            {
              label: 'Bodymaker',
              value: 'bodymakerName',
            },
            {
              label: 'Sensor',
              value: 'sensorName',
            },
          ]}
          placeholder=" "
          isSmall
          width={200}
          value="dt"
          selectLabel={{ name: 'Order by', id: 'orderBy' }}
          styles={{
            backgroundColor: '#F5F7F9',
            borderRadius: '4px',
          }}
          onChange={(e: any) => {
            setPagination({ ...pagination, page: 1 })
            setSelectedOrder({ ...selectedOrder, orderBy: e.target.value })
          }}
        />
        <Select
          key="order"
          options={[
            {
              label: 'Ascending',
              value: 'asc',
            },
            {
              label: 'Descending',
              value: 'desc',
            },
          ]}
          isSmall
          width={200}
          value="asc"
          placeholder=" "
          selectLabel={{ name: 'Order', id: 'order' }}
          styles={{
            backgroundColor: '#F5F7F9',
            borderRadius: '4px',
          }}
          onChange={(e: any) => {
            setPagination({ ...pagination, page: 1 })
            setSelectedOrder({ ...selectedOrder, order: e.target.value })
          }}
        />

        <Select
          key="sensors"
          useLabel
          options={sensorsName}
          isSmall
          width={200}
          isMultiSelect
          selectLabel={{ name: 'Sensors', id: 'sensors' }}
          styles={{
            backgroundColor: '#F5F7F9',
            borderRadius: '4px',
          }}
          onChange={(value: any) => {
            handleMultiSelectChange(value, setSelectedSensors)
          }}
        />
        <Select
          key="plants"
          options={plants}
          isSmall
          useLabel
          width={200}
          isMultiSelect
          selectLabel={{ name: 'Plant', id: 'plant' }}
          styles={{
            backgroundColor: '#F5F7F9',
            borderRadius: '4px',
          }}
          onChange={(value: any) => {
            handleMultiSelectChange(value, setSelectedPlants)
          }}
        />
        <Select
          key="devices"
          options={devices}
          isSmall
          width={200}
          useLabel
          isMultiSelect
          selectLabel={{ name: 'Bodymaker', id: 'bodymaker' }}
          styles={{
            backgroundColor: '#F5F7F9',
            borderRadius: '4px',
          }}
          onChange={(value: any) => {
            handleMultiSelectChange(value, setSelectedDevices)
          }}
        />
        <Select
          key="regions"
          options={regions}
          isSmall
          useLabel
          width={200}
          isMultiSelect
          selectLabel={{ name: 'Region', id: 'region' }}
          styles={{
            backgroundColor: '#F5F7F9',
            borderRadius: '4px',
          }}
          onChange={(value: any) => {
            handleMultiSelectChange(value, setSelectedRegions)
          }}
        />
      </Box>
      {!loading && data?.length > 0 && (
        <>
          <SamplesGrid>
            {data.map((item, index) => (
              <SamplesItem key={index}>
                <SampleItemTitle>
                  Cluster {`${item.cluster_id} - ${item.bodymaker.name}`}
                </SampleItemTitle>
                <SampleItemSubtitle>
                  Sensor Name: {item.sensorName} <br /> Plant Name:{' '}
                  {item.bodymaker.line?.plant?.name} <br /> Timeframe:{' '}
                  {formatDateTime(item?.dt * 1000)}
                  <br />
                  {/* Behaviour: {item.behaviour} */}
                </SampleItemSubtitle>
                <Button
                  variant="contained"
                  onClick={() =>
                    router(
                      routes.activeLearningSample.replace(
                        ':id',
                        `${item.GUID}_${item.Sensor_id}_${item.Sensor_type}`,
                      ),
                    )
                  }
                >
                  Examine
                </Button>
              </SamplesItem>
            ))}
          </SamplesGrid>
          <TablePagination
            totalItems={pagination.totalItems}
            total={pagination.totalItems / pagination.pageSize}
            currentPage={pagination.page}
            limit={pagination.pageSize}
            onPageChange={(page: number) => {
              setPagination({ ...pagination, page })
            }}
          />
        </>
      )}

      {!loading && !data?.length && <div>No samples to classify</div>}
      {loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <GridLoader color="#1068EB" />
        </Box>
      )}
    </div>
  )
}

export default ActiveLearning
