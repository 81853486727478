import React, { useCallback, useEffect, useState } from 'react'

import styled from '@emotion/styled'
import { CheckRounded, RemoveRedEye } from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import axios from 'axios'
import moment from 'moment'
import { useSearchParams, useNavigate, Link } from 'react-router-dom'

import { Notification } from 'src/components/Header/components/UserControl/components/Notifications/entities/notification'
import { PageHeading } from 'src/components/PageHeading'
import { TablePagination } from 'src/components/Table/components/TablePagination'
import TableSkeleton from 'src/components/Table/components/TableSkeleton'
import { getSocket } from 'src/helpers/socketManager'
function useNotificationGetter(
  limit: number,
  page: number,
  force: number,
): [Notification[], boolean, number] {
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [loading, setLoading] = useState(true)
  const [totalPage, setTotalPage] = useState(1)

  useEffect(() => {
    setLoading(true)
    setNotifications([])

    axios
      .get('/notification', { params: { limit, page } })
      .then(({ data }) => {
        setLoading(false)
        setNotifications(data.results)
        setTotalPage(data.totalPages)
      })
      .catch((e) => {
        console.error(e)
      })
  }, [limit, page, force])

  return [notifications, loading, totalPage]
}

export const MyNotificationPage: React.FC = () => {
  /// used to force refetch the data
  const [force, setState] = useState(0)
  const [[limit, page], setPagination] = useState([20, 1])
  const [notifications, loading, totalPage] = useNotificationGetter(
    limit,
    page,
    force,
  )

  const [selectedNotifications, setSelectedNotifications] = useState<
    Array<number | undefined>
  >([])
  const [params, setParams] = useSearchParams()

  const markReadCallback = useCallback(() => {
    axios
      .put('/notification/mark-read', {
        ids: selectedNotifications,
      })
      .then(() => {
        setSelectedNotifications([])
        setState((prev) => prev + 1)
      })
      .catch((e) => {
        console.error(e)
      })
  }, [selectedNotifications])

  useEffect(() => {
    const newLimit = +(params.get('limit') ?? '20')
    const newPage = +(params.get('page') ?? '1')

    if (newLimit != limit || newPage !== page) {
      setSelectedNotifications([])
      setPagination([+(params.get('limit') ?? 20), +(params.get('page') ?? 1)])
    }
    // update the location of the browser to match up with the new settings
  }, [params.get('limit'), params.get('page')])

  return (
    <NotificationsListStyles>
      <PageHeading title="My Notifications" margin="50px 0 20px 0">
        {selectedNotifications.length > 0 && (
          <Button variant="contained" color="info" onClick={markReadCallback}>
            <RemoveRedEye sx={{ margin: '0 10px 0 0' }} /> Mark read
          </Button>
        )}
      </PageHeading>

      <TableContainer
        component={Paper}
        sx={{ borderRadius: '8px' }}
        elevation={1}
      >
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  onChange={(event, checked) => {
                    setSelectedNotifications(
                      checked ? notifications.map((e) => e.id) : [],
                    )
                  }}
                  checked={
                    selectedNotifications.length == notifications.length &&
                    selectedNotifications.length != 0
                  }
                  indeterminate={
                    selectedNotifications.length > 0 &&
                    selectedNotifications.length < notifications.length
                  }
                />
              </TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Notification</TableCell>
              <TableCell>Date</TableCell>
              {/* <TableCell>Read</TableCell> */}
              {/* <TableCell></TableCell> */}
            </TableRow>
          </TableHead>
          {loading && <TableSkeleton columnCount={6}></TableSkeleton>}
          <TableBody sx={{ width: '100%' }}>
            {notifications.map((n) => {
              const notificationColor =
                n.type.toLowerCase() === 'error'
                  ? '#C92A2A'
                  : n.type.toLowerCase() === 'warning'
                  ? '#E9A100'
                  : n.type.toLowerCase() === 'fault'
                  ? '#b95000'
                  : n.type.toLowerCase() === 'info'
                  ? '#37B24D'
                  : n.type.toLowerCase() === 'online'
                  ? '#37B24D'
                  : n.type.toLowerCase() === 'idle'
                  ? '#084F9B'
                  : n.type.toLowerCase() === 'offline'
                  ? '#6A7178'
                  : '#6A7178'

              return (
                <TableRow
                  component={Link}
                  to={n.url ?? ''}
                  onClick={() => {
                    if (n.url) {
                      getSocket()?.emit('notification:read', n.id)
                      console.log('read')
                    }
                  }}
                  key={n.id}
                  sx={{
                    cursor: n.url ? 'pointer' : 'default',
                    bgcolor: n.isRead ? 'transparent' : 'rgba(0,0,0,0.05)',
                    '&:hover': {
                      backgroundColor: n.url ? 'rgba(0,0,0,0.05)' : undefined,
                    },
                  }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={selectedNotifications.includes(n.id ?? -1)}
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                      onChange={(event, checked) => {
                        const index = selectedNotifications.indexOf(n.id)
                        if (index == -1) {
                          setSelectedNotifications((prev) => [...prev, n.id])
                        } else {
                          setSelectedNotifications((prev) =>
                            prev.filter((e) => e != n.id),
                          )
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Box
                      component={'span'}
                      sx={{
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: notificationColor,
                        marginRight: '10px',
                        // position: 'absolute',
                        // left: '8px',
                        // top: '23%',
                        // transform: 'translateY(-50%)',
                      }}
                    ></Box>
                    {n.title}
                  </TableCell>
                  <TableCell>{n.content}</TableCell>
                  <TableCell>{moment(n.date).fromNow()}</TableCell>
                  {/* <TableCell>
                  {n.isRead ? (
                    <CheckRounded color="action"></CheckRounded>
                  ) : (
                    <></>
                  )}
                </TableCell> */}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        currentPage={page}
        limit={limit}
        total={totalPage}
        totalItems={(page - 1) * limit + notifications.length}
        onPageChange={(page, limit) =>
          setParams({ limit: limit.toString(), page: page.toString() })
        }
      ></TablePagination>
    </NotificationsListStyles>
  )
}

const NotificationsListStyles = styled.div`
  width: 100%;

  th {
    padding: 12px 15px;
  }

  td {
    padding: 13px 15px;
  }
`

export default MyNotificationPage
