import { FC } from 'react'

import { Box, Typography } from '@mui/material'
import moment from 'moment'
import { Link, redirect } from 'react-router-dom'

import { getSocket } from 'src/helpers/socketManager'
import { routes } from 'src/router'

import { Notification } from './entities/notification'

interface NotificationTileProps {
  key: string | number | undefined
  notification: Notification
}

export const NotificationTile: FC<NotificationTileProps> = ({
  notification,
}) => {
  const notificationColor =
    notification.type.toLowerCase() === 'error'
      ? '#C92A2A'
      : notification.type.toLowerCase() === 'warning'
      ? '#E9A100'
      : notification.type.toLowerCase() === 'fault'
      ? '#b95000'
      : notification.type.toLowerCase() === 'info'
      ? '#37B24D'
      : notification.type.toLowerCase() === 'online'
      ? '#37B24D'
      : notification.type.toLowerCase() === 'idle'
      ? '#084F9B'
      : notification.type.toLowerCase() === 'offline'
      ? '#6A7178'
      : '#6A7178'

  console.log(notification.isRead)

  return (
    <Box
      component={'li'}
      key={notification.id}
      sx={{
        position: 'relative',
        backgroundColor: notification.isRead ? 'white' : 'rgba(0, 0, 0, 0.035)',
        // backgroundColor: notification.isRead
        //   ? openedNotificationColor
        //   : notificationColor,
        // filter: 'grayscale(35%)',
        // '&:hover': {
        //   backgroundColor: hoveredNotificationColor,
        // },
      }}
      onClick={(e) => {
        console.log('clicked', notification.id)

        getSocket()!.emit('notification:read', notification.id)
        // redirect(routes.myNotification)
      }}
    >
      <Link
        to={routes.myNotification}
        style={{
          width: '100%',
          padding: '10px 24px',
          height: '100%',
        }}
      >
        <Box
          sx={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: notificationColor,
            position: 'absolute',
            left: '8px',
            top: '23%',
            // transform: 'translateY(-50%)',
          }}
        ></Box>
        <Box>
          <Typography noWrap className="title">
            {notification.title}
          </Typography>
          <Typography noWrap className="text">
            {' '}
            {notification.content}{' '}
          </Typography>
          <Box position="absolute" right="8px" top="15%" fontSize="11px">
            {moment(notification.date).fromNow()}
          </Box>
        </Box>
      </Link>
    </Box>
  )
}
