import { FC } from 'react'

import { KeyboardArrowUp } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import SVG from 'react-inlinesvg'

import { IconBuildingFactory } from 'src/assets/icons'

import { IFactory } from '../../types'

interface FactoryItemProps {
  data: IFactory
  onClick: (item: IFactory) => void
}

export const FactoryItem: FC<FactoryItemProps> = ({ data, onClick }) => {
  const { name, lines, address } = data
  const handleClick = (): void => {
    onClick(data)
  }

  return (
    <Box
      sx={{
        width: '100%',
        p: '24px',
        mb: '16px',
        bgcolor: '#FFF',
        boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        '&:last-child': {
          mb: 0,
        },
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          mb: '8px',
          '&>svg:first-of-type path': {
            stroke: '#272B30',
          },
        }}
      >
        <SVG
          src={IconBuildingFactory}
          width={22}
          height={22}
          style={{
            display: 'inline-block',
          }}
        />
        <Typography
          sx={{
            fontSize: '22px',
            lineHeight: '22px',
            fontWeight: 700,
            mt: '4px',
            ml: '8px',
            color: '#272B30',
            flex: 1,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: '75%',
          }}
        >
          {name}
        </Typography>
        <Box
          onClick={handleClick}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '32px',
            height: '32px',
            borderRadius: '100px',
            '&:hover': {
              boxShadow: 'inset 0px 250px 0px rgba(16, 18, 19, 0.1)',
            },
          }}
        >
          <IconButton
            size="small"
            onClick={handleClick}
            sx={{
              transform: 'rotate(90deg)',
              '&:hover': {
                bgcolor: 'inherit',
              },
            }}
          >
            <KeyboardArrowUp color="action" />
          </IconButton>
        </Box>
      </Box>
      <Typography
        variant="body2"
        sx={{
          color: '#ADB5BD',
        }}
      >
        {lines.length} Production Lines
      </Typography>
      <Typography
        variant="body2"
        sx={{
          mt: '16px',
        }}
      >
        {address}
      </Typography>
    </Box>
  )
}
