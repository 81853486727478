import React, { FC, useState } from 'react'

import { Box, Typography } from '@mui/material'
import SVG from 'react-inlinesvg'
import { toast } from 'react-toastify'

import { IconChevronDown, IconCross } from 'src/assets/icons'
import { PageHeading } from 'src/components/PageHeading'
import {
  format2DateToLocal,
  formatDateToLocal,
} from 'src/helpers/timestamp-generator'
import { CustomButton } from 'src/ui/Button'
import { Input } from 'src/ui/Input'

import SignalPreview from './SignalPreview'

const ReviewData: FC<{
  onBackPress: () => void
  onGenerateReport: (reportData: any) => void
  bodymakers: any
  lines: any
  selectedDates: any
}> = ({ onBackPress, bodymakers, lines, onGenerateReport, selectedDates }) => {
  console.log(selectedDates)

  // const [nameInputValue, setNameInputValue] = useState<string>('')
  return (
    <Box
      sx={{
        width: '100%',
        pb: '240px',
      }}
    >
      <PageHeading
        title="Review Data"
        subtitle="The report will consist the data listed below."
      />
      <Box
        sx={{
          mt: '48px',
          mb: '24px',
        }}
      >
        <CustomButton
          styles={{
            marginRight: '16px',
            '& > div': {
              marginRight: 0,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          }}
          appearance="clear-white-default"
          onClick={onBackPress}
        >
          <SVG
            src={IconChevronDown}
            style={{
              fill: '#1068EB',
              //   marginRight: '8px',
              marginBottom: '0px',
              rotate: '90deg',
            }}
          />
          Back
        </CustomButton>
        <CustomButton
          styles={{
            '& > div': {
              marginRight: 0,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          }}
          appearance="primary-soft-default"
          onClick={() => {
            // if (nameInputValue === '') {
            //   toast.error('Please enter a name for the report')
            //   return
            // }
            onGenerateReport({
              // name: nameInputValue,
              bodymakers,
              lines,
            })
          }}
        >
          Generate Report
          <SVG
            src={IconChevronDown}
            style={{
              fill: '#fff',
              marginLeft: '8px',
              marginBottom: '0px',
              rotate: '270deg',
            }}
          />
        </CustomButton>
      </Box>

      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#303D60',
            p: '12px 16px',
            borderRadius: '4px 4px 0px 0px',
          }}
        >
          <Typography
            sx={{
              fontSize: '22px',
              fontWeight: 500,
              color: '#fff',
            }}
          >
            Report Review
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: '#fff',
            p: '36px 48px 64px',
            borderRadius: '0px 0px 4px 4px',
          }}
        >
          {/* <Box
            sx={{
              mb: '36px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
              }}
            >
              <Box
                sx={{
                  width: '364px',
                  backgroundColor: '#fff',
                }}
              >
                <Input
                  onChange={(e: any) => {
                    // console.log(e.target.value)
                    setNameInputValue(e.target.value)
                  }}
                  value={nameInputValue}
                  placeholder="Name"
                  styles={{
                    height: '40px',
                    paddingLeft: '12px',
                  }}
                />
              </Box>
              <CustomButton
                styles={{
                  '& > div': {
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '16px',
                  },
                }}
                onClick={() => {
                  if (nameInputValue === '') {
                    toast.error('Please enter a name for the report')
                  }
                }}
                appearance={'primary-soft-default'}
              >
                Remember Configuration
                <Box
                  component={SVG}
                  sx={{
                    marginLeft: '8px',
                    rotate: '45deg',
                    width: '20px',
                    height: '20px',
                    '& path': {
                      stroke: '#fff',
                      strokeWidth: '2px',
                    },
                  }}
                  src={IconCross}
                />
              </CustomButton>
            </Box>

            <Typography
              sx={{
                fontSize: '12px',
                color: '#6A7178',
                width: '336px',
                paddingLeft: '12px',
                mt: '8px',
              }}
            >
              * By pressing “Remember Configuration” you can save this report to
              run again with different parameters
            </Typography>
          </Box> */}

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: '48px',
            }}
          >
            <Typography
              fontWeight={700}
              fontSize={18}
              color="#101213"
              marginRight="24px"
            >
              Date
            </Typography>
            <Box
              sx={{
                display: 'flex',
                p: '8px 16px',
                backgroundColor: '#E9ECEF',
                borderRadius: '4px',
                width: 'fit-content',
                gap: '24px',
                alignItems: 'center',
              }}
            >
              <Typography fontWeight={700} color="#4F575E">
                {formatDateToLocal(new Date(selectedDates.startDate))}
              </Typography>
              <Box
                sx={{
                  width: '12px',
                  height: '1px',
                  borderRadius: '2px',
                  backgroundColor: '#4F575E',
                }}
              />
              <Typography fontWeight={700} color="#4F575E">
                {formatDateToLocal(new Date(selectedDates.endDate))}
              </Typography>
            </Box>

            <Typography
              fontWeight={700}
              fontSize={18}
              color="#101213"
              marginRight="24px"
              marginLeft="64px"
            >
              Report History
            </Typography>
            <Box
              sx={{
                display: 'flex',
                p: '8px 16px',
                backgroundColor: '#E9ECEF',
                borderRadius: '4px',
                width: 'fit-content',
                alignItems: 'center',
              }}
            >
              <Typography fontWeight={700} color="#4F575E">
                {format2DateToLocal(new Date())}
              </Typography>
            </Box>
          </Box>

          <SignalPreview
            selectedSignals={lines.map((line: any) => {
              return {
                ...line,
                bodymakers: bodymakers.filter(
                  (bodymaker: any) => bodymaker.lineId === line.id,
                ),
              }
            })}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default ReviewData
