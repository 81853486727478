import React, { FC, useEffect, useState } from 'react'

import { Box, Typography } from '@mui/material'

import AnimatedLineChart from 'src/ui/MultiLineChart/AnimatedLineChart'

import ChartTitle from './MachineDetails/ChartTitle'

export interface MachineChartProps {
  isLoading?: boolean
  chartData: ChartData[]
  chartWidth: number
  title: string
  chartAvg?: string
  newData?: ChartData[]
  highThreshold: number
  lowThreshold: number
  unit?: string
  isLive?: boolean
}

export interface ChartData {
  key: string
  light: string
  dark: string
  data: Array<{ date: Date; value: number }>
}

const MachineChart: FC<MachineChartProps> = ({
  chartData,
  chartWidth,
  title,
  newData,
  highThreshold,
  chartAvg,
  lowThreshold,
  isLoading,
  unit,
  isLive = false,
}) => {
  // const [min, setMin] = useState<number | undefined>()
  // const [max, setMax] = useState<number | undefined>()
  const [data, setData] = useState<ChartData[]>([])
  // console.log(data, 'dataaa')

  useEffect(() => {
    if (!chartData[1]?.data && !isLoading) return
    // console.log('chartData', chartData[1]?.data)
    setData(chartData)
    // const sortedData = Array.from(chartData[1].data)?.sort(
    //   (a, b) => a.value - b.value,
    // )
    // const chartMin = sortedData?.[0]?.value * 0.99
    // const chartMax = sortedData?.[sortedData.length - 1]?.value * 1.01
    // // const chartMin =
    // //   chartData[2]?.data?.reduce(
    // //     (min, p) => (p.value != null && p.value < min ? p.value : min),
    // //     999999999, // this value to ensure the min is never the start value
    // //   ) * 0.99
    // // const chartMax =
    // //   chartData[2].data?.reduce(
    // //     (max, p) => (p.value != null && p.value > max ? p.value : max),
    // //     -9999999999, // this value to ensure the max is never the start value
    // // ) * 1.01

    // if (title === 'Overtravel') {
    //   setMin(Number(chartMin?.toFixed(4)))
    //   setMax(Number(chartMax?.toFixed(4)))
    // } else {
    //   setMin(Math.trunc(chartMin))
    //   setMax(Math.trunc(chartMax))
    // }
  }, [title, chartData])

  return (
    <Box
      sx={{
        mt: '36px',
        backgroundColor: 'white',
        borderRadius: '8px',
        padding: '24px',
      }}
    >
      {
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- false positive
        ((data && data?.[1]?.data?.length > 0 && !isLive) ||
          (newData && newData[1]?.data?.length > 0)) &&
          !isLoading && (
            <ChartTitle
              isLive={isLive}
              chartAvg={chartAvg}
              title={title}
              data={chartData}
              newData={newData?.[1] ? newData : undefined}
              unit={unit}
            />
          )
      }

      {chartWidth &&
        !isLoading &&
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        ((data && data?.[1]?.data?.length > 0 && !isLive) ||
          (newData && newData[1]?.data?.length > 0)) && (
          <AnimatedLineChart
            isLive={isLive}
            data={data}
            newData={newData}
            width={chartWidth}
            height={513}
            alerts={{
              min: Number(lowThreshold),
              max: Number(highThreshold),
            }}
            margins={{
              top: 20,
              right: 100,
              bottom: 50,
              left: 20,
            }}
          />
        )}
      {isLoading && (
        <Box>
          <Typography>Loading...</Typography>
        </Box>
      )}

      {chartData.length < 1 && !isLive && !isLoading && (
        <Box>
          <Typography>No Data</Typography>
        </Box>
      )}

      {isLive && newData && newData[1]?.data?.length === 0 && !isLoading && (
        <Box>
          <Typography>No Data</Typography>
        </Box>
      )}

      {newData && newData.length < 2 && !isLoading && isLive && (
        <Box>
          <Typography>Fetching live stream...</Typography>
        </Box>
      )}
    </Box>
  )
}

export default MachineChart
