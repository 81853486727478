import { ActionFunction, LoaderFunction, redirect } from 'react-router-dom'

import { routes } from 'src/router'

export const loginPageAction: ActionFunction = () => {
  localStorage.setItem('isLogged', 'true')

  return redirect(routes.fleetView)
}

export const loginPageLoader: LoaderFunction = ({ request }) => {
  const url = new URL(request.url)
  const pathname = url.pathname
  // replace this with state management isAuthenticated check
  if (localStorage.getItem('isLogged') === 'true' && pathname === routes.login)
    return redirect(routes.fleetView)
  return ''
}
