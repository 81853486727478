import React from 'react'

import CssBaseline from '@mui/material/CssBaseline'
import axios from 'axios'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'

import CustomThemeProvider from 'src/CustomThemeProvider'
import { router } from 'src/router'

import './index.css'
import attachTokenToHeaders from './interceptors/authenticate'
import { store } from './store/store'

axios.defaults.baseURL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_BASE_URL_PROD
    : process.env.REACT_APP_API_BASE_URL

attachTokenToHeaders()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <CustomThemeProvider>
      <CssBaseline />
      <RouterProvider router={router} />
    </CustomThemeProvider>
  </Provider>,
)
