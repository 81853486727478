import { FC, useState } from 'react'
import { Box, Typography } from '@mui/material'
import SVG from 'react-inlinesvg'
import { IconChevronDown } from 'src/assets/icons'

interface SideBarButtonProps {
  onClick?: () => void
  selected?: boolean
  itemName: string
  itemIcon: string
  isExpandable?: boolean
  isLast?: boolean
  isFirst?: boolean
  styles?: any
  indentation?: 'small' | 'large' // Specify the indentation value
}

const SideBarButton: FC<SideBarButtonProps> = ({
  onClick,
  selected: selectedProp,
  itemName,
  itemIcon,
  isExpandable = true,
  isLast,
  isFirst,
  styles,
  indentation = 'small', // Default indentation is 'small'
}) => {
  const [selected, setSelected] = useState(!!selectedProp)

  const background = selected ? 'transparent' : '#F4F6FA'
  const textColor = selected ? '#101213' : '#4F575E'
  const iconColor = selected ? '#1068EB' : '#4F575E'
  const border = selected ? `1px solid #1068EB` : '1px solid transparent'

  // Define the indentation values
  const indentations = {
    small: '16px',
    large: '32px',
  }

  return (
    <Box
      onClick={() => {
        isExpandable && setSelected(!selected)
        onClick && onClick()
      }}
      component="button"
      sx={{
        boxShadow: selected
          ? 'inset 0px 250px 0px rgba(16, 104, 235, 0.1)'
          : 'none',
        background,
        width: `calc(100% - ${indentations[indentation]})`,
        borderBottomLeftRadius: isLast
          ? '4px'
          : !isLast && selected
          ? '4px'
          : 0,
        borderBottomRightRadius: isLast
          ? '4px'
          : !isLast && selected
          ? '4px'
          : 0,
        borderTopLeftRadius: isFirst ? '4px' : !isFirst && selected ? '4px' : 0,
        borderTopRightRadius: isFirst
          ? '4px'
          : !isFirst && selected
          ? '4px'
          : 0,
        padding: '9px 16px',
        marginLeft: indentations[indentation], // Set the indentation based on the value
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        border,
        '&:hover': {
          bgcolor: '#E9ECEF',
          color: '#4F575E',
        },
        ...styles,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <SVG
          src={itemIcon}
          style={{
            flexShrink: 0,
            width: '24px',
            height: '24px',
          }}
        />
        <Typography
          noWrap
          variant="body2"
          sx={{
            ml: '8px',
            color: textColor,
            fontWeight: 500,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {itemName}
        </Typography>
      </Box>
      {isExpandable && (
        <SVG
          src={IconChevronDown}
          style={{
            flexShrink: 0,
            fontWeight: 300,
            fill: iconColor,
            stroke: 'transparent',
            rotate: selected ? '180deg' : '0deg',
          }}
        />
      )}
    </Box>
  )
}

export default SideBarButton
