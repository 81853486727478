import React, { FC } from 'react'

import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'

import Accordion from '../../../components/Accordion/Accordion'
import AccordionDetails from '../../../components/Accordion/AccordionDetails'
import AccordionSummary from '../../../components/Accordion/AccordionSummary'

const faqData = [
  {
    id: 0,
    question:
      'How is the Synapse Bottom Former different than a Pride Hybrid Bottom Former?',
    answer:
      'The Synapse Bottom Former utilizes the same mechanics as a Hybrid Bottom Former while also including sensors to measure overtravel distance and Clamp Ring and Dome Setting Forces. It also has the capability of adjusting how much setting force is exerted on the can; increasing the process window and reducing setup time and variability.',
  },
  {
    id: 1,
    question: 'What is the force range of the Synapse Domer?',
    answer:
      'The Synapse Bottom Former is capable of creating dome setting forces from 2000 lbf to 8500 lbf.',
  },
  {
    id: 2,
    question: 'What is the force range of the Synapse Bottom Former?',
    answer:
      'The Synapse Bottom Former is capable of creating dome setting forces from 2000 lbf to 8500 lbf.',
  },
  {
    id: 3,
    question: 'What should my ideal overtravel be?',
    answer:
      'The target overtravel during setup is 0.010”, with a working range of 0.005” to 0.030”.',
  },
  {
    id: 4,
    question: 'Do I need an internet connection for Synapse?',
    answer:
      'In order to utilize the entire suite of Synapse capabilities, it is strongly recommended that each device have an internet connection. In the event of a power failure of network interruption, the Synapse unit will continue to function without noticeable changes.',
  },
  {
    id: 5,
    question: 'Can Synapse be retrofitted to my existing domer?',
    answer:
      'Yes. The Syanpse Force package was designed to quickly and easily replace any exiting Pride Hybrid bottom former (200 or 300 series). No hardware modifications are required.',
  },
]

const Faq: FC = () => {
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  return (
    <Box sx={{ mt: '2.25rem' }}>
      {faqData.map((item) => (
        <Accordion
          key={item.id}
          expanded={expanded === `panel${item.id}`}
          onChange={handleChange(`panel${item.id}`)}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>{item.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{item.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  )
}

export default Faq
