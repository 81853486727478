import { FC, SetStateAction } from 'react'

import { Box } from '@mui/material'

import { Navigation } from './components/Navigation'
import { UserControl } from './components/UserControl'

interface HeaderProps {
  isAsideVisible: boolean
  setIsAsideVisible: (value: SetStateAction<boolean>) => void
}

export const Header: FC<HeaderProps> = ({
  isAsideVisible,
  setIsAsideVisible,
}) => {
  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
    >
      <Navigation />

      <UserControl />
    </Box>
  )
}
