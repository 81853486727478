import { FC, memo, useEffect, useState } from 'react'

import { Box } from '@mui/system'
import { NavLink, useLocation } from 'react-router-dom'

import { routes } from 'src/router'
import customTheme from 'src/themes/themeLight'
import { useTheme } from '@mui/material'

type IOrderedNavLinks = {
  [key in keyof typeof routes]?: string
}

export const Navigation: FC = memo(() => {
  const match = useLocation()
  const theme = useTheme<typeof customTheme>()
  // get active user role admin or user
  const [orderedNavLinks, setOrderedNavLinks] = useState<IOrderedNavLinks>({
    fleetView: 'Home',
    reports: 'Reports',
    // management: 'Management',
    activeLearning: 'Active Learning',
  })
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') ?? '{}')
    if (
      user.role === 'globalAdmin' ||
      user.role === 'plantSupervisor' ||
      user.role === 'corporateAdmin'
    ) {
      setOrderedNavLinks({
        fleetView: 'Home',
        reports: 'Reports',
        userManagement: 'User Management',
        activeLearning: 'Active Learning',
        billing: 'Billing',
      })
    } else {
      setOrderedNavLinks({
        fleetView: 'Home',
        reports: 'Reports',
        // management: 'Management',
        activeLearning: 'Active Learning',
      })
    }
  }, [])

  const handleActive = (link: any): boolean => {
    return match.pathname === link
  }
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      {(Object.keys(orderedNavLinks) as Array<keyof typeof routes>).map(
        (link, idx) => (
          <NavLink
            key={`${link}+${idx}`}
            to={routes[link]}
            style={({ isActive }) => {
              return {
                overflow: 'hidden',
                lineHeight: '22px',
                fontSize: '14px',
                fontWeight: 500,
                marginRight: '16px',
                borderRadius: '4px',
                whiteSpace: 'nowrap',
                color: handleActive(routes[link])
                  ? theme.palette.static.main
                  : theme.palette.action.main,
                boxShadow: isActive
                  ? 'none'
                  : '0px 8px 10px rgba(0, 0, 0, 0.1)',
              }
            }}
          >
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: handleActive(routes[link])
                  ? theme.palette.action.main
                  : theme.palette.static.main,
                padding: '9px 16px',
                '&:hover': {
                  backgroundColor: handleActive(routes[link])
                    ? theme.palette.action.main
                    : 'rgba(16, 104, 235, 0.1)',
                },
              }}
            >
              {orderedNavLinks[link]}
            </Box>
          </NavLink>
        ),
      )}
    </Box>
  )
})
