import { subDays, subHours } from 'date-fns'

export const timestampButtons = [
  {
    label: '1H',
    value: '1H',
    getTimestamp: () => ({
      startDate: subHours(new Date(), 1),
      endDate: new Date(),
    }),
  },
  {
    label: '12H',
    value: '12H',
    getTimestamp: () => ({
      startDate: subHours(new Date(), 12),
      endDate: new Date(),
    }),
  },
  {
    label: '1D',
    value: '1D',
    getTimestamp: () => ({
      startDate: subDays(new Date(), 1),
      endDate: new Date(),
    }),
  },
  {
    label: '5D',
    value: '5D',
    getTimestamp: () => ({
      startDate: subDays(new Date(), 5),
      endDate: new Date(),
    }),
  },
]
