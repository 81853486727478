import { Alert, Snackbar as MuiSnackbar } from '@mui/material'
import React, { FC } from 'react'
import ReactDOM from 'react-dom'

interface SnackbarProps {
  message: string
  type: 'success' | 'error' | 'warning' | 'info'
  open: boolean
  onClose: () => void
}

const Snackbar: FC<SnackbarProps> = ({ open, message, type, onClose }) => {
  const portalRoot = document.getElementById('root')

  if (!portalRoot) {
    return null // Render nothing if the portal root element is not found
  }

  return ReactDOM.createPortal(
    <MuiSnackbar
      open={open}
      autoHideDuration={3000}
      sx={{ position: 'fixed' }}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert severity={type}>{message}</Alert>
    </MuiSnackbar>,
    portalRoot,
  )
}

export default Snackbar
