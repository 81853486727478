import { ComponentType, ReactElement } from 'react'

import { Typography, Box, SxProps, Theme } from '@mui/material'
import { Controller, FieldValues, Path, UseFormReturn } from 'react-hook-form'

import { IOption } from 'src/ui/Select'

interface FormFieldProps<T extends FieldValues> {
  label?: string
  name: Path<T>
  component: ComponentType<any>
  form: UseFormReturn<T, any>
  options?: IOption[]
  styles?: SxProps<Theme> | undefined
  containerStyles?: SxProps<Theme> | undefined
  [x: string]: any
  handleSelect?: any
  displayName?: string
  // value?: any
}

export const FormField = <TFields extends {}>(
  props: FormFieldProps<TFields>,
): ReactElement => {
  const {
    form,
    label,
    name,
    // value,
    component: Component,
    containerStyles,
    handleSelect,
    displayName,
    ...rest
  } = props
  const {
    control,
    formState: { errors },
  } = form

  const error = errors[name]?.message as string
  // console.log(value, 'value in form field')

  return (
    <Box
      sx={{
        position: 'relative',
        ...containerStyles,
      }}
    >
      {displayName === 'ReactSelect' ||
      displayName === 'ReactDatePicker' ||
      displayName === 'ReactTimePicker' ||
      displayName === 'ReactCheckbox' ? (
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, onBlur, name, ref, value } }) => {
            const handleChange = (event?: any): any => {
              switch (displayName) {
                case 'ReactSelect':
                  return handleSelect(event.target.value)
                case 'ReactCheckbox':
                  return onChange(event.target.checked)
                default:
                  // fix when we need to use datepicker function needs to get called
                  return (date: Date) => {
                    onChange(date)
                  }
              }
            }
            return (
              <Component
                id={name}
                value={value}
                ref={ref}
                checked={value as boolean}
                name={name}
                onBlur={onBlur}
                onChange={(event: any) => {
                  handleChange(event)
                }}
                isinvalid={error ? !!error : undefined}
                {...rest}
              />
            )
          }}
        />
      ) : (
        <Component
          {...(control.register(name), { required: true })}
          {...rest}
          label={label}
        />
      )}
      <Typography
        sx={{
          position: 'absolute',
          bottom: '-12px',
          color: '#DC2020',
          fontSize: '10px',
        }}
      >
        {error}
      </Typography>
    </Box>
  )
}
