import React, { FC } from 'react'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Typography } from '@mui/material'

const ReportsAlert: FC<{
  closing: boolean
  onClick: () => void
}> = ({ closing, onClick }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#1068EB0D',
        width: '100%',
        height: closing ? 0 : '102px',
        borderRadius: '0px 0px 8px 8px',
        padding: closing ? '0 36px' : '16px 36px',
        display: 'flex',
        justifyContent: 'space-between',
        transition: 'all 0.3s ease-in-out',
      }}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Box>
          <InfoOutlinedIcon sx={{ color: '#1068EB' }} />
        </Box>
        <Box
          sx={{
            padding: '2px 14px',
          }}
        >
          <Typography
            sx={{
              color: '#000',
              fontSize: '16px',
              fontWeight: 500,
            }}
          >
            &apos;Generate Report&apos;
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
            }}
          >
            Pressing &apos;Generate Report&apos; will begin the process of
            preparing the report for you. It will process the data in the
            background and email you when your report is ready. Feel free to
            continue browsing as the report is being processed.
          </Typography>
        </Box>
      </Box>
      <Box
        component={'button'}
        sx={{
          background: 'transparent',
          cursor: 'pointer',
          height: 'fit-content',
        }}
        onClick={onClick}
      >
        <CloseOutlinedIcon />
      </Box>
    </Box>
  )
}

export default ReportsAlert
