function convertTimestampToLocal(isoTimestamp?: string): string {
  if (!isoTimestamp) {
    return ''
  }
  const date = new Date(isoTimestamp)

  const datePart = date.toLocaleDateString().replace(/\//g, '/')
  const timePart = date.toLocaleTimeString('en-US', {
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  })

  const formattedTimestamp = `${timePart} ${datePart}`
  return formattedTimestamp
}

export const formatDateToLocal = (date: Date): string => {
  const myDate = new Date(date)

  if (
    isNaN(myDate.getTime()) ||
    myDate.getFullYear() < 1970 ||
    myDate.getFullYear() > 9999
  ) {
    return ''
  }

  console.log(myDate)

  const options: Intl.DateTimeFormatOptions = {
    hour12: true,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeZoneName: 'short',
  }

  const formattedDate = myDate.toLocaleString('en-US', options)

  const [rest, time] = formattedDate.split(', ')

  const dateParts = rest.split('/')
  const [month, day, year] = dateParts

  return `${day}.${month}.${year}`
}

export const format2DateToLocal = (date: Date): string => {
  const options = {
    hour12: true,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeZoneName: 'short',
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }
  // 05/09/23, 09:30 PM
  // split this to be Expored 31/12/22 at 12:32 PM ET
  const formattedDate = date.toLocaleString('en-US', options as any)
  const [month, day, year, time] = formattedDate.split(/\/|, /)

  const formattedDateStr = `${day}/${month}/${year}`

  return `Exported ${formattedDateStr} at ${time}`
}

export default convertTimestampToLocal

export const formatDateShort = (dateStr: string | null): string => {
  if (!dateStr) {
    return 'Never'
  } else {
    const date = new Date(dateStr)
    const options = { day: '2-digit', month: 'short', year: 'numeric' }
    return date.toLocaleDateString('en-US', options as any)
  }
}

export function formatDateTime(unixDate: number): string {
  const date = new Date(unixDate)
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
  const year = String(date.getFullYear())
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`
}
