import axios from 'axios'
import { toast } from 'react-toastify'

let toastLaunched = false
const authRoutes: string[] = ['/login', '/reset-password', '/forgot-password']

const attachTokenToHeaders = (): boolean => {
  // For GET requests
  axios.interceptors.request.use(
    (req) => {
      toast.dismiss()
      const user = localStorage.getItem('user')

      const isLoginRoute = authRoutes.includes(window.location.pathname)
      console.log(window.location.pathname)

      if (!isLoginRoute && !user) {
        throw new axios.Cancel('Reauthorization required') as Error
      }

      if (user) {
        const token: string = JSON.parse(user).token
        token && (req.headers.Authorization = `Bearer ${token}`)
      }

      return req
    },
    async (err) => {
      console.log(err)
      toast.dismiss()
      // Handle errors here
      if (axios.isCancel(err)) {
        // Redirect the user to the login page
        localStorage.setItem('redirectError', 'Reauthorization required')
        window.location.href = '/login'
      } else {
        // Show a toast with the error message
        if (!toastLaunched) {
          toastLaunched = true
          if (err.response && err.response.data.message) {
            toast.error(err.response.data.message)
          } else {
            toast.error(err.message)
          }
          toastLaunched = false
        }
      }
      return err
    },
  )

  // For POST requests
  axios.interceptors.response.use(
    (res) => {
      toast.dismiss()
      // Add configurations here
      const user = localStorage.getItem('user')

      const isLoginRoute = authRoutes.includes(window.location.pathname)

      if (!isLoginRoute && !user) {
        throw new axios.Cancel('Reauthorization required') as Error
      }

      if (user) {
        const token: string = JSON.parse(user).token
        token && (res.headers.Authorization = `Bearer ${token}`)
      }

      return res
    },
    (err) => {
      // Handle errors here
      toast.dismiss()
      if (axios.isCancel(err)) {
        // Redirect the user to the login page
        // localStorage.setItem('redirectError', 'Reauthorization required')
        window.location.href = '/login'
      } else {
        // Show a toast with the error message
        if (!toastLaunched) {
          toastLaunched = true
          if (err.response && err.response.data.message) {
            toast.error(err.response.data.message)
          } else {
            toast.error(err.message)
          }
          toastLaunched = false
        }
      }
      return Promise.reject(err)
    },
  )

  return true
}

export default attachTokenToHeaders
/*
 import axios from 'axios'
import { toast } from 'react-toastify'

let toastLaunched = false
let numFailedRequests = 0

// Add a response interceptor to handle errors globally for all requests
axios.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling function
    return response
  },
  (error) => {
    // Increment the number of failed requests
    numFailedRequests++

    // Handle errors here
    if (axios.isCancel(error)) {
      // Redirect the user to the login page
      localStorage.setItem('redirectError', 'Reauthorization required')
      window.location.href = '/login'
    }

    // Return a rejected promise to the calling function
    return Promise.reject(error)
  }
)

const attachTokenToHeaders = (): boolean => {
  // For GET requests
  axios.interceptors.request.use((req) => {
    const user = localStorage.getItem('user')

    const isLoginRoute = window.location.pathname === '/login'

    if (!isLoginRoute && !user) {
      throw new axios.Cancel('Reauthorization required') as Error
    }

    if (user) {
      const token: string = JSON.parse(user).token
      token && (req.headers.Authorization = `Bearer ${token}`)
    }

    return req
  })

  // For POST requests
  axios.interceptors.response.use((res) => {
    // Add configurations here
    const user = localStorage.getItem('user')

    const isLoginRoute = window.location.pathname === '/login'

    if (!isLoginRoute && !user) {
      throw new axios.Cancel('Reauthorization required') as Error
    }

    if (user) {
      const token: string = JSON.parse(user).token
      token && (res.headers.Authorization = `Bearer ${token}`)
    }

    // Decrement the number of failed requests
    numFailedRequests--

    // If all requests have finished and there are failed requests, launch the toast
    if (numFailedRequests === 0 && !toastLaunched) {
      toastLaunched = true
      toast.error('Failed to fetch data. Please check your internet connection and try again.')
      toastLaunched = false
    }

    return res
  })

  return true
}

export default attachTokenToHeaders

 */
