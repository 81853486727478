import { useState, useEffect, ReactElement } from "react";

import {
  ThemeProvider, createTheme, responsiveFontSizes
} from "@mui/material/styles";
import { useSelector } from "react-redux";

import { darkMode } from "src/store/reducers/portalReducer";
import { ThemeDark, ThemeLight } from "src/themes";

const CustomThemeProvider = (props: any): ReactElement => {
  const { children } = props;

  const darkModeSel = useSelector(darkMode);

  const [themeChoice, setThemeChoice] = useState<object>(ThemeLight);

  useEffect(() => {
    setThemeChoice(darkModeSel ? ThemeDark : ThemeLight);
    // eslint-disable-next-line
  }, [darkModeSel]);

  return (
    <ThemeProvider theme={responsiveFontSizes(createTheme(themeChoice))}>
      {children}
    </ThemeProvider>
  );
};

export default CustomThemeProvider;
