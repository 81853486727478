import React, { FC, useState } from 'react'

import { Box, Typography } from '@mui/material'
import { LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import SVG from 'react-inlinesvg'

import { IconMap, IconTable, IconCalendar } from 'src/assets/icons'
import {
  getHoursWith30MinuteIncrements,
  getTimeZoneInfo,
} from 'src/helpers/date-generator'
import { Select } from 'src/ui/Select'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

dayjs.extend(utc)

const OnDemandReport: FC<{
  onDatesSelection: (startDate?: Date | Dayjs, endData?: Date | Dayjs) => void
  // onTimeframeSelection: (startDate?: Dayjs, endData?: Dayjs) => void
  onTimezoneSelection: (timezone: string, timezoneString: string) => void
  // onTimeFormatSelection: (timeFormat: string) => void
  defaults?: {
    reportTimezone?: string
    // timeFormat?: string
    dates: { startDate?: Dayjs; endDate?: Dayjs }
    reportTimeframe?: string
    reportTime?: string
    reportFrequency?: string
    // timeframeStartDate?: Dayjs
    // timeframeEndDate?: Dayjs
  }
  isRecurring?: boolean
  onFrequencyChange?: (value: any) => void
  onTimeChange?: (value: any) => void
}> = ({
  onFrequencyChange,
  onTimeChange,
  onDatesSelection,
  // onTimeframeSelection,
  onTimezoneSelection,
  // onTimeFormatSelection,
  defaults,
  isRecurring,
}) => {
  const [from, setFrom] = useState<Date | Dayjs | null>(
    dayjs.utc(defaults?.dates?.startDate) ?? null,
  )
  const [until, setUntil] = useState<Date | Dayjs | null>(
    dayjs.utc(defaults?.dates?.endDate) ?? null,
  )
  console.log(defaults)

  const [openFrom, setOpenFrom] = useState<boolean>(false)
  const [openUntil, setOpenUntil] = useState<boolean>(false)
  const timezoneStrings = getTimeZoneInfo()
  const hours = getHoursWith30MinuteIncrements().map((hour) => ({
    value: hour,
    label: hour,
  }))

  // const [timeframeFrom, setTimeframeFrom] = useState<Dayjs | null>(
  //   defaults?.timeframeStartDate ?? null,
  // )
  // const [timeframeUntil, setTimeframeUntil] = useState<Dayjs | null>(
  //   defaults?.timeframeEndDate ?? null,
  // )
  // const [openTimeframeFrom, setOpenTimeframeFrom] = useState<boolean>(false)
  // const [openTimeframeUntil, setOpenTimeframeUntil] = useState<boolean>(false)
  const handleDateChange = (newValue: Dayjs | null, isStartDate: boolean) => {
    const isDateValid = newValue && dayjs(newValue).isValid()

    console.log('isDateValid', isDateValid, newValue, dayjs(newValue))

    try {
      if (!newValue || !isDateValid) return
      // const utcDate = dayjs(newValue).utc(true).toDate() // Convert to UTC using Dayjs
      // console.log('utcDate', utcDate)

      if (isStartDate) {
        setFrom(newValue)
        if (!until) return
        onDatesSelection(newValue, until)
      } else {
        setUntil(newValue)
        if (from && dayjs(from).isBefore(newValue)) {
          onDatesSelection(from, newValue)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          width: '100%',
          backgroundColor: '#fff',
          padding: '32px 36px 72px',
        }}
      >
        {isRecurring && (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '48px',
              mb: '36px',
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  color: '#4F575E',
                  mb: '8px',
                }}
              >
                Report Frequency
              </Typography>
              <Select
                placeholder="Frequency"
                placeholderIcon={IconMap}
                value={defaults?.reportFrequency ?? undefined}
                onChange={(e: any) => {
                  onFrequencyChange && onFrequencyChange(e.target.value)
                }}
                options={[
                  { value: 'daily', label: 'Daily' },
                  { value: 'weekly', label: 'Weekly' },
                  { value: 'biweekly', label: 'Biweekly' },
                  { value: 'monthly', label: 'Monthly' },
                ]}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  color: '#4F575E',
                  mb: '8px',
                }}
              >
                Time
              </Typography>
              <Select
                onChange={(e: any) => {
                  onTimeChange && onTimeChange(e.target.value)
                }}
                value={defaults?.reportTime ?? undefined}
                placeholderIcon={IconTable}
                placeholder="Time"
                options={hours}
              />
            </Box>
          </Box>
        )}

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '48px',
            mb: '36px',
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 600,
                color: '#4F575E',
                mb: '8px',
              }}
            >
              Report Timestamp Timezone
            </Typography>
            <Select
              placeholder="Timezone"
              placeholderIcon={IconMap}
              value={defaults?.reportTimezone ?? undefined}
              onChange={(e: any) => {
                console.log(e)
                onTimezoneSelection(
                  e.target.value,
                  timezoneStrings.find(
                    (timezone) => timezone.value === e.target.value,
                  )?.label ?? 'UTC',
                )
              }}
              options={timezoneStrings}
            />
          </Box>
          {!isRecurring && (
            <Box>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  color: '#4F575E',
                  mb: '12px',
                }}
              >
                Dates
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '16px',
                }}
              >
                <DateTimePicker
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  sx={{
                    flex: 1,
                  }}
                  label="From"
                  disableFuture
                  value={from as Dayjs | null}
                  onChange={(newValue: Dayjs | null) => {
                    // setOpenFrom(false)
                    // setOpenUntil(true)
                    handleDateChange(newValue, true)
                    // const newDate = new Date(newValue?.toISOString() ?? '')

                    // const newValueInUtc = new Date(
                    //   Date.UTC(
                    //     newDate.getFullYear(),
                    //     newDate.getMonth(),
                    //     newDate.getDate(),
                    //     newDate.getHours(),
                    //     newDate.getMinutes(),
                    //     newDate.getSeconds(),
                    //     newDate.getMilliseconds(),
                    //   ),
                    // )
                    // setFrom(newValueInUtc)

                    // if (!newValue || !until || dayjs(newValue).isAfter(until)) {
                    //   onDatesSelection()
                    //   return
                    // }

                    // // if (newValue || !until) {
                    // //   onDatesSelection()
                    // //   return
                    // // }

                    // onDatesSelection(newValueInUtc, until)
                  }}
                  onClose={() => setOpenFrom(false)}
                  open={openFrom}
                  slots={{
                    inputAdornment: () => (
                      <SVG
                        onClick={() => setOpenFrom(true)}
                        src={IconCalendar}
                        style={{
                          width: '26px',
                          height: '26px',
                          marginRight: '8px',
                        }}
                      />
                    ),
                  }}
                  slotProps={{
                    inputAdornment: {
                      position: 'start',
                    },
                    textField: {
                      onClick: () => {
                        setOpenFrom(true)
                        setOpenUntil(false)
                      },
                    },
                  }}
                />

                <Box
                  sx={{
                    width: '24px',
                    height: '2px',
                    borderRadius: '2px',
                    backgroundColor: '#CED4DA',
                  }}
                />

                <DateTimePicker
                  // minDate={from ? dayjs(from).add(1, 'day') : undefined}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  disableFuture
                  shouldDisableDate={(day) => {
                    if (!from) return false
                    const oneBeforeFrom = dayjs(from).subtract(1, 'day')
                    return dayjs(day).isBefore(dayjs(oneBeforeFrom))
                  }}
                  shouldDisableTime={(time) => {
                    if (!from) return false
                    // check if time is before from in the same day
                    const isBeforeFrom =
                      dayjs(from).isSame(time, 'day') &&
                      dayjs(from).isAfter(time)
                    return isBeforeFrom
                  }}
                  label="Until"
                  open={openUntil}
                  disabled={!from}
                  sx={{
                    flex: 1,
                  }}
                  onClose={() => setOpenUntil(false)}
                  value={until as Dayjs | null}
                  onChange={(newValue: Dayjs | null) => {
                    handleDateChange(newValue, false)
                    // if (newValue) {
                    //   const newDate = new Date(newValue?.toISOString() ?? '')

                    //   const newValueInUtc = new Date(
                    //     Date.UTC(
                    //       newDate.getFullYear(),
                    //       newDate.getMonth(),
                    //       newDate.getDate(),
                    //       newDate.getHours(),
                    //       newDate.getMinutes(),
                    //       newDate.getSeconds(),
                    //       newDate.getMilliseconds(),
                    //     ),
                    //   )
                    //   setUntil(newValueInUtc)

                    //   if (!from || dayjs(newValue).isBefore(from)) {
                    //     return
                    //   }

                    //   onDatesSelection(from, newValueInUtc)
                    // }
                  }}
                  slots={{
                    inputAdornment: () => (
                      <SVG
                        onClick={() => {
                          if (!from) return
                          setOpenUntil(true)
                          setOpenFrom(false)
                        }}
                        src={IconCalendar}
                        style={{
                          width: '26px',
                          height: '26px',
                          marginRight: '8px',
                        }}
                      />
                    ),
                  }}
                  slotProps={{
                    inputAdornment: {
                      position: 'start',
                    },
                    textField: {
                      onClick: () => {
                        if (!from) return
                        setOpenUntil(true)
                        setOpenFrom(false)
                      },
                    },
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </LocalizationProvider>
  )
}

export default OnDemandReport

/* <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '48px',
          mb: '36px',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              color: '#4F575E',
              mb: '12px',
            }}
          >
            Dates
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <DatePicker
              sx={{
                flex: 1,
              }}
              label="From"
              value={from}
              onChange={(newValue: any) => {
                setFrom(newValue)
                setOpenFrom(false)
                setOpenUntil(true)
                if (!newValue || !until || dayjs(newValue).isAfter(until)) {
                  onDatesSelection()
                  return
                }
                // if (newValue || !until) {
                //   onDatesSelection()
                //   return
                // }
                onDatesSelection(newValue, until)
              }}
              onClose={() => setOpenFrom(false)}
              open={openFrom}
              slots={{
                inputAdornment: () => (
                  <SVG
                    onClick={() => setOpenFrom(true)}
                    src={IconCalendar}
                    style={{
                      width: '26px',
                      height: '26px',
                      marginRight: '8px',
                    }}
                  />
                ),
              }}
              slotProps={{
                inputAdornment: {
                  position: 'start',
                },
                textField: {
                  onClick: () => {
                    setOpenFrom(true)
                    setOpenUntil(false)
                  },
                },
              }}
            />
            <Box
              sx={{
                width: '24px',
                height: '2px',
                borderRadius: '2px',
                backgroundColor: '#CED4DA',
              }}
            />
            <DatePicker
              minDate={from ?? undefined}
              label="Until"
              open={openUntil}
              disabled={!from}
              sx={{
                flex: 1,
              }}
              onClose={() => setOpenUntil(false)}
              value={until}
              onChange={(newValue: Dayjs | null) => {
                setUntil(newValue)
                if (!from || !newValue || dayjs(newValue).isBefore(from)) return
                onDatesSelection(from, newValue)
              }}
              slots={{
                inputAdornment: () => (
                  <SVG
                    onClick={() => setOpenUntil(true)}
                    src={IconCalendar}
                    style={{
                      width: '26px',
                      height: '26px',
                      marginRight: '8px',
                    }}
                  />
                ),
              }}
              slotProps={{
                inputAdornment: {
                  position: 'start',
                },
                textField: {
                  onClick: () => {
                    if (!from) return
                    setOpenUntil(true)
                    setOpenFrom(false)
                  },
                },
              }}
            />
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              color: '#4F575E',
              mb: '12px',
            }}
          >
            Select Timeframe
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <DatePicker
              sx={{
                flex: 1,
              }}
              label="From"
              value={timeframeFrom}
              onChange={(newValue: any) => {
                setOpenTimeframeFrom(false)
                setOpenTimeframeUntil(true)
                setTimeframeFrom(newValue)
                if (
                  !newValue ||
                  !timeframeUntil ||
                  dayjs(newValue).isAfter(until)
                ) {
                  onTimeframeSelection()
                  return
                }
                // if (newValue || !timeframeUntil) {
                //   onTimeframeSelection()
                //   return
                // }
                onTimeframeSelection(newValue, timeframeUntil)
              }}
              onClose={() => setOpenTimeframeFrom(false)}
              open={openTimeframeFrom}
              slots={{
                inputAdornment: () => (
                  <SVG
                    onClick={() => setOpenTimeframeFrom(true)}
                    src={IconCalendar}
                    style={{
                      width: '26px',
                      height: '26px',
                      marginRight: '8px',
                    }}
                  />
                ),
              }}
              slotProps={{
                inputAdornment: {
                  position: 'start',
                },
                textField: {
                  onClick: () => {
                    setOpenTimeframeFrom(true)
                    setOpenTimeframeUntil(false)
                  },
                },
              }}
            />
            <Box
              sx={{
                width: '24px',
                height: '2px',
                borderRadius: '2px',
                backgroundColor: '#CED4DA',
              }}
            />
            <DatePicker
              sx={{
                flex: 1,
              }}
              minDate={timeframeFrom ?? undefined}
              label="Until"
              open={openTimeframeUntil}
              disabled={!timeframeFrom}
              onClose={() => setOpenTimeframeUntil(false)}
              value={timeframeUntil}
              onChange={(newValue: Dayjs | null) => {
                if (
                  !timeframeFrom ||
                  !newValue ||
                  dayjs(newValue).isBefore(timeframeFrom)
                )
                  return
                setTimeframeUntil(newValue)
                onTimeframeSelection(timeframeFrom, newValue)
              }}
              slots={{
                inputAdornment: () => (
                  <SVG
                    onClick={() => setOpenTimeframeUntil(true)}
                    src={IconCalendar}
                    style={{
                      width: '26px',
                      height: '26px',
                      marginRight: '8px',
                    }}
                  />
                ),
              }}
              slotProps={{
                inputAdornment: {
                  position: 'start',
                },
                textField: {
                  onClick: () => {
                    if (!timeframeFrom) {
                      return
                    }
                    setOpenTimeframeUntil(true)
                    setOpenTimeframeFrom(false)
                  },
                },
              }}
            />
          </Box>
        </Box>
      </Box> */
