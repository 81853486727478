import { FC } from 'react'
import SVG from 'react-inlinesvg'
import { IconNotification } from 'src/assets/icons'
import styled from '@emotion/styled'

interface BillIconProps {
  count?: number
}

export const BillIcon: FC<BillIconProps> = ({ count }) => {
  console.log(count, 'eh')

  return (
    <div style={{ position: 'relative' }}>
      <SVG
        src={IconNotification}
        style={{ margin: '10px', width: 20, height: 20 }}
      />
      {!!count && <CountChip>{count}</CountChip>}
    </div>
  )
}

const CountChip = styled.div`
  background-color: #ff0000;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  font-size: 10px;
  object-fit: contain;
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: 5px;
  left: 5px;
`
