/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  useCallback,
  useState,
  ComponentType,
  ReactElement,
  useEffect,
} from 'react'

import {
  TableContainer,
  Table as MUITable,
  TableBody,
  TableHead,
  TableRow as MUITableRow,
  TableCell,
  Box,
  SxProps,
  Theme,
} from '@mui/material'
import SVG from 'react-inlinesvg'
import { Row, useTable } from 'react-table'

import { IconTableSort } from 'src/assets/icons'

import { TablePagination } from './components/TablePagination'
import { TableRow } from './components/TableRow'
import TableSkeleton from './components/TableSkeleton'
import { ITablePaginationProps } from './types'

// import TableEmpty from './components/TableEmpty'
// import TableLoader from './components/TableLoader'

export interface ITableColumns {
  accessor: any
  header?: string
  width?: number
  isSortable?: boolean
}

interface ITable {
  loading: boolean
  columns: ITableColumns[]
  data: any
  handleSorting?: (key: string, dir: 'desc' | 'asc') => void
  thStyles?: SxProps<Theme> | undefined
  tdStyles?: SxProps<Theme> | undefined
  containerStyles?: SxProps<Theme> | undefined
  extendableComponent?: ComponentType<{
    row: Row<any>
    handleClick: () => void
    onUserDeletion?: (id: number) => void
  }>
  sortDirection?: 'asc' | 'desc'
  pagination?: ITablePaginationProps
  isLoading?: boolean
  onUserDeletion?: (id: number) => void
}

export const Table = (props: ITable): ReactElement => {
  const {
    loading,
    columns,
    handleSorting,
    data,
    extendableComponent,
    pagination,
    tdStyles,
    thStyles,
    containerStyles,
    onUserDeletion,
  } = props

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ data: data || [], columns })

  const [activeTh, setActiveTh] = useState('')

  const renderHead = useCallback(() => {
    const [sortColumn, setSortColumn] = useState<any>(null)
    const [sortDirection, setSortDirection] = useState<any>(null)

    useEffect(() => {
      !!handleSorting && handleSorting(sortColumn, sortDirection)
    }, [handleSorting, sortColumn, sortDirection])

    const handleSortClick = (column: any): any => {
      if (sortColumn === column) {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
      } else {
        setSortColumn(column)
        setSortDirection('asc')
      }
    }

    return (
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <MUITableRow
            key={headerGroup.getHeaderGroupProps().key}
            role={headerGroup.getHeaderGroupProps().role}
          >
            {headerGroup.headers.map((column) => {
              const isColumnSortable = !!columns.find(
                ({ accessor, isSortable }) =>
                  accessor === column.id && isSortable,
              )

              return (
                <TableCell
                  key={column.getHeaderProps().key}
                  role={column.getHeaderProps().role}
                  style={column.width ? { width: column.width } : {}}
                  sx={{
                    maxWidth: '288px',
                    p: '17.5px 12px',
                    borderBottom: '1px solid #CED4DA',
                    backgroundColor: '#FFF',
                    color: '#272B30',
                    lineHeight: '21px',
                    '&:first-of-type': {
                      px: '24px',
                      borderTopLeftRadius: '8px',
                    },
                    '&:last-child': {
                      px: '24px',
                      borderTopRightRadius: '8px',
                    },
                    ...thStyles,
                  }}
                  sortDirection={
                    sortColumn === column.id ? sortDirection : false
                  }
                  onClick={() => isColumnSortable && handleSortClick(column.id)}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {column.render('header')}
                    {isColumnSortable && (
                      <SVG
                        src={IconTableSort}
                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                        onClick={() => handleSortClick(column.id)}
                      />
                    )}
                  </Box>
                </TableCell>
              )
            })}
            {!!extendableComponent && !loading && (
              <TableCell
                key={'extendedColumn'}
                sx={{
                  width: '72px',
                  p: '17.5px 12px',
                  borderBottom: '1px solid #CED4DA',
                  backgroundColor: '#FFF',
                  color: '#272B30',
                  lineHeight: '21px',
                  '&:first-of-type': {
                    px: '24px',
                    borderTopLeftRadius: '8px',
                  },
                  '&:last-child': {
                    px: '24px',
                    borderTopRightRadius: '8px',
                  },
                  ...thStyles,
                }}
              />
            )}
          </MUITableRow>
        ))}
      </TableHead>
    )
  }, [columns, activeTh])

  const renderBody = useCallback(() => {
    return (
      <TableBody
        {...getTableBodyProps()}
        sx={{
          backgroundColor: '#FFF',
        }}
      >
        {rows.map((row) => {
          prepareRow(row)
          return (
            <TableRow
              row={row}
              key={{ ...row.getRowProps() }.key}
              extendableComponent={extendableComponent}
              onUserDeletion={(id: number) => {
                onUserDeletion && onUserDeletion(id)
              }}
              tdStyles={tdStyles}
              styles={(row.original as any)?.styles}
            />
          )
        })}
      </TableBody>
    )
  }, [data])

  return (
    <TableContainer
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        ...containerStyles,
      }}
    >
      <MUITable
        {...getTableProps}
        sx={{
          width: '100%',
          overflow: 'hidden',
          borderRadius: '8px',
          color: '#272B30',
          filter: 'drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.1))',
        }}
      >
        {columns && renderHead()}
        {!!data?.length && !loading && renderBody()}
        {!extendableComponent && loading && (
          <TableSkeleton
            columnCount={columns.length}
            limit={pagination?.limit}
          />
        )}
        {!!extendableComponent && loading && (
          <TableSkeleton
            columnCount={columns.length + 1}
            limit={pagination?.limit}
          />
        )}
      </MUITable>
      {pagination && !loading && (
        <TablePagination
          currentPage={pagination.page}
          total={pagination.totalPages}
          onPageChange={pagination.onPageChange}
          totalItems={pagination.totalItems}
          limit={pagination.limit}
        />
      )}

      {/* {data?.length && !loading && <TableEmpty />} */}
      {/* {loading && <TableLoader />} */}
    </TableContainer>
  )
}
