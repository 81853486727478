import { ReactElement, useState } from 'react'

import { Box, Typography } from '@mui/material'
import SVG from 'react-inlinesvg'

import { IconChevronDown } from 'src/assets/icons'
import { IOption, Select } from 'src/ui/Select'

const mockPageOption: IOption[] = [
  {
    label: '5',
    value: 5,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: '15',
    value: 15,
  },
  {
    label: '20',
    value: 20,
  },
]

export interface TableRowProps {
  data?: any
  onPageChange: (page: number, limit: number) => void
  currentPage: number
  limit: number
  total: number
  totalItems: number
}

export const TablePagination = (props: TableRowProps): ReactElement => {
  const { onPageChange, currentPage, limit, total, totalItems } = props
  const [value, setValue] = useState(limit)

  const handleChange = (e: any): void => {
    setValue(e.target.value)
    // changes limit and resets page to 1
    onPageChange(1, e.target.value)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '24px',
        height: '56px',
        mt: '16px',
        '&>div': {
          display: 'flex',
          alignItems: 'center',
          p: '8px 16px',
          backgroundColor: '#FFF',
          borderRadius: '8px',
        },
      }}
    >
      <Box>
        <Typography
          variant="body2"
          sx={{
            color: '#000',
            mr: '8px',
            whiteSpace: 'nowrap',
          }}
        >
          Rows per page
        </Typography>
        <Select
          key={limit}
          value={limit}
          options={mockPageOption}
          width={70}
          isSmall
          onChange={handleChange}
        />
      </Box>
      <Box
        sx={{
          gap: '16px',
          '&>div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #CED4DA',
            borderRadius: '4px',
          },
        }}
      >
        <Box
          component={'button'}
          disabled={currentPage <= 1}
          onClick={
            currentPage === 1
              ? () => {}
              : () => {
                  onPageChange(currentPage - 1, value)
                }
          }
          sx={{
            p: '7px',
            cursor: 'pointer',
            backgroundColor: '#fff',
            borderRadius: '4px',
            border: '1px solid #CED4DA',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              boxShadow: 'inset 0px 250px 0px rgba(16, 18, 19, 0.1)',
            },
            '&:disabled': {
              cursor: 'not-allowed',
              boxShadow: 'none',
              opacity: 0.5,
            },
          }}
        >
          <SVG
            src={IconChevronDown}
            style={{
              position: 'relative',
              transform: 'rotate(90deg)',
              fill: '#101213',
            }}
          />
        </Box>
        <Box
          sx={{
            fontSize: '14px',
            lineHeight: 1.25,
            fontWeight: 500,
            p: '4.5px 16px',
            backgroundColor: '#303D60',
            color: '#FFF',
          }}
        >
          Displayed {totalItems === 0 ? 0 : (currentPage - 1) * limit + 1} -{' '}
          {Math.min(currentPage * limit, totalItems)}
        </Box>
        <Box
          component={'button'}
          disabled={currentPage >= total}
          onClick={
            currentPage === total
              ? () => {}
              : () => {
                  onPageChange(currentPage + 1, value)
                }
          }
          sx={{
            p: '7px',
            cursor: 'pointer',
            backgroundColor: '#fff',
            borderRadius: '4px',
            border: '1px solid #CED4DA',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              boxShadow: 'inset 0px 250px 0px rgba(16, 18, 19, 0.1)',
            },
            '&:disabled': {
              cursor: 'not-allowed',
              boxShadow: 'none',
              opacity: 0.5,
            },
          }}
        >
          <SVG
            src={IconChevronDown}
            style={{
              position: 'relative',
              transform: 'rotate(-90deg)',
              fill: '#101213',
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}
