import { FC } from 'react'

import { routes } from 'src/router'

import MediaSection from './MediaSection'

const Articles: FC = () => {
  return (
    <MediaSection
      title="Articles"
      apiEndpoint="articles"
      type="article"
      viewAllLabel="View All Articles"
      viewAllRoute={routes.knowledgeBaseArticles}
    />
  )
}

export default Articles
