import { useEffect, useState } from 'react'

import { io, Socket } from 'socket.io-client'

import {
  SocketConnected,
  SocketDisconnected,
} from './sockets/user-socket-props'

let socket: Socket | undefined
let socketPromise: Promise<Socket> | undefined
let userToken: string | undefined

function _initSocket(): Promise<Socket> {
  console.log('Init socket')

  if (socket?.connected) return Promise.resolve(socket)

  socketPromise ??= new Promise((resolve, reject) => {
    if (!userToken) {
      reject(Error('No user token'))
    }

    console.log('Connecting socket...')

    const newSocket = io(`${BASE_URL?.split('/api')[0]}?token=${userToken}`, {
      transports: ['websocket'],
    }).on('connect', () => {
      console.log('Socket connected')
      resolve(newSocket)
      socket = newSocket
    })
  })

  return socketPromise
}

/**
 * Hook to use the socket connection
 */
export function useSocket(
  onConnect?: SocketConnected,
  onDisconnect?: SocketDisconnected,
) {
  const [socket, setSocket] = useState<Socket | undefined>(undefined)

  useEffect(() => {
    if (socket?.connected) return

    _initSocket()
      .then((socket) => {
        setSocket(socket)
        onConnect?.(socket)

        if (onDisconnect) socket.on('disconnect', onDisconnect)
      })
      .catch((err) => {
        console.error('Socket ', err)
      })
  }, [socket?.connected])

  return socket
}

const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_BASE_URL_PROD
    : process.env.REACT_APP_API_BASE_URL

export const initSocket = (token: string): void => {
  userToken = token
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  _initSocket()
}

export const disconnectSocket = (): void => {
  if (socket) {
    console.log('Disconnecting socket...')

    socket.disconnect()
    socket = undefined
    socketPromise = undefined
    userToken = undefined
  }
}

export function getSocket() {
  return socket
}
