import { FC, useEffect, useState } from 'react'

import { Box, Typography } from '@mui/material'
import axios from 'axios'
import SVG from 'react-inlinesvg'
import { useBeforeUnload, useParams } from 'react-router-dom'
import { GridLoader } from 'react-spinners'

import { IconClockVariant } from 'src/assets/icons'
import convertTimestampToLocal from 'src/helpers/timestamp-generator'

const handleColorChip = (option?: string): string => {
  switch (option) {
    case 'NORMAL':
      return '#37B24D'
    case 'WARNING':
      return '#E9A100'
    case 'FAULT':
      return '#C92A2A'
    default:
      return '#6A7178'
  }
}

const handleLogBgColor = (option?: string): string => {
  switch (option) {
    case 'NORMAL':
      return 'rgba(55, 178, 77, 0.15)'
    case 'WARNING':
      return 'rgba(233, 161, 0, 0.15)'
    case 'FAULT':
      return 'rgba(201, 42, 42, 0.15)'
    default:
      return 'rgba(106, 113, 120, 0.15)'
  }
}
const MachineLogs: FC = () => {
  const [logs, setLogs] = useState<any[]>([])
  const { bodymakerId } = useParams<{ bodymakerId: string }>()
  const [loading, setLoading] = useState<boolean>(false)

  const [eventSource, setEventSource] = useState<EventSource | null>()

  useBeforeUnload(() => {
    eventSource?.close()
  })

  const fetchLogs = async (): Promise<void> => {
    setLoading(true)
    try {
      const { data } = await axios.get(
        `/company/bodymaker-base-logs/${bodymakerId}`,
      )
      setLogs(data)
    } catch (error) {
      console.log('Error fetching logs:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchLogs()
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })

    const userString = localStorage.getItem('user') ?? ''
    const user = JSON.parse(userString)
    const token = user?.token

    // get cpm from an event source
    const newEventSource = new EventSource(
      `${axios.defaults.baseURL}company/live-logs/${bodymakerId}/?token=${token}`,
      { withCredentials: true },
    )

    setEventSource(newEventSource)

    return () => {
      newEventSource.close()
    }
  }, [bodymakerId])

  useEffect(() => {
    eventSource?.addEventListener('message', (event) => {
      const data = JSON.parse(event.data)

      setLogs((prevLogs) => {
        console.log('prevLogs', prevLogs)

        const newLogs = [...prevLogs]
        newLogs.pop()
        return [data, ...newLogs].sort((a, b) => {
          return new Date(b.time).getTime() - new Date(a.time).getTime()
        })
      })
    })

    return () => {
      eventSource?.close()
    }
  }, [eventSource])

  return (
    <>
      <Box
        component={'h2'}
        sx={{
          my: 3,
        }}
      >
        Live updates
      </Box>
      {!loading &&
        logs &&
        logs.map((log: any) => (
          <Box
            key={log.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              lineHeight: '18px',
              gap: '0 24px',
              mt: '1px',
              fontSize: '14px',
              p: '16px 64px',
              bgcolor: handleLogBgColor(log.clusterStatusName),
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                // justifyContent: 'center',
                p: '4px',
                color: '#FFF',
                borderRadius: '4px',
                bgcolor: handleColorChip(log.clusterStatusName),
                minWidth: '170px',
              }}
            >
              <SVG src={IconClockVariant} />
              <span
                style={{
                  marginLeft: '4px',
                }}
              >
                {log.dt
                  ? convertTimestampToLocal(
                      new Date(log.dt * 1000).toISOString(),
                    )
                  : 'N/A'}
              </span>
            </Box>
            <Typography sx={{ fontSize: 'inherit', color: '#6A7178' }}>
              {log.data}
            </Typography>
          </Box>
        ))}

      {loading && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: '4px',
            color: '#FFF',
            borderRadius: '4px',
            minWidth: '170px',
          }}
        >
          <GridLoader size={15} color={'#1068EB'} />
        </Box>
      )}

      {!loading && !logs.length && <Box>No Logs available</Box>}
    </>
  )
}

export default MachineLogs
