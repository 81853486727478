import { FC, useState } from 'react'

import { Box, Stack, Typography, InputLabel } from '@mui/material'
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { ImagePrideLogin } from 'src/assets/images'
import { routes } from 'src/router'
import { requestResetPassword } from 'src/store/reducers/authReducer'
import { RootState } from 'src/store/store'
import { CustomButton } from 'src/ui/Button'
import { Image } from 'src/ui/Image'
import { Input } from 'src/ui/Input'

export const ForgotPasswordForm: FC = () => {
  const [error, setError] = useState('')
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch()
  const isLoading = useSelector((state: RootState) => state.authReducer.loading)
  const form = useForm({
    defaultValues: {
      email: '',
    },
  })
  const resetPassword = async (e: Event): Promise<void> => {
    e.preventDefault()
    console.log('reset')

    const { email } = form.getValues()
    if (!email) {
      setError('Email is required.')
      return
    }

    try {
      const res = (await dispatch(
        requestResetPassword({ email }),
      ).unwrap()) as unknown as { success: boolean; message: string }

      if (!res.success) {
        throw new Error(res.message)
      } else {
        setError('')
        toast.success('Reset password link sent to your email.')
      }
    } catch (err) {
      console.log(err)

      const error = err as Error
      if (error.message) {
        setError(error.message)
      } else if (typeof err === 'string') {
        setError(err)
      } else {
        setError('Something went wrong')
      }
    }
  }

  return (
    <Stack
      alignItems="center"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '435px',
        bgcolor: '#FFF',
        p: '0',
        borderRadius: '24px',
        boxShadow: '0px 20px 40px rgba(0,0,0,0.4)',
      }}
    >
      <Image
        styles={{
          width: '468px',
          height: '652px',
          borderRadius: '24px 0 0 24px',
        }}
        src={ImagePrideLogin}
      />
      {/* <Image src={IconLogo} styles={{ width: '100px', marginBottom: '32px' }} /> */}
      <Box
        sx={{
          width: '632px',
          p: '48px',
        }}
      >
        <Typography
          sx={{
            color: '#000',
            fontWeight: 700,
            textAlign: 'center',
            mb: '16px',
            fontSize: '36px',
          }}
        >
          Forgot Password?
        </Typography>

        <Form>
          <Stack sx={{ mb: '16px' }}>
            <InputLabel
              htmlFor="email"
              sx={{
                fontSize: '14px',
                lineHeight: '18px',
                my: '16px',
                fontWeight: 500,
                color: '#4F575E',
              }}
            >
              Email
            </InputLabel>
            <Input
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                form.setValue('email', e.target.value)
              }
              type="text"
              name="email"
              id="email"
              value={form.watch('email')}
              styles={{
                p: '8px',
                fontSize: '14px',
                '&>input': {
                  p: 0,
                },
              }}
            />
          </Stack>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              mb: 3,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: '8px',
              }}
            >
              <Link
                to={routes.login}
                style={{
                  fontSize: '14px',
                  lineHeight: '18px',
                  fontWeight: 500,
                  color: '#1068EB',
                }}
              >
                Or login instead
              </Link>
            </Box>
          </Box>

          {error && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: '16px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#DC2020',
                }}
              >
                {error}
              </Box>
            </Box>
          )}

          <CustomButton
            type="button"
            appearance="primary-soft-large"
            onClick={resetPassword}
            isLoading={isLoading}
            styles={{
              width: '100%',
            }}
          >
            Reset Password
          </CustomButton>
        </Form>
      </Box>
    </Stack>
  )
}

export default ForgotPasswordForm
