import { FC, createRef } from 'react'

import { UploadFile } from '@mui/icons-material'
import { Input } from '@mui/material'
import { Box } from '@mui/system'
import axios from 'axios'
import { toast } from 'react-toastify'

import { Notifications } from './components/Notifications/Notifications'
import { ProfileMenu } from './components/ProfileMenu'

export const UserControl: FC = () => {
  const inputRef = createRef<HTMLInputElement>()
  const uploadFile = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const file = e.target.files?.[0]
    console.log(file)

    if (file) {
      try {
        const data = new FormData()
        data.append('file', file)
        // replace this with the actual API endpoint
        const response = await axios.post('/company/upload-file', data)

        if (response.status === 200) {
          console.log('File uploaded successfully', response.data)
          toast.success('File uploaded successfully')
        }
      } catch (e) {
        console.log(e)
      } finally {
        // Reset the input value
        if (inputRef.current) {
          inputRef.current.value = ''
        }
      }
    }
  }
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Input
        ref={inputRef}
        id="upload-file"
        type="file"
        onChange={uploadFile}
        sx={{
          display: 'none',
        }}
      />
      <Box
        component="label"
        htmlFor="upload-file"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '40px',
          height: '40px',
          minWidth: '40px',
          padding: '0 !important',
          borderRadius: '4px',
          backgroundColor: 'white',
          color: 'gray',
          cursor: 'pointer',
          marginRight: '8px',
          boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <UploadFile />
      </Box>
      <Notifications />
      <ProfileMenu />
    </Box>
  )
}
