import { FC, useEffect, useState } from 'react'

import ReactDOM from 'react-dom'

const TopNotification: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [notificationRoot, setNotificationRoot] = useState<HTMLElement | null>(
    null,
  )
  useEffect(() => {
    setNotificationRoot(document.getElementById('notification-root'))
  }, [])

  if (!notificationRoot) return <></>
  return ReactDOM.createPortal(children, notificationRoot)
}

export default TopNotification
