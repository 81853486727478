import Styled from '@emotion/styled'

export const SamplesGrid = Styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    
    column-gap: 24px;
    row-gap: 16px;
    width: 100%;
  `

export const SamplesItem = Styled.div`
  position: relative;
  display: inline-flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  
  border-radius: 8px;
  background: #FFF; 
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.10);
  
   
  button {
    position: absolute;
    right: 16px;
    padding: 8px 16px;
    
    background: #1068EB;
     
     &:hover {
        background: #0A4ABF;
     }
  }
`

export const SampleItemTitle = Styled.span`
    color: #101213;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 122.222% */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 175px;
`

export const SampleItemSubtitle = Styled.span`
    color: #ADB5BD;
    font-feature-settings: 'clig' off, 'liga' off;
     
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 150% */
`
