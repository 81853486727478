import React, { FC, useEffect, useMemo, useRef, useState } from 'react'

import * as d3 from 'd3'

import { Margins } from './AnimatedLineChart'

interface BottomAxisProps {
  xScale: d3.ScaleTime<number, number, never>
  height: number
  margins: Margins
  selectedDate: Date | null
}

const BottomAxis: FC<BottomAxisProps> = ({
  xScale,
  height,
  margins,
  selectedDate,
}) => {
  const axisRef = useRef<SVGGElement>(null)
  const dateRef = useRef<SVGTextElement>(null)

  useEffect(() => {
    d3.select(axisRef.current!)
      .attr('transform', `translate(0, ${height - margins.bottom})`)
      .transition()
      .duration(0)
      .call(d3.axisBottom(xScale))
  }, [height, margins.bottom, xScale])

  /// show date on the bottom axis when hovering over the chart
  useEffect(() => {
    xScale.interpolate()
    if (!selectedDate) {
      d3.select(dateRef.current!).text('') // clear the date when the mouse leaves the chart
    } else {
      d3.select(dateRef.current!)
        .attr('x', xScale(selectedDate))
        .attr('y', height - margins.bottom + 30)
        .attr('text-anchor', 'middle')
        .attr('background', 'white')
        .attr('font-size', '.8rem')
        .attr('fill', '#646464')
        .text(d3.timeFormat('%b %d %H:%M:%S')(selectedDate))
    }
  }, [height, margins.bottom, selectedDate, xScale])

  return (
    <>
      <g ref={axisRef} />
      <text ref={dateRef} />
    </>
  )
}

export default BottomAxis
