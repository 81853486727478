import React, { FC, useState } from 'react'

import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Typography,
  Chip,
  Skeleton,
  useTheme,
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

import { routes } from 'src/router'
import customTheme from 'src/themes/themeDark'

export interface MediaCardProps {
  id: string
  title: string
  description?: string
  videoUrl?: string
  thumbnailUrl?: string
  type: 'video' | 'article'
  tags: string[]
}

const MediaCard: FC<{ mediaCard: MediaCardProps }> = ({ mediaCard }) => {
  const theme = useTheme<typeof customTheme>()
  const { id, title, description, videoUrl, thumbnailUrl, type, tags } =
    mediaCard
  const navigate = useNavigate()
  const handleClick = (): void => {
    if (mediaCard.type === 'video') {
      navigate(`${routes.knowledgeBase}/video-tutorials/${id}`, {
        state: { video: mediaCard },
      })
    } else {
      navigate(`${routes.knowledgeBase}/articles/${id}`, {
        state: { article: mediaCard },
      })
    }
  }

  const [isLoading, setIsLoading] = useState(true)

  const handleLoaded = (): void => {
    setIsLoading(false)
  }

  const renderMedia = (): JSX.Element => {
    if (type === 'video') {
      return (
        <CardMedia
          component="iframe"
          onClick={(e) => e.preventDefault()}
          src={videoUrl}
          title={title}
          sx={{
            height: '264px',
            borderTopLeftRadius: '0.25rem',
            borderTopRightRadius: '0.25rem',
            border: 'none',
            pointerEvents: 'none',
            display: isLoading ? 'none' : 'block',
          }}
          onLoad={handleLoaded}
        />
      )
    } else {
      return (
        <CardMedia
          component="img"
          src={thumbnailUrl}
          alt={title}
          sx={{
            height: '264px',
            borderTopLeftRadius: '0.25rem',
            borderTopRightRadius: '0.25rem',
            display: isLoading ? 'none' : 'block',
          }}
          onLoad={handleLoaded}
        />
      )
    }
  }

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card
        onClick={handleClick}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '0.25rem',
          mb: '1.5rem',
          padding: '0',
          cursor: 'pointer',
          position: 'relative',
        }}
      >
        {isLoading && (
          <Skeleton
            variant="rectangular"
            sx={{
              height: '264px',
              borderTopLeftRadius: '0.25rem',
              borderTopRightRadius: '0.25rem',
            }}
          />
        )}
        {renderMedia()}
        <CardContent sx={{ flexGrow: 1, padding: '1.5rem 1.5rem 0' }}>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            sx={{
              maxHeight: '3.6em',
              fontSize: '1.25rem',
              fontWeight: 500,
              lineHeight: 1.2,
              color: theme.typography.h5.color,
              mb: '1.5rem',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {title}
          </Typography>
          {type === 'article' && (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                fontSize: '1rem',
                fontWeight: 400,
                lineHeight: 1.5,
                color: theme.typography.h5.color,
                mb: '1.3125rem',
                display: '-webkit-box',
                WebkitLineClamp: 4,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {description}
            </Typography>
          )}
          {tags.map((tag) => (
            <Chip
              label={tag}
              sx={{
                backgroundColor: theme.typography.h5.color,
                color: theme.palette.static.main,
                fontSize: '0.875rem',
                margin: '0 0.25rem',
                padding: '0.25rem 0.5rem',
              }}
              key={`${tag}${Math.random()}`}
            />
          ))}
        </CardContent>
        <CardActions sx={{ padding: '0 1.5rem 1.5rem' }}>
          {type === 'video' ? (
            <Button
              component={Link}
              to={`${routes.knowledgeBase}/video-tutorials/${id}`}
              state={{ video: mediaCard }}
              size="medium"
              sx={{ color: theme.palette.action.main, fontSize: '1rem' }}
            >
              Watch Video
            </Button>
          ) : (
            <Button
              component={Link}
              to={`${routes.knowledgeBase}/articles/${id}`}
              state={{ article: mediaCard }}
              size="medium"
              sx={{ color: theme.palette.action.main, fontSize: '1rem' }}
            >
              Read More
            </Button>
          )}
        </CardActions>
      </Card>
    </Grid>
  )
}

export default MediaCard
