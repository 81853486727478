import { FC, useEffect, useState } from 'react'

import { Backdrop, Box, CircularProgress, Typography } from '@mui/material'
import axios from 'axios'
import SVG from 'react-inlinesvg'
import { useNavigate, useParams } from 'react-router-dom'

import { IconSitemap } from 'src/assets/icons'
import { PageHeading } from 'src/components/PageHeading'
import { ITableColumns, Table } from 'src/components/Table'
import convertTimestampToLocal from 'src/helpers/timestamp-generator'
import Breadcrumb from 'src/ui/Breadcrumbs/Breadcrumb'
import { CustomButton } from 'src/ui/Button'

import { BodymakerOverview } from '../FleetData/components/BodymakerOverview'
import PieChart from 'src/ui/PieChart/PieChart'
const columns: ITableColumns[] = [
  {
    accessor: 'lineName',
    header: 'Plant Asset',
    width: 0,
  },
  // {
  //   accessor: 'productionRateCumulative',
  //   header: 'Production Rate Cumulative',
  //   width: 0,
  // },
  {
    accessor: 'synapseStatus',
    header: 'Synapse Status',
    width: 0,
  },
  {
    accessor: 'productionRateCumulative',
    header: 'Production Rate Cumulative',
    width: 0,
  },
  {
    accessor: 'lastUpdated',
    header: 'Timestamp',
    width: 0,
  },
  // {
  //   accessor: 'actions',
  //   header: 'Actions',
  //   width: 74,
  // },
]

const LineView: FC = () => {
  const { lineId, plantId } = useParams<{ lineId: string; plantId: string }>()
  const [data, setData] = useState<any[]>([])
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [breadcrumbs, setBreadcrumbs] = useState<any>()
  const [status, setStatus] = useState({
    synapseNormal: 0,
    synapseWarning: 0,
    synapseFailure: 0,
    synapseFault: 0,
    synapseUnknown: 0,
  })
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setIsLoading(true)
      const response = await axios.get(`/company/line/${lineId}`)
      const getLineData = await axios.get(
        `/data/factories/${plantId}/lines/${lineId}`,
      )

      setBreadcrumbs([
        {
          label: getLineData.data.factoryName,
          href: '/',
        },
        {
          label: getLineData.data.lineName,
        },
      ])
      console.log(response.data)
      console.log([response.data.line])
      console.log(response.data.synapseStatusThresholds)

      setData([response.data.line])
      console.log(response.data.synapseStatusThresholds)

      setStatus(response.data.synapseStatusThresholds)
    }

    fetchData()
      .then(() => {
        setData((prevState: any) => {
          const temp = prevState.map((item: any) => {
            return {
              ...item,
              name: item.name,
              styles: {
                // backgroundColor: '#FEFAF2',
                // boxShadow: 'inset 0px -1px 0px #CED4DA',
              },
              lineName: (
                <>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                      component={SVG}
                      src={IconSitemap}
                      height={32}
                      width={32}
                      sx={{
                        '& path': {
                          // stroke: '#F59F00',
                        },
                      }}
                      style={{
                        minWidth: 'fit-content',
                      }}
                    ></Box>
                    <Typography
                      variant="body2"
                      sx={{
                        ml: '8px',
                        color: '#000',
                        fontWeight: 600,
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: '12px',
                      color: 'secondary.main',
                    }}
                  >
                    {item.plant.region}
                  </Typography>
                </>
              ),
              // productionRateCumulative: (
              //   <Box
              //     sx={{
              //       p: '5.5px 16px',
              //       display: 'inline-block',
              //       borderRadius: '100px',
              //       backgroundColor: '#F59F00',
              //       color: '#fff',
              //       fontWeight: 500,
              //     }}
              //   >
              //     {item.cumulativeProductionRate}
              //   </Box>
              // ),
              synapseStatus: (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <PieChart
                    props={{
                      data: [
                        {
                          label: 'normal',
                          value: item.synapseStatus.normal,
                        },
                        {
                          label: 'warning',
                          value: item.synapseStatus.warning,
                        },
                        {
                          label: 'fault',
                          value: item.synapseStatus.fault,
                        },
                      ],
                      thresholds: {
                        normal: {
                          value: status.synapseNormal,
                          color: '#37B24D',
                        },
                        warning: {
                          value: status.synapseWarning,
                          color: '#E9A100',
                        },
                        fault: {
                          value: status.synapseFault,
                          color: '#C92A2A',
                        },
                      },
                      innerRadius: 10,
                      outerRadius: 20,
                    }}
                  />

                  <Box
                    sx={{
                      ml: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '12px',
                      fontWeight: 600,
                    }}
                  >
                    <Box
                      component={'span'}
                      sx={{ color: '#37B24D', ml: '12px', mr: '4px' }}
                    >
                      Normal
                    </Box>
                    = {item.synapseStatus.normal}
                    <Box
                      component={'span'}
                      sx={{ color: '#E9A100', ml: '12px', mr: '4px' }}
                    >
                      Warning
                    </Box>
                    = {item.synapseStatus.warning}
                    <Box
                      component={'span'}
                      sx={{ color: '#C92A2A', ml: '12px', mr: '4px' }}
                    >
                      Fault
                    </Box>
                    = {item.synapseStatus.fault}
                  </Box>
                </Box>
              ),
              productionRateCumulative: (
                <Typography
                  sx={{
                    color: '#272B30',
                  }}
                >
                  {item.avgProductionRate}
                </Typography>
              ),
              lastUpdated: (
                <Box
                  sx={{
                    p: '4px',
                    display: 'inline-block',
                    borderRadius: '4px',
                    backgroundColor: 'secondary.main',
                    color: '#F8F9FA',
                    whiteSpace: 'nowrap',
                    lineHeight: '18px',
                    fontSize: '12px',
                  }}
                >
                  {convertTimestampToLocal(item.lastUpdated)}
                </Box>
              ),
              actions: (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CustomButton
                    appearance="primary-soft-small"
                    styles={{ py: '12px !important' }}
                  >
                    Edit
                  </CustomButton>
                </Box>
              ),
            }
          })

          return temp
        })
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          navigate('/')
        }
        console.log(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [lineId])

  return (
    <Box
      sx={{
        mt: '36px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          mb: '48px',
        }}
      >
        <Breadcrumb
          breadcrumbs={
            breadcrumbs ?? [
              {
                label: 'Loading...',
              },
            ]
          }
        />
      </Box>
      <Box
        sx={{
          mb: '24px',
        }}
      >
        <PageHeading
          title={!isLoading ? `${data[0]?.name} View` : 'Loading..'}
        />
      </Box>
      <Table
        data={data}
        loading={isLoading}
        columns={columns}
        containerStyles={{
          mt: '48px',
        }}
        tdStyles={{
          p: '16px',
          pb: '12px',
        }}
      />
      <BodymakerOverview
        lineId={lineId}
        showHeader={false}
        showPagination={false}
        showSearch={false}
      />
      {isLoading && (
       <Backdrop
       open={true}
       sx={{ zIndex: 9999, background: 'transparent' }}
     ></Backdrop>
      )}
    </Box>
  )
}

export default LineView
