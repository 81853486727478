import React, { FC, useEffect, useState } from 'react'

import { Box, Typography, debounce } from '@mui/material'
import axios from 'axios'
import SVG from 'react-inlinesvg'

import { Icon3DCube } from 'src/assets/icons'
import { Search } from 'src/components/Search'
import { ITableColumns, Table } from 'src/components/Table'
import { ITablePaginationProps } from 'src/components/Table/types'
import convertTimestampToLocal from 'src/helpers/timestamp-generator'

import { IBodymakerOverviewData } from '../../types'
import { Select } from 'src/ui/Select'
import { Link } from 'react-router-dom'
import { routes } from 'src/router'

interface BodymakerOverviewProps {
  data?: any
  showHeader?: boolean
  showPagination?: boolean
  showSearch?: boolean
  lineId?: string
}

// const mockTableData: IBodymakerOverviewData[] = [
//   {
//     id: 1,
//     name: 'ABM21',
//     line: 'Milwaukee Line',
//     productionRate: '2343 CPM',
//     overTravel: '0.0846 cm',
//     clampRing: '0.0846 cm',
//     alignment: '0.0846 cm',
//     dome: '0.0846 cm',
//     timestamp: '3:15:47 AM CEST 10/12/2022',
//   },
//   {
//     id: 2,
//     name: 'ABM21',
//     line: 'Milwaukee Line',
//     productionRate: '2345 CPM',
//     overTravel: '0.0846 cm',
//     clampRing: '0.0846 cm',
//     alignment: '0.0846 cm',
//     dome: '0.0846 cm',
//     timestamp: '3:15:47 AM CEST 10/12/2022',
//   },
//   {
//     id: 3,
//     name: 'ABM21',
//     line: 'Milwaukee Line',
//     productionRate: '2347 CPM',
//     overTravel: '0.0846 cm',
//     clampRing: '0.0846 cm',
//     alignment: '0.0846 cm',
//     dome: '0.0846 cm',
//     timestamp: '3:15:47 AM CEST 10/12/2022',
//   },
//   {
//     id: 4,
//     name: 'ABM21',
//     line: 'Milwaukee Line',
//     productionRate: '2345 CPM',
//     overTravel: '0.0846 cm',
//     clampRing: '0.0846 cm',
//     alignment: '0.0846 cm',
//     dome: '0.0846 cm',
//     timestamp: '3:15:47 AM CEST 10/12/2022',
//   },
//   {
//     id: 5,
//     name: 'ABM21',
//     line: 'Milwaukee Line',
//     productionRate: '2345 CPM',
//     overTravel: '0.0846 cm',
//     clampRing: '0.0846 cm',
//     alignment: '0.0846 cm',
//     dome: '0.0846 cm',
//     timestamp: '3:15:47 AM CEST 10/12/2022',
//   },
// ]

const columns: ITableColumns[] = [
  {
    accessor: 'name',
    header: 'Bodymaker',
    width: 0,
    isSortable: true,
  },
  // {
  //   accessor: 'productionRate',
  //   header: 'Production Rate',
  //   width: 0,
  //   isSortable: true,
  // },
  {
    accessor: 'overTravel',
    header: 'Over Travel',
    width: 0,
    isSortable: true,
  },
  {
    accessor: 'clampRing',
    header: 'Clamp Ring',
    width: 0,
    isSortable: true,
  },
  {
    accessor: 'dome',
    header: 'Dome',
    width: 0,
    isSortable: true,
  },
  {
    accessor: 'alignment',
    header: 'Alignment',
    width: 0,
    isSortable: true,
  },

  {
    accessor: 'lastUpdated',
    header: 'Timestamp',
    width: 0,
    isSortable: true,
  },
]

export const BodymakerOverview: FC<BodymakerOverviewProps> = (props) => {
  const [data, setData] = useState<IBodymakerOverviewData[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [regions, setRegions] = useState<string[]>([])
  const [plants, setPlants] = useState<
    Array<{
      id: string
      name: string
    }>
  >([])
  const [pagination, setPagination] = useState<ITablePaginationProps>({
    page: 1,
    totalPages: 1,
    limit: 5,
    totalItems: 1,
    searchQuery: '',
    sort: {
      field: 'name',
      order: 'asc',
    },
    regions: [],
    plants: [],
    onPageChange: (page, limit) => {
      setPagination((prevState) => ({
        ...prevState,
        page,
        limit,
      }))
    },
  })

  const handleSorting = (column: string, dir: any): void => {
    setPagination((prevState) => {
      return {
        ...prevState,
        page: 1,
        sort: {
          field: column,
          order: dir,
        },
      }
    })
  }

  //   const handleSorting = (column: string): void => {
  //     setPagination((prevState) => {
  //       return {
  //         ...prevState,
  //         page: 1,
  //         sort: {
  //           field: column,
  //           order:
  //             prevState.sort.order === ('asc' as 'asc' | 'desc')
  //               ? 'desc'
  //               : 'asc',
  //         },
  //       }
  //     })
  //   }
  // }

  const handleSearchDebounced = debounce((value) => {
    setPagination((prevState) => ({
      ...prevState,
      searchQuery: value,
      searchField: 'name',
      page: 1,
    }))
  }, 500)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setIsLoading(true)
      const { data } = await axios.get<any, any>('/company/bodymakers', {
        params: {
          ...pagination,
          sort: undefined,
          sortColumn: pagination.sort.field,
          sortOrder: pagination.sort.order,
          onPageChange: undefined,
          regions: undefined,
          region: pagination.regions || undefined,
          plants: undefined,
          plantIds: pagination.plants,
          lineId: props.lineId,
        },
      })
      console.log(data)

      setData(data.items)
      setRegions(data.regions)
      setPlants(data.plants)
      setPagination((prevState) => ({
        ...prevState,
        totalPages: data.totalPages,
        totalItems: data.totalItems,
      }))
    }

    fetchData()
      .then(() => {
        setData((prevState: any) => {
          const temp = prevState.map((item: any) => {
            return {
              ...item,
              name: (
                <>
                  <Box
                    component={Link}
                    to={routes.machineView
                      .replace(':lineId', item?.line?.id)
                      .replace(':bodymakerId', item.id)
                      .replace(':plantId', item?.line?.plant?.id)}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <SVG
                      src={Icon3DCube}
                      height={32}
                      width={32}
                      style={{
                        stroke: '#29823B',
                        fill: 'red',
                        minWidth: 'fit-content',
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        ml: '8px',
                        color: '#000',
                        fontWeight: 600,
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: '12px',
                      color: 'secondary.main',
                    }}
                  >
                    {item?.line?.plant?.name} - {item.line?.name}
                  </Typography>
                </>
              ),
              // productionRate: (
              //   <Box
              //     sx={{
              //       p: '5.5px 16px',
              //       display: 'inline-block',
              //       borderRadius: '100px',
              //       backgroundColor: '#EBF7ED',
              //       color: '#267D36',
              //       fontWeight: 500,
              //     }}
              //   >
              //     {item.productionRate} CPM
              //   </Box>
              // ),
              // map all the other columns to append lbs to the value
              overTravel: item.overTravel ? `${item.overTravel} in` : '-',
              clampRing: item.clampRing ? `${item.clampRing} lbf` : '-',
              dome: item.dome ? `${item.dome} lbf` : '-',
              alignment: item.alignment ? `${item.alignment} in` : '-',
              lastUpdated: (
                <Box
                  sx={{
                    p: '4px',
                    display: 'inline-block',
                    borderRadius: '4px',
                    backgroundColor: 'secondary.main',
                    color: '#F8F9FA',
                    whiteSpace: 'nowrap',
                    lineHeight: '18px',
                    fontSize: '12px',
                  }}
                >
                  {item.lastUpdated
                    ? convertTimestampToLocal(item.lastUpdated)
                    : 'N/A'}
                </Box>
              ),
            }
          })
          return temp
        })
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error.message)
        setIsLoading(false)
      })
  }, [
    props.lineId,
    pagination.page,
    pagination.limit,
    pagination.searchQuery,
    pagination.sort,
    pagination.regions,
    pagination.plants,
  ])
  const filters =
    regions &&
    regions.map((region) => {
      return { value: region, label: region }
    })

  const plantFilters =
    plants &&
    plants.map((plant) => {
      return { value: plant.id, label: plant.name }
    })

  const onFilterChange = (value: string[]): void => {
    setPagination((prevState) => ({
      ...prevState,
      regions: value,
      page: 1,
    }))
  }

  const onPlantFilterChange = (value: any[]): void => {
    console.log(value)

    setPagination((prevState) => ({
      ...prevState,
      plants: value,
      page: 1,
    }))
  }

  return (
    <Box>
      {props.showHeader && (
        <Typography
          variant="h4"
          sx={{ mt: '48px', mb: '24px', color: '#101213' }}
        >
          Bodymaker Overview
        </Typography>
      )}
      {props.showSearch && (
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
          }}
        >
          <Search
            searchStyles={{
              width: '364px',
              padding: '0.3rem 3rem 0.3rem 0',
            }}
            selectLabel={{ name: 'Region', id: 'region' }}
            styles={{ width: 'auto' }}
            onChange={(e) => handleSearchDebounced(e.target.value)}
            hasFilters={true}
            onFilterChange={(e: any) => {
              onFilterChange(e)
            }}
            options={filters}
            isMultiFilter={true}
            selectStyles={{
              backgroundColor: '#F5F7F9',
              borderRadius: '4px',
            }}
          />
          <Select
            onChange={onPlantFilterChange}
            options={plantFilters}
            isSmall
            width={200}
            isMultiSelect
            selectLabel={{ name: 'Plant', id: 'plant' }}
            values={plantFilters.map((item) => item.label)}
            styles={{
              backgroundColor: '#F5F7F9',
              borderRadius: '4px',
            }}
          />
        </Box>
      )}
      <Table
        data={data}
        loading={isLoading}
        columns={columns}
        containerStyles={{
          mt: '48px',
        }}
        tdStyles={{
          p: '16px',
          pb: '12px',
        }}
        handleSorting={handleSorting}
        pagination={props.showPagination ? pagination : undefined}
      />
    </Box>
  )
}
