import { useEffect, useState } from 'react'

import styled from '@emotion/styled'
import { Backdrop, Box, Typography } from '@mui/material'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { GridLoader } from 'react-spinners'

import { PageHeading } from 'src/components/PageHeading'
import PageLoader from 'src/components/PageLoader/PageLoader'
import { formatDateTime } from 'src/helpers/timestamp-generator'
import { ClassifyCard } from 'src/pages/ActiveLearningSample/components/ClassifyCard'
import { ClassifyForm } from 'src/pages/ActiveLearningSample/components/ClassifyForm'
import { routes } from 'src/router'
import Breadcrumb from 'src/ui/Breadcrumbs/Breadcrumb'
import { sensorNames } from '..'

const ActiveLearningSample: React.FC = () => {
  const params = useParams()
  const { id } = params
  const [loading, setLoading] = useState<boolean>(false)
  const [question, setQuestion] = useState<any>()
  const [questions, setQuestions] = useState<any[]>([])
  const [submitting, setSubmitting] = useState<boolean>(false)
  const router = useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const splittedId = id?.split('_')
      const guid = splittedId?.[0]
      const sensorId = splittedId?.[1]
      const sensorType = splittedId?.[2]
      const response = await axios.get('active-learning/question', {
        params: {
          guid,
          Sensor_id: sensorId,
          Sensor_type: sensorType,
        },
      })
      const data = await response.data
      setQuestion(data.question)
      setQuestions(data.questions)
      setLoading(false)
    }

    fetchData().catch((err) => {
      if (err.response.status === 403 || err.response.status === 404) {
        router(routes.activeLearning, { replace: true })
      }

      setLoading(false)
      console.log(err)
    })
  }, [id, router])

  const onConfirm = async (items: any[]) => {
    setSubmitting(true)
    await axios.post(
      'active-learning/answer',
      {
        answer: items,
      },
      {
        params: {
          guid: question.GUID,
          Sensor_id: question.Sensor_id,
          Sensor_type: question.Sensor_type,
        },
      },
    )

    setSubmitting(false)

    const nextQuestion = questions?.[0]
    if (nextQuestion) {
      router(
        routes.activeLearningSample.replace(
          ':id',
          `${nextQuestion.GUID}_${nextQuestion.Sensor_id}_${nextQuestion.Sensor_type}`,
        ),
      )
    }
  }

  const onIgnore = async () => {
    setSubmitting(true)
    await axios.post(
      'active-learning/answer',
      {
        answer: ['unable to classify'],
      },
      {
        params: {
          guid: question.GUID,
          Sensor_id: question.Sensor_id,
          Sensor_type: question.Sensor_type,
        },
      },
    )

    setSubmitting(false)

    const nextQuestion = questions?.[0]
    console.log(nextQuestion)

    if (nextQuestion) {
      router(
        routes.activeLearningSample.replace(
          ':id',
          `${nextQuestion.GUID}_${nextQuestion.Sensor_id}_${nextQuestion.Sensor_type}`,
        ),
      )
    } else {
      router(routes.activeLearning)
    }
  }
  // const [sensorName, setSensorName] = useState<any>('')
  // useEffect(() => {
  //   setSensorName(params.id?.split('-').slice(-1))
  // }, [params.id])
  return (
    <Box sx={{ marginTop: '36px', width: '100%', paddingBottom: '60px' }}>
      {!loading && question && (
        <>
          <Breadcrumb
            breadcrumbs={[
              { href: routes.activeLearning, label: 'Active Learning' },
              {
                label: `${question.clusterStatus
                  .split('')[0]
                  .toUpperCase()}${question.clusterStatus.slice(1)} - Cluster ${
                  question.cluster_id
                }`,
              },
            ]}
          />
          <PageHeading
            title={`${question.clusterStatus
              .split('')[0]
              .toUpperCase()}${question.clusterStatus.slice(1)} - Cluster ${
              question.cluster_id
            }`}
            backButtonLink={routes.activeLearning}
            margin="48px 0 36px 0"
          />
          <ChartRow
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '12px',
                maxWidth: '50%',
                flexWrap: 'wrap',
              }}
            >
              <p
                style={{
                  lineBreak: 'anywhere',
                }}
              >
                Sensor: {question.sensorName}
              </p>
              <p
                style={{
                  lineBreak: 'anywhere',
                  marginBottom: '24px',
                }}
              >
                Classification: {question.clusterStatus}
              </p>
              <Typography variant={'subtitle2'}>
                Timeframe: {formatDateTime(question.dt * 1000).toString()}{' '}
              </Typography>
              <img
                style={{ maxWidth: '100%', width: '100%', flexGrow: 1 }}
                src={`data:image/png;base64, ${question.image}`}
                alt="Sample chart"
              />
            </Box>

            <ClassifyForm onConfirm={onConfirm} onIgnore={onIgnore} />
          </ChartRow>
        </>
      )}

      {!loading && questions && (
        <ClassifySectionTittle>To Classify</ClassifySectionTittle>
      )}
      {!loading && questions && (
        <TopClassifyGrid>
          {questions &&
            questions.map((question, index) => (
              <ClassifyCard key={index} item={question} />
            ))}
        </TopClassifyGrid>
      )}
      {loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            mt: 3,
          }}
        >
          <GridLoader color="#1068EB" />
        </Box>
      )}
      {submitting && (
        <Backdrop
          open={true}
          sx={{ zIndex: 9999, background: 'transparent' }}
        ></Backdrop>
      )}
    </Box>
  )
}

export default ActiveLearningSample

const ClassifySectionTittle = styled.h4`
  margin-top: 64px;
  margin-bottom: 36px;

  color: #000;
  font-size: 28px;
  font-weight: 500;
  font-family: Roboto;
`

const ChartRow = styled.div`
  display: flex;
  align-items: center;
  gap: 46px;

  img {
    max-width: 50%;
  }
`

const TopClassifyGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(300px, 1fr));
  gap: 24px;
`
