import { forwardRef, useState } from 'react'

import { Checkbox as MUICheckbox, Box, useTheme } from '@mui/material'

import customTheme from 'src/themes/themeLight'

const defaultStyles = {
  borderRadius: '4px',
  p: 0,
  '& .MuiSvgIcon-root': { fontSize: 30 },
}

export const Checkbox = forwardRef<HTMLInputElement, any>(
  ({ styles, value, ...props }, ref) => {
    const theme = useTheme<typeof customTheme>()

    return (
      <MUICheckbox
        ref={ref}
        {...props}
        sx={{
          ...defaultStyles,
          ...styles,
          border: !props.checked
            ? `1.2px solid ${theme.palette.secondary.main}`
            : 'none',
        }}
        onChange={(e: any) => {
          props.onChange(e)
        }}
        value={props.checked || false}
        checked={props.checked || false}
        icon={<Box />}
      />
    )
  },
)

Checkbox.displayName = 'ReactCheckbox'
