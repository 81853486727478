import React, { FC, useEffect, useState } from 'react'

import { PlayArrowOutlined } from '@mui/icons-material'
import { Box, Typography, IconButton, Button } from '@mui/material'
// import { DateRange } from 'mui-daterange-picker'
import { useLocation } from 'react-router-dom'

import DateRangePicker from 'src/ui/DateRangePicker/DateRangePicker'

interface TimePickerProps {
  timestampButtons: Array<{
    label: string
    value: string
    getTimestamp: () => any
  }>

  onDateRangeSelected: (dateRange: any | 'live') => void
}

const TimePicker: FC<TimePickerProps> = ({
  timestampButtons,
  onDateRangeSelected,
}) => {
  const [selectedRange, setSelectedRange] = useState<any | 'live'>({})
  const [selectedButton, setSelectedButton] = useState<string>('1H')

  const { pathname } = useLocation()

  const handleDateRangeSelected = (
    dateRange: any | 'live',
    buttonId: string,
  ): void => {
    if (buttonId === selectedButton && buttonId !== 'custom') return
    setSelectedRange(dateRange)
    setSelectedButton(buttonId)
    onDateRangeSelected(dateRange)
  }

  const renderTimestampButton = createTimestampButton(
    selectedButton,
    handleDateRangeSelected,
  )
  useEffect(() => {
    setSelectedButton('1H')
    setSelectedRange(timestampButtons[0].getTimestamp())
  }, [pathname])

  return (
    <Box
      sx={{
        backgroundColor: '#1068EB26',
        padding: '1rem 1.5rem 1.875rem',
        borderRadius: '8px',
        border: '1px solid rgba(16, 104, 235, 0.15)',
      }}
    >
      <Typography variant="h6" color="#101213" marginBottom="20px">
        Choose the timeframe for charts preview
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <Box
            sx={{
              background: 'white',
              borderRadius: '4px',
              boxShadow:
                selectedButton === 'live'
                  ? 'none'
                  : '0px 4px 4px rgba(0, 0, 0, 0.25)',
              backgroundColor: selectedButton === 'live' ? '#1068EB' : '#fff',
              width: 'fit-content',
            }}
          >
            <IconButton
              disableRipple
              onClick={() => {
                handleDateRangeSelected('live', 'live')
              }}
            >
              <PlayArrowOutlined
                sx={{
                  color: selectedButton === 'live' ? '#fff' : '#303D60',
                }}
              />{' '}
              <Typography
                sx={{
                  color: selectedButton === 'live' ? '#fff' : '#303D60',
                }}
              >
                Live
              </Typography>
            </IconButton>
          </Box>
          {timestampButtons.map((button) => renderTimestampButton(button))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <Box
            sx={{
              background: 'white',
              borderRadius: '4px',
              width: 'fit-content',
              boxShadow:
                selectedButton === 'custom'
                  ? 'none'
                  : '0px 4px 4px rgba(0, 0, 0, 0.25)',
              backgroundColor: selectedButton === 'custom' ? '#1068EB' : '#fff',
            }}
          >
            <DateRangePicker
              resetRange={!(selectedButton === 'custom')}
              iconColor={selectedButton === 'custom' ? '#fff' : '#303D60'}
              // iconColor={'#303D60'}
              onDateRangeSelected={(dateRange) => {
                handleDateRangeSelected(dateRange, 'custom')
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default TimePicker

interface TimestampButton {
  label: string
  value: string
  getTimestamp: () => any
}

const createTimestampButton =
  (
    selectedButton: string,
    handleDateSelection: (dateRange: any, buttonId: string) => void,
  ): FC<TimestampButton> =>
  ({ label, value, getTimestamp }) => {
    const isActive = selectedButton === value
    const backgroundColor = isActive ? '#1068EB' : '#fff'
    const color = isActive ? '#fff' : '#303D60'
    const boxShadow = isActive ? 'none' : '0px 4px 4px rgba(0, 0, 0, 0.25)'

    return (
      <Box
        key={value}
        sx={{
          background: 'white',
          borderRadius: '4px',
          width: '40px',
          height: '40px',
          display: 'flex',
          justifyContent: 'center',
          boxShadow,
          backgroundColor,
        }}
      >
        <Button
          disableRipple
          sx={{
            color,
            minWidth: 0,
          }}
          onClick={() => {
            const dateRange = getTimestamp()
            handleDateSelection(dateRange, value)
          }}
        >
          {label}
        </Button>
      </Box>
    )
  }
