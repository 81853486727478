import React, { useEffect, useMemo, useRef } from 'react'

import { useTheme } from '@mui/material'
import * as d3 from 'd3'

import { ChartData } from 'src/modules/MachineView/components/MachineChart'
import { DataItem, Margins } from 'src/ui/MultiLineChart/AnimatedLineChart'

/**
 *  This will draw the line and the circles
 */
export const LineDrawer: React.FC<{
  data: ChartData
  xScale: d3.ScaleTime<number, number>
  yScale: d3.ScaleLinear<number, number>
  margin: Margins
  selectedDate: Date | null
  isLive: boolean
}> = ({
  data: { data, light, dark },
  selectedDate,
  xScale,
  yScale,
  margin,
  isLive,
}) => {
  const theme = useTheme()
  const activeColor = useMemo(
    () => (theme.palette.mode === 'dark' ? dark : light),
    [dark, light, theme.palette.mode],
  )

  const lineRef = useRef<SVGPathElement>(null)

  const tooltipCircleRef = useRef<SVGCircleElement>(null)
  const tooltipTextRef = useRef<SVGTextElement>(null)

  useEffect(() => {
    const lineGenerator = d3
      .line<DataItem>()
      .curve(d3.curveMonotoneX)
      .x((d) => xScale(d.date))
      .y((d) => yScale(d.value))

    d3.select(lineRef.current)
      .datum(data)
      .attr('id', 'line')
      .attr('stroke', activeColor)
      .attr('stroke-width', 1)
      .attr('fill', 'none')
      .attr('d', lineGenerator)
  })

  /// initial animation
  useEffect(() => {
    setTimeout(() => {
      const path = d3.select(lineRef.current)

      const pathLength = path.node()?.getTotalLength()

      if (!pathLength) return
      let transitionPath = d3.transition().ease(d3.easeSin).duration(1000)
      if (isLive) {
        transitionPath = d3.transition().ease(d3.easeSin).duration(50)
      }
      path
        .transition(transitionPath)
        .attrTween('stroke-dashoffset', function (d) {
          return d3.interpolate(
            path.attr('stroke-dashoffset') ?? pathLength,
            '0',
          )
        })
        .attrTween('stroke-dasharray', function (d) {
          return d3.interpolate(
            path.attr('stroke-dasharray') ?? pathLength,
            pathLength.toString(),
          )
        })
    }, 80)
  })

  /** Hover animation  */
  useEffect(() => {
    if (!selectedDate) {
      d3.select(tooltipCircleRef.current).attr('opacity', 0)

      d3.select(tooltipTextRef.current).attr('opacity', 0)
    } else {
      const activeItemIndex = d3.bisectCenter(
        data.map((d) => d.date),
        selectedDate,
      )
      const activeItem = data[activeItemIndex]

      d3.select(tooltipCircleRef.current)
        .attr('cx', xScale(activeItem?.date))
        .attr('cy', yScale(activeItem?.value))
        .attr('opacity', xScale(selectedDate) > margin.left ? 1 : 0)

      d3.select(tooltipTextRef.current)
        .attr('x', xScale(activeItem?.date))
        .attr('y', yScale(activeItem?.value) - 20)
        .attr('opacity', xScale(selectedDate) > margin.left ? 1 : 0)
        .text(activeItem.value ?? 0)
    }
  }, [data, margin.left, selectedDate, xScale, yScale])

  return (
    <>
      <path ref={lineRef} fill={activeColor} />
      <circle
        ref={tooltipCircleRef}
        r="4"
        fill="white"
        strokeWidth="2"
        stroke={activeColor}
        opacity="0"
      />
      <text
        ref={tooltipTextRef}
        x="0"
        y="0"
        textAnchor="middle"
        fontSize={10}
        fontWeight={'bold'}
        stroke={'white'}
        strokeWidth={1}
        paintOrder={'stroke'}
        fill={activeColor}
      ></text>
    </>
  )
}
