import React, { FC } from 'react'

import Articles from './components/Articles'
import Faq from './components/Faq'
import VideoTutorials from './components/VideoTutorials'

const KnowledgeBase: FC = () => {
  return (
    <>
      <Faq />
      <VideoTutorials />
      <Articles />
    </>
  )
}

export default KnowledgeBase
