import { FC, useEffect, useState } from 'react'

import { Box, Stack, Typography } from '@mui/material'
import axios from 'axios'
import SVG from 'react-inlinesvg'

import {
  Icon3DCube,
  IconColored3dCube,
  IconColoredBuildingFactory,
  IconColoredSitemap,
  IconGlobe,
  IconMapPin,
} from 'src/assets/icons'

import { BodymakerOverview } from './components/BodymakerOverview'
import { FactoryOverview } from './components/FactoryOverview'

// const panelsData = [
//   {
//     title: 'Total Locations',
//     number: '8',
//     icon: IconMapPin,
//   },
//   {
//     title: 'Total Factories',
//     number: '26',
//     icon: IconColoredBuildingFactory,
//   },
//   {
//     title: 'Total Lines',
//     number: '120',
//     icon: IconColoredSitemap,
//   },
//   {
//     title: 'Total Bodymakers',
//     number: '1546',
//     icon: IconColored3dCube,
//   },
// ]

export const FleetData: FC = () => {
  const [panelsData, setPanelsData] = useState([
    {
      id: 'uniqueLocations',
      title: 'Regions',
      number: 0,
      icon: IconGlobe,
    },
    {
      id: 'totalFactories',
      title: 'Plants',
      number: 0,
      icon: IconColoredBuildingFactory,
    },
    {
      id: 'totalLines',
      title: 'Lines',
      number: 0,
      icon: IconColoredSitemap,
    },
    {
      id: 'totalBodymakers',
      title: 'Bodymakers',
      number: 0,
      icon: Icon3DCube,
    },
  ])
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const response = await axios.get('/company/company-data')
      const json = await response.data

      const newPanelsData = panelsData.map((panel) => {
        const newPanel = { ...panel }
        newPanel.number = json[panel.id]
        return newPanel
      })

      setPanelsData(newPanelsData)
    }
    void fetchData().then(() => {})
    // .catch((error) => {
    // console.log(error.message)
    // })
  }, [])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: '24px 36px',
          bgcolor: '#FFF',
          border: '1px solid #CED4DA',
          borderRadius: '8px',
          mt: '33px',
          gap: '28px',
          '&>div': {
            borderRight: '1px solid #CED4DA',
            display: 'flex',
            pr: '28px',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          },
        }}
      >
        {panelsData.map((panel) => (
          <Box
            key={panel.title}
            sx={{
              '&:last-child': {
                border: 'none',
                pr: 0,
              },
            }}
          >
            <Stack>
              <Typography
                variant="h4"
                sx={{
                  color: '#272B30',
                }}
              >
                {panel.number}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 400,
                  color: 'secondary.main',
                }}
              >
                {panel.title}
              </Typography>
            </Stack>
            <SVG
              src={panel.icon}
              style={{
                width: '40px',
                height: '40px',
              }}
            />
          </Box>
        ))}
      </Box>
      <FactoryOverview />
      <BodymakerOverview showPagination showSearch showHeader />
    </>
  )
}
