import { FC, ReactNode } from 'react'

import { Box, Grid, useTheme } from '@mui/material'
import customTheme from 'src/themes/themeLight'

interface GeneralLayoutProps {
  children: ReactNode
  isAsideVisible: boolean
}

export const GeneralLayout: FC<GeneralLayoutProps> = ({
  children,
  isAsideVisible,
}) => {
  const theme = useTheme<typeof customTheme>()
  return (
    <Box
      id="123"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        py: '24px',
        backgroundColor: theme.palette.background.default,
        px: '36px',
        transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
        width: '100%',
        maxWidth: 'calc(1920px - 248px)',
        marginX: 'auto',
      }}
    >
      <Grid container maxWidth={'calc(1920px - 248px)'} marginX="auto">
        {children}
      </Grid>
    </Box>
  )
}
