import React, { forwardRef, useState } from 'react'

import {
  FormControl,
  MenuItem,
  Select as MUISelect,
  SxProps,
  SelectChangeEvent,
  InputLabel,
  Checkbox,
  ListItemText,
  Box,
  Typography,
} from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import SVG from 'react-inlinesvg'

import { IconChevronDown } from 'src/assets/icons'
import customTheme from 'src/themes/themeLight'

export interface IOption {
  value: any
  label: string
}

const defaultStyles: SxProps<Theme> = {
  color: customTheme.palette.secondary.main,
  // backgroundColor: customTheme.palette.static.main,
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  fontSize: '14px',
  '&>svg': {
    position: 'absolute',
    right: '8px',
  },
  '&>div': {
    position: 'relative',
    zIndex: 2,
    height: 'inherit',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}

export interface ISelectProps {
  value?: any
  options?: IOption[]
  placeholder?: string
  onChange: (value: any) => void
  width?: number
  height?: number
  isSmall?: boolean
  styles?: SxProps<Theme>
  optionIcon?: string | undefined
  isMultiSelect?: boolean
  values?: string[]
  disabled?: boolean
  selectLabel: { name: string; id: string }
  error?: boolean
  placeholderIcon?: string
  useLabel?: boolean
}

function getStyles(
  value: string,
  selectedValue: readonly string[],
  theme: Theme,
): React.CSSProperties {
  return {
    fontWeight: !selectedValue.includes(value)
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
  }
}

export const Select = forwardRef<HTMLSelectElement, any>(
  (props: ISelectProps, ref) => {
    const {
      options,
      placeholder = 'Select an option',
      value,
      onChange,
      isSmall = false,
      width,
      height,
      styles,
      optionIcon,
      isMultiSelect = false,
      disabled = false,
      values,
      selectLabel,
      error,
      placeholderIcon,
      useLabel = false,
    } = props
    const theme = useTheme()
    const [selectedValues, setSelectedValues] = useState<string[]>([])
    const [selectedValue, setSelectedValue] = useState<string>(value ?? '')

    const handleChange = (event: SelectChangeEvent<any>): void => {
      const newValue = event.target.value
      setSelectedValues(newValue)
      onChange(newValue)
    }

    const handleSingleSelectChange = (event: SelectChangeEvent<any>): void => {
      const value = event.target.value

      setSelectedValue(value)

      onChange(event)
    }

    const ITEM_HEIGHT = 48
    const ITEM_PADDING_TOP = 8

    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
      },
    }

    return (
      <FormControl
        sx={{
          width: width ? `${width}px` : '100%',
          ...styles,
        }}
        size={isSmall ? 'small' : 'medium'}
        disabled={disabled}
        error={error}
      >
        {isMultiSelect && (
          <>
            <InputLabel
              sx={{
                '&.MuiInputLabel-shrink': {
                  color: customTheme.palette.action.main,
                },
                '& + .Mui-focused fieldset': {
                  borderColor: customTheme.palette.action.main,
                },
              }}
              id="filters"
            >
              {selectLabel?.name ?? 'Filters'}
            </InputLabel>
            <MUISelect
              labelId="filters"
              id="filtersSelect"
              value={selectedValues}
              onChange={handleChange}
              label={selectLabel?.name ?? 'Filters'}
              ref={ref}
              renderValue={(selected) => {
                console.log('selected', options)

                if (Array.isArray(selected)) {
                  if (typeof selected[0] === 'string' && !useLabel) {
                    return (
                      <Typography noWrap>
                        {selected
                          .map(
                            (opt) =>
                              opt.split('')[0].toUpperCase() + opt.slice(1),
                          )
                          .join(', ')}
                      </Typography>
                    )
                  } else if (typeof selected[0] === 'number') {
                    const selectedLabels = selected.map((num) => {
                      const option = options?.find((opt) => opt.value === num)
                      return option ? option.label : num
                    })

                    return (
                      <Typography noWrap>
                        {selectedLabels.join(', ')}
                      </Typography>
                    )
                  } else if (typeof selected[0] === 'string') {
                    const selectedLabels = selected.map((val: any) => {
                      const option = options?.find((opt) => opt.value === val)
                      return option ? option.label : val
                    })

                    return (
                      <Typography noWrap>
                        {selectedLabels
                          .map(
                            (opt) =>
                              `${opt.split('')[0].toUpperCase()}${opt.slice(
                                1,
                              )}`,
                          )
                          .join(', ')}
                      </Typography>
                    )
                  }
                }

                return null
              }}
              MenuProps={MenuProps}
              multiple={isMultiSelect}
              sx={{
                height: height ? `${height}px` : '100%',
                ...defaultStyles,
              }}
            >
              {options &&
                options.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    style={getStyles(option.value, selectedValues, theme)}
                  >
                    <Checkbox
                      sx={{
                        'input:checked ~ svg': {
                          color: customTheme.palette.action.main,
                        },
                      }}
                      checked={selectedValues.includes(option.value)}
                    />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
            </MUISelect>
          </>
        )}

        {!isMultiSelect && (
          <>
            {selectLabel && (
              <InputLabel
                sx={{
                  '&.MuiInputLabel-shrink:not(.Mui-disabled)': {
                    color: error
                      ? customTheme.palette.error.main
                      : customTheme.palette.action.main,
                  },
                  '& + .Mui-focused fieldset': {
                    borderColor: error
                      ? customTheme.palette.error.main
                      : customTheme.palette.action.main + ' !important',
                  },
                }}
                id={selectLabel?.id}
              >
                {selectLabel?.name}
              </InputLabel>
            )}

            <MUISelect
              labelId={selectLabel?.id}
              label={selectLabel?.name}
              maxRows={1}
              displayEmpty
              // onClick={}
              value={selectedValue}
              placeholder={placeholder}
              onChange={handleSingleSelectChange}
              ref={ref}
              sx={{
                height: height ? `${height}px` : '100%',
                ...defaultStyles,
                'label + &': {
                  // marginTop: theme.spacing(2),
                },
              }}
              renderValue={(selected) => {
                // console.log('selected', !selected)

                if (selected === null || selected === '') {
                  if (placeholderIcon) {
                    return (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        <SVG
                          src={placeholderIcon}
                          style={{
                            width: '24px',
                            height: '24px',
                            flexShrink: 0,
                          }}
                        />
                        {placeholder}
                      </Box>
                    )
                  }
                  return <span>{placeholder}</span>
                }

                return (
                  options?.find((option) => option.value === selected)?.label ??
                  ''
                )
              }}
              IconComponent={() => (
                <SVG src={IconChevronDown} style={{ fill: '#6A7178' }} />
              )}
            >
              {options &&
                options.length > 0 &&
                options.map((option) => (
                  <MenuItem key={option.label} value={option.value}>
                    {optionIcon ? (
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '8px',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        <SVG
                          src={optionIcon}
                          style={{
                            width: '24px',
                            height: '24px',
                            flexShrink: 0,
                          }}
                        />
                        {option.label}
                      </Box>
                    ) : (
                      option.label
                    )}
                  </MenuItem>
                ))}
            </MUISelect>
          </>
        )}
      </FormControl>
    )
  },
)
