import React, { FC } from 'react'

import { Box } from '@mui/material'

import { PageHeading } from 'src/components/PageHeading'
import { Search } from 'src/components/Search'
import Breadcrumb from 'src/ui/Breadcrumbs/Breadcrumb'
import { IOption } from 'src/ui/Select'

const BaseHeader: FC<{
  title: string
  subtitle: string
  backButtonLink: string
  onSearchChange: (value: string) => void
  breadcrumbText?: string
  filters?: IOption[]
  onFilterChange?: (filter: any) => void
}> = ({
  title,
  subtitle,
  backButtonLink,
  onSearchChange,
  breadcrumbText,
  filters,
  onFilterChange
}) => {
  // const onFilterChange = (e: ChangeEvent<HTMLInputElement>): void => {}

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        mt: '3rem',
        mb: '4.5rem',
      }}
    >
      <Breadcrumb finalText={breadcrumbText} />
      <Box sx={{ mt: '3rem', mb: '1.5rem' }}>
        <PageHeading
          title={title}
          subtitle={subtitle}
          backButtonLink={backButtonLink}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: 'auto',
          mb: '1.5rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'auto',
          }}
        >
          <Search
            searchStyles={{
              width: '364px',
              padding: '0.3rem 3rem 0.3rem 0',
            }}
            styles={{ width: 'auto' }}
            onChange={(e) => onSearchChange && onSearchChange(e.target.value)}
            hasFilters={true}
            onFilterChange={onFilterChange}
            options={filters}
            isMultiFilter={true}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default BaseHeader
