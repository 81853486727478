import React, { FC, useEffect, useState } from 'react'

import { Box } from '@mui/material'

import BaseModal from './BaseModal'
import { CustomButton } from '../Button'

const ConfirmationModal: FC<{
  show: boolean
  onClose: () => void
  onConfirm: () => void
  username?: string
  message?: string
  type: string
}> = ({ show, onClose, onConfirm, username, type, message }) => {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(show)
  }, [show])

  const handleClose = (): void => {
    setOpen(false)
    onClose()
  }

  const [details] = useState<any>({
    title:
      type === 'deactivate'
        ? 'Deactivation Confirmation'
        : type === 'delete'
        ? 'Delete Confirmation'
        : 'Activation Confirmation',
    message:
      message !== null && !!message
        ? message
        : type === 'deactivate'
        ? `Are you sure you want to deactivate ${username}'s account?`
        : type === 'delete'
        ? `Are you sure you want to delete ${username}'s account?`
        : `Are you sure you want to activate ${username}'s account?`,
    confirmText:
      type === 'deactivate'
        ? 'Deactivate'
        : type === 'delete'
        ? 'Delete'
        : 'Activate',
  })

  return (
    <BaseModal
      fullWidth={false}
      maxWidth="sm"
      show={open}
      onClose={handleClose}
      title={details.title}
    >
      <Box
        sx={{
          mb: '16px',
        }}
      >
        <p>{details.message}</p>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          mb: '16px',
          width: '100%',
        }}
      >
        <CustomButton
          styles={{ mr: 2 }}
          onClick={onConfirm}
          appearance={
            type === 'deactivate' || type === 'delete'
              ? 'error-soft-default'
              : 'primary-soft-default'
          }
        >
          {details.confirmText}
        </CustomButton>
        <CustomButton onClick={handleClose} appearance="cancel-soft-default">
          Cancel
        </CustomButton>
      </Box>
    </BaseModal>
  )
}

export default ConfirmationModal
