import axios from 'axios'
import { LoaderFunction, LoaderFunctionArgs, redirect } from 'react-router-dom'
import { store } from 'src/store/store'
import { checkAuthentication } from 'src/store/reducers/authReducer'
import attachTokenToHeaders from 'src/interceptors/authenticate'
import { routes } from 'src/router'

export const rootLoader: LoaderFunction = async ({
  request,
}: LoaderFunctionArgs) => {
  const url = new URL(request.url)
  // const API_BASE_URL =
  //   process.env.REACT_APP_API_BASE_URL_PROD
  //   //  ?? 'http://localhost:8080/api/'

  const pathname = url.pathname
  const authRoutes = [routes.login, routes.forgotPassword, routes.resetPassword]

  try {
    axios.defaults.baseURL =
      process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_API_BASE_URL_PROD
        : process.env.REACT_APP_API_BASE_URL

    attachTokenToHeaders()
    const isAuthenticated = store.getState().authReducer.isAuthenticated
    if (!isAuthenticated) {
      const isAuth = await store.dispatch(checkAuthentication() as any)
      if (!isAuth.payload && !authRoutes.includes(pathname as any))
        return redirect(routes.login)
    }
  } catch (err) {
    return redirect(routes.login)
  }

  return null
}
